import React, { Component } from "react";
// import { convertToRaw } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/languages/de.js";

// Import a third-party plugin.
// import 'froala-editor/js/third_party/image_tui.min.js';
// import 'froala-editor/js/third_party/embedly.min.js';
// import 'froala-editor/js/third_party/spell_checker.min.js';

// Include font-awesome css if required.
// install using "npm install font-awesome --save"
// import 'font-awesome/css/font-awesome.css';
// import 'froala-editor/js/third_party/font_awesome.min.js';

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

import FroalaEditorComponent from "react-froala-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  ButtonDropdown,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import config from "@generic/config";

import classes from "./index.module.css";

class CommentEditor extends Component {
  render = () => {
    const {
      iscommentEditorOpen,
      editorState,
      addComment,
      commentReaderType,

      showCommentEditor,
      onEditorStateChange,
      toggleTypeDropdown,
      commentReaderTypeOpened,
      readerTypeChange,
      isCuratorForRequest,
    } = this.props;

    // const editorButtonDisable = editorState
    //   ? convertToRaw(editorState.getCurrentContent()).blocks.some(
    //       (item) => item.text
    //     )
    //   : false;

    const getTypeItem = (commentReaderType) => {
      switch (commentReaderType) {
        case "for_user":
          return (
            <em className={["fa-1x fas fa-comments", classes.Icon].join(" ")} />
          );

        case "is_curator_only":
          return (
            <em className={["fa-1x fas fa-user-tie", classes.Icon].join(" ")} />
          );

        default:
          return <></>;
      }
    };

    return (
      <>
        <Card
          className={[
            "mt-2 mb-0 p-2",
            commentReaderType === "is_curator_only"
              ? classes.CommentEditorCurator
              : classes.CommentEditorUser,
          ].join(" ")}
        >
          <div className={classes.Wrapper}>
            <span className={classes.CommentEditorLabel}>
              <em
                className={[
                  "fa-1x mr-2 ml-2 fas fa-pencil-alt",
                  commentReaderType === "is_curator_only" ? "text-white" : "",
                ].join(" ")}
              ></em>

              <span
                className={
                  commentReaderType === "is_curator_only"
                    ? classes.CommentEditorTextCurator
                    : classes.CommentEditorTextUser
                }
                onClick={showCommentEditor}
              >
                <span className={classes.CommentEditorText}>
                  Добавление нового комментария
                </span>
                &nbsp; {iscommentEditorOpen ? "-" : "+"}
              </span>
            </span>

            {isCuratorForRequest ? (
              <span className={classes.CommentEditorDropDown}>
                <ButtonDropdown
                  isOpen={commentReaderTypeOpened}
                  toggle={toggleTypeDropdown}
                >
                  <DropdownToggle className={classes.ButtonDropDrown} caret>
                    {getTypeItem(commentReaderType)}
                  </DropdownToggle>

                  <DropdownMenu
                    right
                    className={["mt-2 p-0", classes.ReviewMenu].join(" ")}
                  >
                    <DropdownItem onClick={() => readerTypeChange("for_user")}>
                      <em
                        className={["fa-1x fas fa-comments", classes.Icon].join(
                          " "
                        )}
                      />
                      Сообщение
                    </DropdownItem>

                    <DropdownItem
                      onClick={() => readerTypeChange("is_curator_only")}
                    >
                      <em
                        className={["fa-1x fas fa-user-tie", classes.Icon].join(
                          " "
                        )}
                      />
                      Для куратора
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </span>
            ) : null}
          </div>

          {iscommentEditorOpen ? (
            <div className="bg-white p-2 mt-2">
              <FroalaEditorComponent
                tag="textarea"
                config={{
                  autofocus: true,
                  pluginsEnabled: [
                    "align",
                    "charCounter",
                    "colors",
                    "embedly",
                    "emoticons",
                    "entities",
                    "specialCharacters",
                    "fontFamily",
                    "fontSize",
                    "print",
                    "inlineStyle",
                    "inlineClass",
                    "lineBreaker",
                    "lineHeight",
                    "link",
                    "lists",
                    "paragraphFormat",
                    "paragraphStyle",
                    "quickInsert",
                    "quote",
                    "url",
                    "wordPast",
                  ],
                  placeholderText: "",
                  charCounterCount: false,
                  key: config.get("froalaKey"),
                }}
                model={editorState}
                onModelChange={onEditorStateChange}
              />
              {/* 
            <Editor
              editorState={editorState}
              wrapperClassName="wysiwig-editor-wrapper"
              editorClassName="form-control"
              editorStyle={{ height: 150 }}
              onEditorStateChange={(editorState) =>
                onEditorStateChange(editorState)
              }
            /> */}
            </div>
          ) : null}
        </Card>

        {iscommentEditorOpen ? (
          <div className={classes.ParticipantButtonWrapper}>
            <button
              className="btn btn-primary" //
              onClick={addComment}
              disabled={!editorState}
            >
              Отправить комментарий
            </button>
          </div>
        ) : null}
      </>
    );
  };
}

export default CommentEditor;
