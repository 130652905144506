import React, { Component } from "react";
import { Row, Col, Card, Input } from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";

import {
  ALL_FILES,
  FILES,
  FILE_TYPES,
} from "@components/Constants/requestFiles";

import RequestFilesModal from "./requestFilesModal/index";

import { getFileTypeIcon } from "../../../../utils/Request";

import classes from "./index.module.css";

class RequestFiles extends Component {
  state = {
    dropdownOpen: false,
    filter: ALL_FILES,
    file: null,
    fileType: { value: "track", label: "Трек для выхода" },
    showRequestFilesModal: false,

    isShowMiniatures: true,
    isGroupByType: false,

    trackIsCollapsed: true,
    referenceIsCollapsed: true,
    otherIsCollapsed: true,
  };

  componentDidMount = async () => {};

  changeFilter = (id) => {
    this.setState({
      filter: id,
    });
  };

  onDrop = (acceptedFiles) => {
    const file = Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0]),
    });

    this.setState({ file });
  };

  handleFileTypeChange = (e) => {
    this.setState({
      fileType: e,
    });
  };

  onToggleChange = (type) => {
    this.setState({ [type]: !this.state[type] });
  };

  openRequestFile = (file) => {
    this.setState({ showRequestFilesModal: true });
  };

  saveEditFile = (file) => {
    this.setState({ showRequestFilesModal: false });
  };

  closeEditFile = (file) => {
    this.setState({ showRequestFilesModal: false });
  };

  handleSubmit = () => {};

  render() {
    // const {
    //   filter, //
    //   fileType,
    //   isShowMiniatures,
    //   isGroupByType,
    //   trackIsCollapsed,
    //   referenceIsCollapsed,
    //   otherIsCollapsed,
    //   showRequestFilesModal,
    // } = this.state;

    // const files = [1, 2, 3, 4, 5];

    return (
      <div className="mt-5">
        <div className="text-center my-3">
          <h1 className="mb-3">
            <sup>
              <em className="fa fa-cog fa-1x text-muted fa-spin text-info"></em>
            </sup>
            <em className="fa fa-cog fa-3x text-muted fa-spin text-purple"></em>
            <em className="fa fa-cog fa-sm text-muted fa-spin text-success"></em>
          </h1>

          <div className="text-bold text-lg mb-3">
            Загрузки файлов в текущей версии системы заявок пока нет...
          </div>

          <p className="lead m-0">
            Мы активно ведем разработку нашей с вами новой системы заявок. И нам
            нужно еще немного времени, чтобы сделать удобную загрузку файлов.
            Пока, пожалуйста, загружайте файлы в облака. Подробнее об этом
            рассказано в правилах - прочитайте их внимательно.
          </p>
        </div>
      </div>
    );

    // return (
    //   <div>
    //     {showRequestFilesModal ? (
    //       <RequestFilesModal
    //         saveEditFile={this.saveEditFile}
    //         closeEditFile={this.closeEditFile}
    //       />
    //     ) : null}

    //     <Row>
    //       <Col lg="3">
    //         <Card className="mb-3 p-2">
    //           <div className={classes.SeletFileTypeHeader}>
    //             Загрузка нового файла
    //           </div>

    //           <div className={classes.SelectFileTypeWrapper}>
    //             <Select
    //               value={fileType}
    //               className={classes.SelectFileType}
    //               onChange={(e) => this.handleFileTypeChange(e)}
    //               options={FILE_TYPES}
    //             />

    //             <div className={classes.DropzoneHint}>
    //               <small>Hint</small>
    //             </div>
    //           </div>

    //           <Dropzone
    //             className={["card mb-1", classes.DropzoneWrapper].join(" ")}
    //             ref="dropzone"
    //             onDrop={this.onDrop}
    //           >
    //             <div
    //               className={[
    //                 "text-center box-placeholder m-0",
    //                 classes.Dropzone,
    //               ].join(" ")}
    //             >
    //               Нажмите для выбора файла или просто перетащите файл сюда
    //             </div>

    //             <div className={classes.DropzoneHint}>
    //               <small>Hint</small>

    //               <small>100MB</small>
    //             </div>
    //           </Dropzone>

    //           <button
    //             className="btn btn-primary" //
    //             onClick={this.handleSubmit}
    //           >
    //             Загрузить файлы!
    //           </button>
    //         </Card>

    //         <div className="card b">
    //           <div className="list-group">
    //             {FILES.map((item) => (
    //               <div
    //                 key={item.name}
    //                 className={[
    //                   "list-group-item",
    //                   "list-group-item-action",
    //                   "d-flex justify-content-between",
    //                   "align-items-center rounded-0",
    //                   classes.ListGroupItem,
    //                   item.id === filter ? "active" : "",
    //                 ].join(" ")}
    //                 onClick={() => this.changeFilter(item.id)}
    //               >
    //                 <span> {item.name}</span>

    //                 <span className="ml-auto badge">49</span>
    //               </div>
    //             ))}
    //           </div>
    //         </div>

    //         <div className="card b">
    //           <div className="list-group p-2">
    //             <div className="checkbox c-checkbox m-0">
    //               <label className={classes.InputText}>
    //                 <Input
    //                   type="checkbox"
    //                   bsSize="sm"
    //                   checked={isGroupByType}
    //                   onChange={(e) => this.onToggleChange("isGroupByType")}
    //                 />
    //                 Группировка
    //                 <span className={["fa", "fa-check m-0"].join(" ")}></span>
    //               </label>
    //             </div>

    //             <div className="checkbox c-checkbox m-0">
    //               <label className={[classes.InputText, "mb-0"].join(" ")}>
    //                 <Input
    //                   type="checkbox"
    //                   bsSize="sm"
    //                   checked={isShowMiniatures}
    //                   onChange={() => this.onToggleChange("isShowMiniatures")}
    //                 />
    //                 Отображать миниатюры
    //                 <span className={["fa", "fa-check m-0"].join(" ")}></span>
    //               </label>
    //             </div>
    //           </div>
    //         </div>
    //       </Col>

    //       <Col lg="9">
    //         {filter !== ALL_FILES || !isGroupByType ? (
    //           <Row>
    //             {files.map((item) => (
    //               <Col lg="4" md="6">
    //                 <div className="card" data-filter-group="audio">
    //                   <div className="card-body text-center">
    //                     <div className="d-flex">
    //                       <div className="ml-auto">
    //                         <a
    //                           className="text-muted mr-1"
    //                           href=""
    //                           title="Download"
    //                         >
    //                           <em className="fa fa-download fa-fw"></em>
    //                         </a>
    //                       </div>
    //                     </div>

    //                     {isShowMiniatures ? (
    //                       <a className="px-4" href="">
    //                         <em
    //                           className={[
    //                             "fa-5x far",
    //                             getFileTypeIcon(item),
    //                           ].join(" ")}
    //                         ></em>
    //                       </a>
    //                     ) : null}

    //                     <p
    //                       className={classes.FileName}
    //                       onClick={() => this.openRequestFile()}
    //                     >
    //                       A good song.ogg
    //                     </p>

    //                     <div className="d-flex m-0 text-muted">
    //                       <small>6.3MB</small>

    //                       <small className="ml-auto">10m ago</small>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </Col>
    //             ))}
    //           </Row>
    //         ) : (
    //           <>
    //             <div className={classes.IntersectionsCollapse}>
    //               <span
    //                 className={classes.Label}
    //                 onClick={() => this.onToggleChange("referenceIsCollapsed")}
    //               >
    //                 <span className={classes.Underline}>Референс </span>
    //                 &nbsp;{referenceIsCollapsed ? "-" : "+"}
    //               </span>
    //             </div>

    //             <Row>
    //               {referenceIsCollapsed
    //                 ? files.map((item) => (
    //                     <Col key={item} lg="4" md="6">
    //                       <div className="card" data-filter-group="audio">
    //                         <div className="card-body text-center">
    //                           <div className="d-flex">
    //                             <div className="ml-auto">
    //                               <a
    //                                 className="text-muted mr-1"
    //                                 href=""
    //                                 title="Download"
    //                               >
    //                                 <em className="fa fa-download fa-fw"></em>
    //                               </a>
    //                             </div>
    //                           </div>

    //                           <a className="px-4" href="">
    //                             <em
    //                               className={[
    //                                 "fa-5x far",
    //                                 getFileTypeIcon(item),
    //                               ].join(" ")}
    //                             ></em>
    //                           </a>

    //                           <p
    //                             className={classes.FileName}
    //                             onClick={() => this.openRequestFile()}
    //                           >
    //                             A good song.ogg
    //                           </p>

    //                           <div className="d-flex m-0 text-muted">
    //                             <small>6.3MB</small>

    //                             <small className="ml-auto">10m ago</small>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </Col>
    //                   ))
    //                 : null}
    //             </Row>

    //             <div className={classes.IntersectionsCollapse}>
    //               <span
    //                 className={classes.Label}
    //                 onClick={() => this.onToggleChange("trackIsCollapsed")}
    //               >
    //                 <span className={classes.Underline}>Трек для выхода </span>
    //                 &nbsp;{trackIsCollapsed ? "-" : "+"}
    //               </span>
    //             </div>

    //             <Row>
    //               {trackIsCollapsed
    //                 ? files.map((item) => (
    //                     <Col lg="4" md="6">
    //                       <div className="card" data-filter-group="audio">
    //                         <div className="card-body text-center">
    //                           <div className="d-flex">
    //                             <div className="ml-auto">
    //                               <a
    //                                 className="text-muted mr-1"
    //                                 href=""
    //                                 title="Download"
    //                               >
    //                                 <em className="fa fa-download fa-fw"></em>
    //                               </a>
    //                             </div>
    //                           </div>

    //                           <a className="px-4" href="">
    //                             <em
    //                               className={[
    //                                 "fa-5x far",
    //                                 getFileTypeIcon(item),
    //                               ].join(" ")}
    //                             ></em>
    //                           </a>

    //                           <p
    //                             className={classes.FileName}
    //                             onClick={() => this.openRequestFile()}
    //                           >
    //                             A good song.ogg
    //                           </p>

    //                           <div className="d-flex m-0 text-muted">
    //                             <small>6.3MB</small>

    //                             <small className="ml-auto">10m ago</small>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </Col>
    //                   ))
    //                 : null}
    //             </Row>

    //             <div className={classes.IntersectionsCollapse}>
    //               <span
    //                 className={classes.Label}
    //                 onClick={() => this.onToggleChange("otherIsCollapsed")}
    //               >
    //                 <span className={classes.Underline}>Прочее </span>
    //                 &nbsp;{otherIsCollapsed ? "-" : "+"}
    //               </span>
    //             </div>

    //             <Row>
    //               {otherIsCollapsed
    //                 ? files.map((item) => (
    //                     <Col lg="4" md="6">
    //                       <div className="card" data-filter-group="audio">
    //                         <div className="card-body text-center">
    //                           <div className="d-flex">
    //                             <div className="ml-auto">
    //                               <a
    //                                 className="text-muted mr-1"
    //                                 href=""
    //                                 title="Download"
    //                               >
    //                                 <em className="fa fa-download fa-fw"></em>
    //                               </a>
    //                             </div>
    //                           </div>

    //                           <a className="px-4" href="">
    //                             <em
    //                               className={[
    //                                 "fa-5x far",
    //                                 getFileTypeIcon(item),
    //                               ].join(" ")}
    //                             ></em>
    //                           </a>

    //                           <p
    //                             className={classes.FileName}
    //                             onClick={() => this.openRequestFile()}
    //                           >
    //                             A good song.ogg
    //                           </p>

    //                           <div className="d-flex m-0 text-muted">
    //                             <small>6.3MB</small>

    //                             <small className="ml-auto">10m ago</small>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </Col>
    //                   ))
    //                 : null}
    //             </Row>
    //           </>
    //         )}
    //       </Col>
    //     </Row>
    //   </div>
    // );
  }
}

export default RequestFiles;
