import React from "react";
import { Route, Redirect } from "react-router-dom";

import AuthService from "@services/auth";

const protectedRoute = ({
  component: Component,
  allowLoggedIn,
  allowAnon,
  allowAll = false,
  ...rest
}) => {
  let allowRender;
  let redirectPath;

  if (allowAll) {
    allowRender = true;
  } else {
    if (allowLoggedIn) {
      allowRender = AuthService.isLoggedIn();
      redirectPath = "/login";
    }

    if (allowAnon) {
      allowRender = !AuthService.isLoggedIn();
      redirectPath = "/";
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (allowRender) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: redirectPath,
              search: props.location.search,
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};

export default protectedRoute;
