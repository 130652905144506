import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";

import * as townApi from "@api/town";
import * as festApi from "@api/fest";
import { setLoading } from "@store/mainPage/actions";

import FestListItem from "../itemActive/index";

import classes from "./index.module.css";

class List extends Component {
  state = {
    fests: [],
    townsById: new Map(),
    error: false,
  };

  componentDidMount = async () => {
    this.setState({
      error: false,
    });

    setLoading(true);

    try {
      const [towns, fests] = await Promise.all([
        townApi.getTowns(),
        festApi.getFests(false),
      ]);

      const townsById = new Map();

      towns.forEach((town) => townsById.set(town.id, town));

      this.setState({
        fests,
        townsById,
        error: false,
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);

      this.setState({
        error: true,
      });

      swal("Ошибка", "Не удалось загрузить фестивали", "error");
    }
  };

  render = () => {
    const {
      error, //
      fests,
      townsById,
    } = this.state;

    if (error) {
      return (
        <div className="alert alert-danger m-2">
          <strong>Ошибка!</strong> Попробуйте обновить страницу, или повтороте
          попытку позже.
        </div>
      );
    }

    return (
      <div className={["content-wrapper", classes.ContentWrapper].join(" ")}>
        <div className="content-heading">Выбор фестиваля</div>

        {fests.map((fest) => (
          <FestListItem key={fest.id} fest={fest} />
        ))}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
