import { getInstance } from "@generic/axios";

export const getSquads = async () => {
  try {
    const axios = getInstance();

    const response = await axios.get("/api/v1/squads");

    if (!response || !response.data || !response.data.squads) {
      throw new Error("empty response");
    }

    return response.data.squads.map((item) => ({
      value: item.id,
      guid: item.guid,
      label: item.name,
    }));
  } catch (e) {
    throw e;
  }
};
