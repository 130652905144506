import { getInstance } from "@generic/axios";

export const login = async (username, password) => {
  try {
    const axios = getInstance();

    const response = await axios.post("/api/v1/auth/login", {
      username,
      password,
    });

    if (!response || !response.data) {
      throw new Error("empty response");
    }

    if (!response.data.access_token || !response.data.refresh_token) {
      throw new Error("empty token");
    }

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  } catch (e) {
    throw e;
  }
};

export const register = async ({
  username, //
  email,
  password,
}) => {
  try {
    const axios = getInstance();

    await axios.post("/api/v1/auth/register", {
      username,
      password,
      email: email.value,
    });
  } catch (e) {
    throw e;
  }
};

export const reset = async (username, password) => {
  try {
    const axios = getInstance();

    // TODO fix reset URL
    const response = await axios.post("/api/v1/auth/login", {
      username,
      password,
    });

    if (!response || !response.data) {
      throw new Error("empty response");
    }

    if (!response.data.access_token || !response.data.refresh_token) {
      throw new Error("empty token");
    }

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  } catch (e) {
    throw e;
  }
};

export const restorePassword = async (username) => {
  try {
    const axios = getInstance();

    await axios.post("/api/v1/auth/restore-password", {
      username_or_email: username,
    });
  } catch (e) {
    throw e;
  }
};
