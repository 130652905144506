import { v4 } from "uuid";

class EventEmitter {
  events = {};

  subscribe(event, callback) {
    if (!this.events[event]) {
      this.events[event] = {};
    }

    const subscriptionId = v4();

    this.events[event][subscriptionId] = callback;

    return subscriptionId;
  }

  unsubscrube(event, subscriptionId) {
    delete this.events[event][subscriptionId];
  }

  dispatch(event, data) {
    if (!this.events[event]) {
      return;
    }

    Object.keys(this.events[event]).forEach((eventKey) => {
      const callback = this.events[event][eventKey];

      callback(data);
    });
  }
}

export default new EventEmitter();
