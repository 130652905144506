export const getUserFullText = (user, force = false) => {
  const { firstName, name, lastName } = user;

  if (user.id || force) {
    if (user.isHiddenPersonal || user.is_hidden_personal) {
      return name;
    }

    if (!!firstName || !!lastName) {
      return `${firstName} "${name}" ${lastName}`;
    }

    return name;
  } else {
    return "Пока неизвестен...";
  }
};
