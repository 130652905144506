export const MY_PROFILE = "my-profile";
export const INFO = "info";
export const MY_TEAM = "my-team";
export const CHANGE_PASSWORD = "change-password";

export const PAGES = [
  // { name: "Профиль", id: MY_PROFILE },
  { name: "Данные для участия", id: INFO },
  { name: "Я и моя команда", id: MY_TEAM },
  { name: "Изменить пароль ", id: CHANGE_PASSWORD },
];
