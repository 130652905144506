import React from "react";
import moment from "moment";
import { Input } from "reactstrap";

import { getUserFullText } from "../../../../../../utils/user";

import classes from "./index.module.css";

const getSocial = (item, key) => {
  switch (key) {
    case "vk":
      if (item.vk) {
        return { href: item.vk };
      } else {
        return {};
      }
    case "twitter":
      if (item.twitter) {
        return { href: item.twitter };
      } else {
        return {};
      }
    case "instagram":
      if (item.instagram) {
        return { href: item.instagram };
      } else {
        return {};
      }
    default:
      return {};
  }
};

const leaderItem = ({ item, onDeleteClick, openContactInfo }) => {
  console.log(item);
  return (
    <>
      <fieldset className={classes.FieldSet}>
        <div className="d-flex align-items-center">
          <div className="col-11 pl-0">
            <h5>
              <div
                onClick={() => openContactInfo(item.usrTeammateId)}
                className="text-bold"
              >
                <span className={classes.TextLink}>
                  {getUserFullText({ ...item, name: item.nickname }, true)}{" "}
                </span>

                <span>может добавлять вас в новые заявки</span>
              </div>
            </h5>

            <small>
              Вы в команде с{" "}
              {moment(item.inTeamSince).format("DD.MM.YYYY г. HH:mm")}
            </small>
          </div>

          <div className="col-1 p-0">
            <button
              className={["btn btn-secondary", classes.Button].join(" ")}
              type="button"
              onClick={onDeleteClick}
            >
              <em className="fa-2x far fa-times-circle" />
            </button>
          </div>
        </div>
      </fieldset>

      {item.isContactInfoOpen ? (
        <fieldset className={classes.FieldSet}>
          <div className="form-group justify-content-end row mb-1">
            <div className="col-2 align-self-center ">
              <div className="text-bold float-right ">Город</div>
            </div>

            <div className="col-10 pl-0">
              <Input
                type="text" //
                disabled
                value={item.city && item.city.label ? item.city.label : ""}
              />
            </div>
          </div>

          <div className="form-group justify-content-end row">
            <div className="col-2 align-self-center ">
              <div className="text-bold float-right ">Контакты</div>
            </div>

            <div className="col-10">
              <div className="row">
                <div className="col p-0">
                  <Input
                    type="text" //
                    disabled
                    value={item.phone}
                  />
                </div>

                <div className="col-auto pl-0">
                  <a
                    className={[
                      "btn btn-primary",
                      classes.Icons,
                      item.vk ? "" : classes.Disabled,
                    ].join(" ")}
                    type="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    {...getSocial(item, "vk")}
                  >
                    <em className="fab fa-vk" />
                  </a>

                  <a
                    className={[
                      "btn btn-primary",
                      classes.Icons,
                      item.twitter ? "" : classes.Disabled,
                    ].join(" ")}
                    type="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    {...getSocial(item, "twitter")}
                  >
                    <em className="fab fa-twitter" />
                  </a>

                  <a
                    className={[
                      "btn btn-primary",
                      classes.Icons,
                      item.instagram ? "" : classes.Disabled,
                    ].join(" ")}
                    type="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    {...getSocial(item, "instagram")}
                  >
                    <em className="fab fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      ) : null}
    </>
  );
};

export default leaderItem;
