import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import swal from "sweetalert";

import { setLoading } from "@store/mainPage/actions";
import * as profileApi from "@api/profile";
import * as festApi from "@api/fest";

import MyCrew from "./myCrew/index";
import MyLeaders from "./myLeaders/index";
import MyInvites from "./myInvites/index";

import classes from "./index.module.css";

import CrewItem from "./myCrew/crewItem/index";

class MyTeam extends Component {
  state = {
    crewsIsExpanded: false,
    invitesIsExpanded: false,
    leadersIsExpanded: false,

    invites: [],
    crews: [1, 2],
    leaders: [],
    teammates: [],
    deleteUsrTeammateId: "",
    rejectInviteGuid: "",
    acceptInviteGuid: "",
    deleteType: "",
    festsById: {},
    inviteLink: "",
    inviteLinkData: null,
  };

  loadData = async () => {
    const { setLoading } = this.props;

    try {
      setLoading(true);

      const teamData = await profileApi.getTeamInfo();
      const invites = await profileApi.getInvites();
      const fests = await festApi.getFests(false);

      const festsById = {};

      for (let i = 0; i < fests.length; i++) {
        festsById[fests[i].id] = fests[i];
      }

      const { leaders, teammates } = teamData;

      const urlParams = new URLSearchParams(window.location.search);
      const inviteLink = urlParams.get("invite-link");

      let inviteLinkData = null;
      if (!!inviteLink) {
        inviteLinkData = await profileApi.getParticipantByInviteLink(
          inviteLink
        );
      }

      this.setState({
        leaders, //
        teammates,
        invites,
        festsById,
        inviteLink,
        inviteLinkData,
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      swal("Ошибка", "Не удалось получить данные", "error");
    }
  };

  componentDidMount = async () => {
    // To Do добавить загрузку
    await this.loadData();
  };

  onCancelClick = async () => {
    const { history } = this.props;
    history.push("/");
  };

  onChange = (type, value) => {
    console.log(type, value);
    this.setState({ [type]: value });
  };

  onClick = (type) => {
    this.setState({ [type]: !this.state[type] });
  };

  handleDeleteLeader = (usrTeammateId) => {
    this.setState({
      deleteUsrTeammateId: usrTeammateId,
      deleteType: "leader",
    });
  };

  handleOpenContactInfoteammates = (usrTeammateId) => {
    const { teammates } = this.state;

    const newTeammates = JSON.parse(JSON.stringify(teammates));

    const changedTeammates = newTeammates.map((item) => {
      if (item.usrTeammateId === usrTeammateId) {
        const oldIsContactInfoOpen = item.isContactInfoOpen;

        item.isContactInfoOpen = !oldIsContactInfoOpen;
      }
      return item;
    });

    this.setState({ teammates: changedTeammates });
  };

  handleOpenContactInfoLeaders = (usrTeammateId) => {
    const { leaders } = this.state;

    const newLeaders = JSON.parse(JSON.stringify(leaders));

    const changedLeaders = newLeaders.map((item) => {
      if (item.usrTeammateId === usrTeammateId) {
        const oldIsContactInfoOpen = item.isContactInfoOpen;
        console.log(oldIsContactInfoOpen);
        item.isContactInfoOpen = !oldIsContactInfoOpen;
      }

      return item;
    });

    this.setState({ leaders: changedLeaders });
  };
  handleDeleteParticipant = (usrTeammateId) => {
    this.setState({
      deleteUsrTeammateId: usrTeammateId,
      deleteType: "participant",
    });
  };

  confirmDelete = async () => {
    try {
      const { deleteUsrTeammateId } = this.state;
      const { setLoading } = this.props;

      setLoading(false);

      await profileApi.deleteUsrTeammate(deleteUsrTeammateId);

      this.setState(
        {
          deleteUsrTeammateId: "",
          deleteType: "",
        },
        async () => {
          await this.loadData();
        }
      );
    } catch (e) {
      swal("Ошибка", "", "error");
    }
  };

  confirmReject = async () => {
    try {
      const { rejectInviteGuid } = this.state;
      const { setLoading } = this.props;

      setLoading(false);

      await profileApi.rejectInvite(rejectInviteGuid);

      this.setState(
        {
          rejectInviteGuid: "",
          deleteType: "",
        },
        async () => {
          await this.loadData();
        }
      );
    } catch (e) {
      swal("Ошибка", "Не удалось отклонить приглашение", "error");
    }
  };

  discardReject = () => {
    this.setState({
      rejectInviteGuid: "",
      deleteType: "",
    });
  };

  handleInviteRejectClick = (rejectInviteGuid) => {
    this.setState({
      rejectInviteGuid: rejectInviteGuid,
      deleteType: "invite",
    });
  };

  confirmAccept = async () => {
    try {
      const { acceptInviteGuid } = this.state;
      const { setLoading } = this.props;

      setLoading(false);

      await profileApi.acceptInvite(acceptInviteGuid);

      this.setState(
        {
          acceptInviteGuid: "",
        },
        async () => {
          await this.loadData();
        }
      );
    } catch (e) {
      if (e.message === "ALREADY_ACCEPTED") {
        swal(
          "Ошибка",
          "Данное приглашение более неактуально, Вы можете уточнить у лидера, в чём дело, или отклонить данное приглашение.",
          "error"
        );
      } else {
        swal("Ошибка", "Не удалось принять приглашение", "error");
      }
    }
  };

  discardAcceptInvite = () => {
    this.setState({
      acceptInviteGuid: "",
    });
  };

  handleInviteAcceptClick = (acceptInviteGuid) => {
    this.setState({
      acceptInviteGuid,
    });
  };

  discardDelete = () => {
    this.setState({
      deleteUsrTeammateId: "",
      deleteType: "",
    });
  };

  handleDiscardInviteLink = () => {
    const { history } = this.props;

    history.push("/profile/my-team");

    this.setState({
      inviteLink: "",
      inviteLinkData: null,
    });
  };

  handleAcceptInviteClick = async () => {
    const { inviteLink } = this.state;
    const { setLoading, history } = this.props;

    try {
      setLoading(true);

      const acceptInviteGuid = await profileApi.acceptInviteByLink(inviteLink);

      history.push("/profile/my-team");

      this.setState(
        {
          inviteLink: "",
          inviteLinkData: null,
        },
        async () => {
          try {
            setLoading(false);

            await profileApi.acceptInvite(acceptInviteGuid);

            swal(
              "Это сообщение об успехе!",
              "Приглашение успешно принято!",
              "success"
            );

            await this.loadData();
          } catch (e) {
            if (e.message === "ALREADY_ACCEPTED") {
              swal(
                "Ошибка",
                "Данное приглашение более неактуально, Вы можете уточнить у лидера, в чём дело, или отклонить данное приглашение.",
                "error"
              );
            } else {
              swal("Ошибка", "Не удалось принять приглашение", "error");
            }
          }
        }
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);

      swal("Ошибка", "Не удалось принять приглашение", "error");
    }
  };

  render = () => {
    const {
      crewsIsExpanded,
      invitesIsExpanded,
      leadersIsExpanded,
      festsById,
      teammates,
      leaders,
      invites,

      deleteUsrTeammateId,
      rejectInviteGuid,
      acceptInviteGuid,
      deleteType,
      inviteLink,
      inviteLinkData,
    } = this.state;

    return (
      <>
        <Modal isOpen={!!inviteLink} style={{ maxWidth: "37rem" }}>
          <ModalBody>
            <p>
              <strong>
                Ура! Вы получили ссылку-приглашение! Но эта ссылка одноразовая:
                если её принять то никто более не сможет ею воспользоваться.
              </strong>
            </p>

            <div
              style={{
                border: "1px dotted #cfdbe2",
                borderTop: "none",
                margin: "0",
                marginBottom: "1rem",
              }}
            ></div>

            {!!inviteLinkData ? (
              <CrewItem
                item={inviteLinkData}
                festsById={festsById}
                hideControls
              />
            ) : (
              <></>
            )}
          </ModalBody>

          <ModalFooter>
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <div>
                <Button color="danger" onClick={this.handleDiscardInviteLink}>
                  Отмена
                </Button>
              </div>

              <div>
                <Button color="primary" onClick={this.handleAcceptInviteClick}>
                  Принять
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>

        <Modal isOpen={!!acceptInviteGuid}>
          <ModalHeader>
            <strong>Вы хотите принять приглашение?</strong>
          </ModalHeader>

          <ModalBody>
            <p>
              Вы согласны принять приглашение и присоединиться к команде лидера?
              Обратите внимание, что при принятии приглашения лидер сможет
              добавлять вас в его последующие проекты без приглашения.
            </p>
          </ModalBody>

          <ModalFooter className="d-flex justify-content-end">
            <Button
              className="col-2"
              color="success"
              onClick={this.confirmAccept}
            >
              Да
            </Button>{" "}
            <Button
              className="col-2"
              color="secondary"
              onClick={this.discardAcceptInvite}
            >
              Нет
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={!!deleteUsrTeammateId || !!rejectInviteGuid}>
          <ModalHeader>
            <strong>
              {deleteType === "leader" ? "Вы хотите покинуть команду?" : ""}
              {deleteType === "participant"
                ? "Вы хотите исключить участника из команды?"
                : ""}
              {deleteType === "invite"
                ? "Вы хотите отклонить приглашение?"
                : ""}
            </strong>
          </ModalHeader>

          <ModalBody>
            {deleteType === "leader" ? (
              <>
                <p>
                  Вы сможете вернуться в команду позже, если захотите, но
                  возможно, лучше остаться...
                </p>

                <p>
                  Вы уверены, что хотите выйти из команды лидера? Вы останетесь
                  в уже созданных заявках, но лидер не сможет вас добавить в
                  новые.
                </p>
              </>
            ) : null}

            {deleteType === "participant" ? (
              <>
                <p>
                  Вы сможете пригласить участника в свою команду позже, если
                  захотите, но возможно, следует его оставить...
                </p>

                <p>
                  Вы уверены, что хотите исключить участника из команды? Он
                  останется в уже созданных заявках, но Вы больше не сможете
                  добавить его в новые.
                </p>
              </>
            ) : null}

            {deleteType === "invite" ? (
              <>
                <p>Вы уверены, отклонить приглашение на участие в заявке? :0</p>
              </>
            ) : null}
          </ModalBody>

          <ModalFooter>
            {deleteType === "leader" || deleteType === "participant" ? (
              <>
                <Button color="danger" onClick={this.confirmDelete}>
                  Да
                </Button>{" "}
                <Button color="secondary" onClick={this.discardDelete}>
                  Нет
                </Button>
              </>
            ) : null}

            {deleteType === "invite" ? (
              <>
                <Button color="danger" onClick={this.confirmReject}>
                  Да
                </Button>{" "}
                <Button color="secondary" onClick={this.discardReject}>
                  Нет
                </Button>
              </>
            ) : null}
          </ModalFooter>
        </Modal>

        <Card className={classes.Card}>
          <CardBody>
            <div className="row justify-content-center">
              <div className="col-12">
                <form>
                  <MyCrew
                    crews={invites}
                    festsById={festsById}
                    crewsIsExpanded={crewsIsExpanded}
                    onClick={(type) => this.onClick(type)}
                    onRejectClick={this.handleInviteRejectClick}
                    onAcceptClick={this.handleInviteAcceptClick}
                  />

                  <MyLeaders
                    leadersIsExpanded={leadersIsExpanded}
                    leaders={leaders}
                    openContactInfo={(usrTeammateId) =>
                      this.handleOpenContactInfoLeaders(usrTeammateId)
                    }
                    onClick={(type) => this.onClick(type)}
                    onDeleteClick={this.handleDeleteLeader}
                  />

                  <MyInvites
                    invitesIsExpanded={invitesIsExpanded}
                    teammates={teammates}
                    openContactInfo={(usrTeammateId) =>
                      this.handleOpenContactInfoteammates(usrTeammateId)
                    }
                    onClick={(type) => this.onClick(type)}
                    onDeleteClick={this.handleDeleteParticipant}
                  />
                </form>
              </div>
            </div>
          </CardBody>
        </Card>
      </>
    );
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyTeam));
