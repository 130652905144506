import React from "react";
import Select from "react-select";
import { Input } from "reactstrap";

import classes from "./index.module.css";

const newCityPart = ({
  newCity, //
  country,
  countries,
  submitted,
  onChange,
  isNewCityPartOpen,
}) => {
  return (
    <>
      {isNewCityPartOpen ? (
        <fieldset className="pb-2 mb-2">
          <div className="form-group mb-2  justify-content-end row">
            <div className="col-4 align-self-center">
              <div className="text-bold  float-right">
                <span className={classes.TextRed}>* </span>
                <span>Город</span>
              </div>
            </div>

            <div className="col-8">
              <Input
                type="text" //
                valid={submitted && !!newCity}
                invalid={submitted && !newCity}
                placeholder="Новый город..."
                value={newCity}
                onChange={(e) => onChange("newCity", e.target.value)}
              />
            </div>
          </div>

          <div className="form-group mb-2  justify-content-end row">
            <div className="col-4 align-self-center">
              <div className="text-bold  float-right">
                <span className={classes.TextRed}>* </span>
                <span>Страна</span>
              </div>
            </div>

            <div className="col-8">
              <Select
                name="select-name"
                value={country}
                className={[
                  submitted && !!country ? classes.Valid : "",
                  submitted && !country ? classes.Invalid : "",
                ].join(" ")}
                placeholder="Выберите страну..."
                onChange={(e) => onChange("country", e)}
                options={countries}
              />
            </div>
          </div>
        </fieldset>
      ) : null}
    </>
  );
};

export default newCityPart;
