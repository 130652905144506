import React, { Component } from "react";
import { Container, Card, Col, CardBody, CardHeader } from "reactstrap";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";

import { setProfileImageAction } from "@store/profile/actions";
import avatar from "@components/Assets/ava3.jpg";

import classes from "./index.module.css";

class FormUpload extends Component {
  onDrop = (acceptedFiles) => {
    const { setProfileImage } = this.props;

    const file = Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0]),
    });

    setProfileImage(file);
  };

  render() {
    const { profileImage } = this.props;

    const image = profileImage ? profileImage.preview : avatar;

    return (
      <Col lg="5">
        <Card className={["card-default", classes.Card].join(" ")}>
          <CardBody className="text-center">
            <div className="py-4">
              <img
                className="img-fluid rounded-circle img-thumbnail thumb96"
                src={image}
                alt="Contact"
              />
            </div>
          </CardBody>
        </Card>

        <Card
          className={["card-default d-none d-lg-block", classes.Card].join(" ")}
        >
          <CardHeader className="p-1">
            <div className="card-title text-center">Загрузка файла аватара</div>
          </CardHeader>

          <CardBody className="pt-1">
            <Container className="container-md">
              <Dropzone
                className={["card p-0", classes.BoxShadowDisabled].join(" ")}
                ref="dropzone"
                onDrop={this.onDrop}
              >
                <div
                  className={[
                    "text-center box-placeholder m-0",
                    classes.Dropzone,
                  ].join(" ")}
                >
                  Нажмите для выбора файла или просто перетащите файл сюда
                </div>
              </Dropzone>
            </Container>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  profileImage: state.profile.profileImage,
});

const mapDispatchToProps = (dispatch) => ({
  setProfileImage: (file) => {
    dispatch(setProfileImageAction(file));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormUpload);
