import React, { Component } from "react";
import { Card, CardBody, Input } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { v4 } from "uuid";

import "react-toastify/dist/ReactToastify.css";

import { setLoading } from "@store/mainPage/actions";
import ModalDialog from "@components/Custom/modalDialog/index";
import config from "@generic/config";
import * as profileApi from "@api/profile";

import Footer from "../../../../profile/components/footer/index";
import { getUserFullText } from "../../../../../utils/user";

import classes from "./index.module.css";
import swal from "sweetalert";

class containerModal extends Component {
  constructor(props) {
    super(props);

    this.copyRef = React.createRef();
  }

  state = {
    submitted: false,
    participantArray: [],
    participantRole: "",
    town: "",
    phone: "",
    socialForInvite: "",
    inviteLink: "",
    vk: "",
    instagram: "",
    twitter: "",
    participantName: null,
    inviteExpanded: false,
    inviteText: "",
    participantSaved: false,
  };

  onChange = (type, e) => {
    if (type === "fileIsActual") {
      this.setState({ [type]: !this.state[type] });
    } else {
      this.setState({
        [type]: e,
      });
    }

    if (type === "participantName") {
      this.setState({
        town: e.town,
        phone: e.phone,
        vk: e.vk,
        twitter: e.twitter,
        instagram: e.instagram,
      });
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  toggleInviteExpanded = () => {
    this.setState({
      inviteExpanded: !this.state.inviteExpanded,
    });
  };

  handleSubmit = () => {
    const {
      participantName, //
      participantRole,
      socialForInvite,
      inviteLink,
    } = this.state;

    const { participantArray, onConfirmClick } = this.props;

    if (!!participantName && participantName.value) {
      const user = participantArray.find((item) => {
        return item.id === participantName.value;
      });

      onConfirmClick(user, participantRole, "", "");
    } else {
      onConfirmClick({}, participantRole, socialForInvite, inviteLink);
    }
  };

  handleSelectInputChange = (value, action) => {
    if (action.action === "input-change") {
      this.setState({
        participantName: null,
      });
    }
  };

  componentDidMount = () => {
    const { editParticipantIndex, currentParticipants } = this.props;
    let participantSaved = false;

    if (editParticipantIndex !== undefined) {
      const participantName = {
        value: currentParticipants[editParticipantIndex].user.id,
        label: getUserFullText(currentParticipants[editParticipantIndex].user),
      };

      let inviteText = "";
      participantSaved = !!currentParticipants[editParticipantIndex].id;

      if (
        currentParticipants[editParticipantIndex].id &&
        !!currentParticipants[editParticipantIndex].invites
      ) {
        if (currentParticipants[editParticipantIndex].invites.length === 0) {
          inviteText = "Этого приглашения еще нет ни у кого в профиле";
        } else {
          const agreed = currentParticipants[editParticipantIndex].invites.find(
            (invite) => invite.isConfirmed === true && invite.isAgreed === true
          );

          if (!agreed) {
            const pending = currentParticipants[editParticipantIndex].invites
              .sort((a, b) =>
                moment(b.createdAt).isAfter(moment(a.createdAt)) ? 1 : -1
              )
              .find((invite) => invite.isConfirmed === false);

            if (!!pending) {
              inviteText = `Приглашение отправлено ${moment(
                pending.createdAt
              ).format(
                "DD.MM.YYYY в HH:mm"
              )}, ожидаем согласия от приглашаемого...`;
            } else {
              const rejected = currentParticipants[editParticipantIndex].invites
                .sort((a, b) =>
                  moment(b.dateConfirmed).isAfter(moment(a.dateConfirmed))
                    ? 1
                    : -1
                )
                .find(
                  (invite) =>
                    invite.isConfirmed === true && invite.isAgreed === false
                );

              if (!!rejected) {
                inviteText = `Приглашаемый отказался от участия ${moment(
                  rejected.dateConfirmed
                ).format("DD.MM.YYYY в HH:mm")}`;
              }
            }
          }
        }
      }

      this.setState({
        participantName,
        town:
          currentParticipants[editParticipantIndex].user &&
          currentParticipants[editParticipantIndex].user.town
            ? currentParticipants[editParticipantIndex].user.town.name
            : "",
        phone: currentParticipants[editParticipantIndex].user
          ? currentParticipants[editParticipantIndex].user.phone
          : "",
        participantRole:
          currentParticipants[editParticipantIndex].performerInfo,
        inviteText,
        participantSaved,
        instagram: currentParticipants[editParticipantIndex].user.instagram,
        twitter: currentParticipants[editParticipantIndex].user.twitter,
        vk: currentParticipants[editParticipantIndex].user.vk,
        socialForInvite:
          currentParticipants[editParticipantIndex].socialForInvite,
        inviteLink: currentParticipants[editParticipantIndex].inviteLink,
      });
    }
  };

  onCreateLinkBySocialClick = async () => {
    const {
      editParticipantIndex,
      currentParticipants,
      onCreateLinkBySocialClick,
    } = this.props;

    const { socialForInvite } = this.state;

    if (
      editParticipantIndex !== undefined &&
      !!currentParticipants[editParticipantIndex].id
    ) {
      onCreateLinkBySocialClick(
        currentParticipants[editParticipantIndex].id,
        socialForInvite
      );
    } else {
      const { socialForInvite } = this.state;

      try {
        await profileApi.checkSocial(socialForInvite);

        swal("Это сообщение об успехе", "Участник найден", "success");
      } catch (e) {
        swal("Ошибка!", "Не удалось найти участника", "error");
      }
    }
  };

  onCreateInviteLinkClick = () => {
    const {
      editParticipantIndex,
      onCreateInviteLinkClick,
      currentParticipants,
    } = this.props;

    const { inviteLink } = this.state;

    if (
      editParticipantIndex !== undefined &&
      !!currentParticipants[editParticipantIndex].id
    ) {
      onCreateInviteLinkClick(currentParticipants[editParticipantIndex].id);
    } else {
      if (!!inviteLink) {
        return;
      } else {
        const inviteLink = v4();

        this.setState({
          inviteLink,
        });
      }
    }
  };

  copyToClipboard = (e) => {
    this.copyRef.current.select();
    document.execCommand("copy");

    e.target.focus();

    toast("Скопировано в буфер обмена!", {
      type: "success",
      position: "top-right",
    });
  };

  render() {
    const {
      town, //
      phone,
      socialForInvite,
      participantRole,
      participantName,
      inviteExpanded,
      inviteText,
      participantSaved,
      vk,
      instagram,
      twitter,
    } = this.state;

    let { inviteLink } = this.state;

    const {
      participantArray, //
      currentParticipants,
      editParticipantIndex,
      onCancelClick,
      onDeleteClick,
    } = this.props;

    const filteredParticipants = [];

    participantArray.forEach((participant) => {
      if (
        !currentParticipants.some((item) => item.user.id === participant.id)
      ) {
        filteredParticipants.push(participant);
      }
    });

    const options = filteredParticipants.map((item) => ({
      value: item.id,
      label: getUserFullText(item),
      town: item.town.name,
      phone: item.phone,
      vk: item.vk,
      instagram: item.instagram,
      twitter: item.twitter,
    }));

    if (
      editParticipantIndex !== undefined &&
      !!currentParticipants[editParticipantIndex].id
    ) {
      const l = currentParticipants[editParticipantIndex].inviteLink;
      inviteLink = l;
    }

    console.log(inviteLink);

    const getSocial = (item, key) => {
      switch (key) {
        case "vk":
          if (item) {
            return { href: item };
          } else {
            return {};
          }
        case "twitter":
          if (item) {
            return { href: item };
          } else {
            return {};
          }
        case "instagram":
          if (item) {
            return { href: item };
          } else {
            return {};
          }
        default:
          return {};
      }
    };

    return (
      <ModalDialog>
        <Card className={[classes.ModalContent].join(" ")}>
          <CardBody className="pb-0">
            <fieldset className="pb-2 mb-2">
              <div className="text-bold pb-1">
                <span>Выберите участника заявки из своей команды</span>
              </div>

              <div>
                <Select
                  value={participantName}
                  className={classes.SelectFileType}
                  placeholder="Начните вводить имя участника..."
                  onChange={(e) => this.onChange("participantName", e)}
                  options={options}
                  onInputChange={this.handleSelectInputChange}
                />
              </div>

              <div className="text-bold pt-2">
                <span>Или </span>

                <span
                  className={classes.TextButton}
                  onClick={this.toggleInviteExpanded}
                >
                  пригласите участника в свою команду, если в списке его нет +
                </span>
              </div>

              {/* {!participantSaved ? ( */}
              <p>
                Здесь вы можете пригласить людей в свою команду.
              </p>
              {/* ) : null} */}
            </fieldset>

            {/* {!participantSaved ? (
              <></>
            ) : ( */}
            <>
              {!inviteExpanded ? null : (
                <fieldset className="pb-2 mb-2">
                  <div>
                    <fieldset className="pb-2 mb-2">
                      <div className={classes.ContainerModalHint}>
                        Укажите профиль социальной сети для отправки
                        приглашения. Приглашение будет отправлено, если такой
                        профиль будет найден в нашей с вами системе заявок.
                        <div className={classes.Underline}>
                          Участнику нужно будет зайти в нашу систему заявок и
                          принять приглашение.
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="pb-2 mb-2">
                      <div className="form-group justify-content-end row">
                        <div className="col-3 pr-1 align-self-center ">
                          <div className="text-bold float-right ">
                            Ссылка на профиль
                          </div>
                        </div>

                        <div className="col-9 pl-1">
                          <div className="d-flex">
                            <div className="col p-0">
                              <Input
                                type="text" //
                                value={socialForInvite}
                                className={classes.InviteInputText}
                                onChange={(e) =>
                                  this.onChange(
                                    "socialForInvite",
                                    e.target.value
                                  )
                                }
                              />
                            </div>

                            <div className="col-auto pr-0 pl-2">
                              <a
                                className={[
                                  "btn btn-primary",
                                  classes.Icons,
                                ].join(" ")}
                                type="button"
                                onClick={this.onCreateLinkBySocialClick}
                              >
                                <em className="fa-1x fas fa-user-friends" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="pb-2 mb-2">
                      <div className={classes.ContainerModalHint}>
                        Либо можно просто поделиться ссылкой любым удобным вам
                        способом. По ссылке можно пригласить одного участника.
                      </div>
                    </fieldset>

                    <fieldset className="pb-2 mb-2">
                      <div className="form-group justify-content-end row">
                        <div className="col-3 pr-1 align-self-center ">
                          <div className="text-bold float-right ">
                            Ссылка-приглашение
                          </div>
                        </div>

                        <div className="col-9 pl-1">
                          <div className="d-flex">
                            <div className="col-auto  pr-2 pl-0">
                              <a
                                className={[
                                  "btn btn-primary",
                                  classes.Icons,
                                ].join(" ")}
                                type="button"
                                onClick={this.onCreateInviteLinkClick}
                              >
                                <em className="fa-1x fas fa-sync" />
                              </a>
                            </div>

                            <div className="col p-0">
                              <input
                                className="form-control"
                                type="text" //
                                readOnly
                                ref={this.copyRef}
                                value={
                                  inviteLink
                                    ? `${config.get(
                                        "inviteUrl"
                                      )}/invite/${inviteLink}`
                                    : "← Получить ссылку-приглашение"
                                }
                              />
                            </div>

                            <div className="col-auto pr-0 pl-2">
                              <button
                                className={[
                                  "btn btn-primary",
                                  classes.Icons,
                                ].join(" ")}
                                type="button"
                                onClick={this.copyToClipboard}
                              >
                                <em className="fa-1x fas fa-share-alt" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-9 pl-1 offset-3">
                          Чтобы ссылка с приглашением заработала, нужно нажать «Сохранить» в этом окне, а потом сохранить заявку. 
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="pb-0 mb-0">
                      <div className={classes.ContainerModalStatus}>
                        {!!inviteText ? inviteText : ""}
                      </div>
                    </fieldset>
                  </div>
                </fieldset>
              )}
            </>
            {/* )} */}

            <fieldset className="pb-2 mb-2">
              <div className="form-group justify-content-end row ">
                <div className="col-3 pr-1 align-self-center ">
                  <div className="text-bold float-right ">
                    <span className={classes.TextRed}>* </span>
                    <span>Роль/Персонаж</span>
                  </div>
                </div>

                <div className="col-9 pl-1">
                  <Input
                    type="text" //
                    value={participantRole}
                    className={classes.InviteInputText}
                    onChange={(e) =>
                      this.onChange("participantRole", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-9 pl-1 offset-3">
                  Наруто, Пэкхён, школьник, бэк-вокалист, подтанцовка, помощник,
                  художник, представитель, продавец, фотограф, волонтёр,
                  массовка, и т.д.
                </div>
              </div>
            </fieldset>

            <fieldset className="pb-2 mb-2">
              <div className="form-group justify-content-end row mb-2">
                <div className="col-3 pr-1 align-self-center ">
                  <div className="text-bold float-right ">Город</div>
                </div>

                <div className="col-9 pl-1">
                  <Input
                    type="text" //
                    disabled
                    className={classes.InviteInputText}
                    readOnly
                    value={town}
                  />
                </div>
              </div>

              <div className="form-group justify-content-end row">
                <div className="col-3 pr-1 align-self-center ">
                  <div className="text-bold float-right ">Контакты</div>
                </div>

                <div className="col-9 pl-1">
                  <div className="d-flex">
                    <div className="col p-0 pr-1">
                      <Input
                        type="text" //
                        disabled
                        className={classes.InviteInputText}
                        value={phone}
                      />
                    </div>

                    <div className="col-auto p-0">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        {...getSocial(vk, "vk")}
                        className={[
                          "btn btn-primary mr-1 ml-1",
                          classes.Icons,
                          vk ? "" : classes.Disabled,
                        ].join(" ")}
                        type="button"
                      >
                        <em className="fab fa-vk" />
                      </a>

                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        {...getSocial(twitter, "twitter")}
                        className={[
                          "btn btn-primary mr-1 ml-1",
                          classes.Icons,
                          twitter ? "" : classes.Disabled,
                        ].join(" ")}
                        type="button"
                      >
                        <em className="fab fa-twitter" />
                      </a>

                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        {...getSocial(instagram, "instagram")}
                        className={[
                          "btn btn-primary ml-1",
                          classes.Icons,
                          instagram ? "" : classes.Disabled,
                        ].join(" ")}
                        type="button"
                      >
                        <em className="fab fa-instagram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </CardBody>

          <Footer
            onDeleteClick={onDeleteClick}
            onSubmitClick={this.handleSubmit}
            onCancelClick={onCancelClick}
            showDelete={editParticipantIndex || editParticipantIndex === 0}
          />
        </Card>
      </ModalDialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => {
    dispatch(setLoading(loading));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(containerModal));
