import React from "react";
import moment from "moment";

import classes from "./dates.module.css";

const months = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июНя",
  "июЛя",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

const dates = ({ dates, festActive }) => {
  if (dates.length === 0) {
    if (!festActive) {
      return <span className={classes.Inactive}>Даты неизвестны</span>;
    }

    return <span className={classes.Date}>Даты неизвестны</span>;
  }

  if (dates.length === 1) {
    if (!festActive) {
      return (
        <span className={classes.Inactive}>
          {moment(dates[0]).format("DD.MM.YYYY")} г.
        </span>
      );
    }

    const d = moment(dates[0]);

    const month = d.month();
    const date = d.date();

    return (
      <>
        <span className={classes.Date}>{date}</span>{" "}
        <span className={classes.Month}>{months[month]}</span>
      </>
    );
  }

  if (!festActive) {
    return (
      <span className={classes.Inactive}>
        {moment(dates[0]).format("DD.MM.YYYY")} -{" "}
        {moment(dates[dates.length - 1]).format("DD.MM.YYYY")} г.
      </span>
    );
  }

  const start = moment(dates[0]);
  const end = moment(dates[dates.length - 1]);

  // const monthStart = start.month();
  const dateStart = start.date();

  const monthEnd = end.month();
  const dateEnd = end.date();

  return (
    <>
      <span className={classes.Date}>
        {dateStart}-{dateEnd}
      </span>{" "}
      <span className={classes.Month}>{months[monthEnd]}</span>
    </>
  );
};

export default dates;
