import axios from "axios";

import authService from "@services/auth";

let instance = null;

// const httpHistoryStorage = [];

// const HTTP_HISTORY_STORAGE_MAX_COUNT = 15;

// const addToHistoryStorage = (item) => {
//   httpHistoryStorage.push(item);

//   if (httpHistoryStorage.length > HTTP_HISTORY_STORAGE_MAX_COUNT) {
//     httpHistoryStorage.shift();
//   }
// };

export function createInstance(apiUrl, onLogout) {
  instance = axios.create({
    baseURL: apiUrl,
  });

  instance.interceptors.request.use((configParam) => {
    if (configParam.url === "/api/v1/auth/login") {
      return configParam;
    }

    const config = { ...configParam };
    config.headers.Authorization = `Bearer ${authService.accessToken}`;
    config.accessToken = authService.accessToken;

    if (config.headers.__isRetryRequest) {
      config.__isRetryRequest = true;
      delete config.headers.__isRetryRequest;
    }

    const {
      url, //
      data,
      method,
    } = config;

    // addToHistoryStorage({
    //   timestamp: new Date().valueOf(),
    //   type: "request",
    //   url,
    //   method,
    //   data,
    // });

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      const config = response && response.config ? response.config : {};

      const { data, status } = response;
      const { method, url } = config;

      // addToHistoryStorage({
      //   timestamp: new Date().valueOf(),
      //   type: "response",
      //   url,
      //   method,
      //   data,
      //   status,
      // });

      return response;
    },
    (error) => {
      if (error.config.url === "/api/v1/auth/login") {
        return Promise.reject(error);
      }

      const response = error && error.response ? error.response : {};
      const config = error && error.config ? error.config : {};

      const { data, status } = response;
      const { method, url } = config;

      // addToHistoryStorage({
      //   timestamp: new Date().valueOf(),
      //   type: "httpError",
      //   url,
      //   method,
      //   data,
      //   status,
      // });

      // Если это вторая попытка отправки запроса, и на ней мы ловим ошибку,
      // значит тут уже исправить ничего не получится
      if (config.__isRetryRequest) {
        return Promise.reject(error);
      }

      // Здесь обрабатываем только 401 ошибку,
      // остальные отдаем дальше по цепи как есть
      if (config && error.response && error.response.status === 401) {
        onLogout();

        return Promise.reject();

        /*

        // axios 0.19 не поддерживает проброс кастомных параметров в настоящий момент.
        // Приходится грязным хаком через headers.
        // В перехватчике запроса эта ситуация нивелируется.
        config.headers.__isRetryRequest = true;

        // Если токен уже успел обновиться, то пытаемся перепослать запрос с новым токеном
        if (config.accessToken !== authService.accessToken) {
          config.headers.Authorization = `Bearer ${authService.accessToken}`;

          return instance.request(config);
        }

        // Если токен еще не обновился, пинаем authService, чтобы обновил (на всякий случай),
        // подписываемся на изменение токена (на случай нескольких параллельных запросов),
        // и в колбэке перепосылаем запрос
        return new Promise((resolve, reject) => {
          authService.subscribeOnTokenRefresh((newAccessToken) => {
            config.headers.Authorization = `Bearer ${newAccessToken}`;

            instance.request(config).then(resolve).catch(reject);
          });

          authService.getNewAccessToken();
        });

        */
      }

      return Promise.reject(error);
    }
  );
}

export function getInstance() {
  return instance;
}

// class AxiosError extends Error {
//   constructor(args) {
//     super(args);

//     this.name = "AxiosError";
//   }

//   setHttpStatusCode(code) {
//     this.responseCode = code;
//   }

//   getHttpStatusCode() {
//     return this.responseCode;
//   }

//   setErrorCode(error) {
//     this.errorCode = error;
//   }

//   getErrorCode() {
//     return this.errorCode;
//   }
// }

// export function wrapAxiosError(message, e) {
//   const wrapped = new AxiosError(message);
//   const getDeepErrorCode = (p, o) =>
//     p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

//   wrapped.setHttpStatusCode((e && e.request && e.request.status) || 0);
//   wrapped.setErrorCode(
//     getDeepErrorCode(["response", "data", "details", 0, "code"], e) || 0
//   );

//   return wrapped;
// }

// export function getHttpHistoryStorage() {
//   return [...httpHistoryStorage];
// }
