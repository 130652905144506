import React from "react";

import classes from "./index.module.css";

const ModalDialog = (props) => (
  <div className={[classes.Dialog].join(" ")} tabIndex="-1">
    <div className={classes.ModalContent}>{props.children}</div>
  </div>
);

export default ModalDialog;
