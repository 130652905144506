import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Card, //
  CardBody,
  CardFooter,
  Input,
} from "reactstrap";
import swal from "sweetalert";

import authService from "@services/auth";

import classes from "./index.module.css";

const Login = () => {
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isMemorized, setMemorized] = useState(false);

  const onSubmitClick = async () => {
    try {
      await authService.login(username, password);

      const urlParams = new URLSearchParams(window.location.search);
      const inviteLink = urlParams.get("invite-link");

      if (!!inviteLink) {
        history.push(`/profile/my-team?invite-link=${inviteLink}`);
      } else {
        history.push("/");
      }
    } catch (e) {
      swal(
        "Ошибка",
        "Не удалось войти. Проверьте правильность логина и пароля, и попробуйте еще раз.",
        "error"
      );
    }
  };

  const onSubmitKeyPress = async (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      e.preventDefault();

      try {
        await authService.login(username, password);

        const urlParams = new URLSearchParams(window.location.search);
        const inviteLink = urlParams.get("invite-link");

        if (!!inviteLink) {
          history.push(`/profile/my-team?invite-link=${inviteLink}`);
        } else {
          history.push("/");
        }
      } catch (e) {
        swal(
          "Ошибка",
          "Не удалось войти. Проверьте правильность логина и пароля и попробуйте еще раз.",
          "error"
        );
      }
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const inviteLink = urlParams.get("invite-link");

  return (
    <Card
      className={["card-default", classes.Card].join(" ")}
      onKeyPress={(e) => onSubmitKeyPress(e)}
    >
      <CardBody className={classes.CardBody}>
        <h3 className={classes.FormHeader}>Добро пожаловать!</h3>

        <p className={classes.Description}>
          Укажите свои учётные данные для входа.
        </p>

        <div className={classes.Form}>
          <div>
            <Input
              type="text" //
              className={["mb-2", classes.Input].join(" ")}
              placeholder="Укажите логин или электронную почту..."
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div>
            <Input
              type="password" //
              className={["mb-2", classes.Input].join(" ")}
              placeholder="Ваш пароль..."
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div>
            <div>
              <div className="checkbox c-checkbox">
                <label className="mb-1">
                  <Input
                    type="checkbox"
                    bsSize="sm"
                    checked={isMemorized}
                    onChange={() => setMemorized(!isMemorized)}
                  />
                  <span
                    className={["fa", "fa-check", classes.Check].join(" ")}
                  ></span>
                  Запомнить меня
                </label>
              </div>
            </div>
          </div>

          <hr />

          <div className={classes.SignUpWrapper}>
            <div className={classes.Hint}>Ещё не зарегистрированы?</div>

            <div
              onClick={() =>
                history.push(
                  !!inviteLink
                    ? `/register?invite-link=${inviteLink}`
                    : "/register"
                )
              }
              className={classes.CreateAccountLink}
            >
              <span>Создать учетную запись!</span>
            </div>
          </div>
        </div>
      </CardBody>

      <CardFooter className={classes.Footer}>
        <div className={classes.SubmitWrapper}>
          <Link to="/reset" className={classes.TextButton}>
            <strong>Восстановить пароль</strong>
          </Link>

          <div className={classes.SubmitButtonWrapper}>
            <button
              type="submit"
              className={["btn", "btn-primary", classes.Button].join(" ")}
              onClick={onSubmitClick}
            >
              Войти в учетную запись
            </button>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default Login;
