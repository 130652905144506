import React, { Component } from "react";
import { Card, CardBody, Input } from "reactstrap";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { FILE_TYPES } from "@components/Constants/requestFiles";
import ModalDialog from "@components/Custom/modalDialog/index";

import Footer from "../../../../profile/components/footer/index";

import classes from "./index.module.css";

class RequestFilesModal extends Component {
  state = {
    submitted: false,
    editorState: null,
    fileName: "",
    fileIsActual: true,
    file: null,
    fileType: { value: "track", label: "Трек для выхода" },
  };

  componentDidMount = async () => {
    console.log("requestFileModal");
  };

  onChange = (type, e) => {
    if (type === "fileIsActual") {
      this.setState({ [type]: !this.state[type] });
    } else {
      this.setState({
        [type]: e,
      });
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  render() {
    const {
      submitted, //
      fileType,
      fileName,
      fileIsActual,
      editorState,
    } = this.state;

    const { saveEditFile, closeEditFile } = this.props;

    return (
      <ModalDialog>
        <Card className={[classes.ModalContent].join(" ")}>
          <CardBody className="pb-0">
            <fieldset className="pb-2 mb-2">
              <div className="text-bold pb-1">
                <span className={classes.TextRed}>* </span>
                <span>Выберите назначение файла</span>
              </div>

              <div>
                <Select
                  value={fileType}
                  className={classes.SelectFileType}
                  onChange={(e) => this.onChange("fileType", e)}
                  options={FILE_TYPES}
                />
              </div>

              <div className={classes.IconFile}>
                <a className="px-4" href="">
                  <em
                    className={["fa-5x far fa-file-audio text-primary"].join(
                      " "
                    )}
                  ></em>
                </a>
              </div>
            </fieldset>

            <div className="form-group mb-2  justify-content-end row">
              <div className="col-2 pr-1 align-self-center">
                <div className="text-bold   float-right">
                  <span className={classes.TextRed}>* </span>
                  <span>Имя файла</span>
                </div>
              </div>

              <div className="col-10 pl-1">
                <Input
                  type="text" //
                  valid={submitted && !!fileName}
                  invalid={submitted && !fileName}
                  value={fileName}
                  placeholder="Имя файла..."
                  onChange={(e) => this.onChange("fileName", e.target.value)}
                />
              </div>
            </div>

            <fieldset className="pb-2 mb-2">
              <div className="form-group justify-content-end row">
                <div className="col-2 pr-1 align-self-center">
                  <div className="text-bold  float-right">
                    <span className={classes.TextRed}>* </span>
                    <span>Актуален</span>
                  </div>
                </div>

                <div className="col-10 pl-1">
                  <div className="checkbox c-checkbox m-0">
                    <label className={classes.CheckboxLabel}>
                      <Input
                        type="checkbox"
                        bsSize="sm"
                        checked={fileIsActual}
                        onChange={(e) => this.onChange("fileIsActual", e)}
                      />

                      <span className={["fa", "fa-check m-0"].join(" ")}></span>
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset className="pb-2 mb-2">
              <div className="text-bold  ">
                <span>Заметка к файлу</span>
              </div>

              <div className="bg-white mt-2">
                <Editor
                  editorState={editorState}
                  wrapperClassName="wysiwig-editor-wrapper"
                  editorClassName="form-control"
                  editorStyle={{ height: 150, lineHeight: 0.52857 }}
                  onEditorStateChange={(editorState) =>
                    this.onEditorStateChange(editorState)
                  }
                />
              </div>
            </fieldset>
          </CardBody>

          <Footer
            onSubmitClick={() => saveEditFile()}
            onCancelClick={closeEditFile}
          />
        </Card>
      </ModalDialog>
    );
  }
}

export default RequestFilesModal;
