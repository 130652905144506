import React, { Component } from "react";
import { ListGroup } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import * as requestApi from "@api/request";
import { setLoading } from "@store/mainPage/actions";

import { TAB_HISTORY } from "@components/Constants/request";

import { getDuringTime, getCommentIcon } from "../../../../utils/Request";

import classes from "./index.module.css";

class ActiveHistory extends Component {
  state = {
    historyList: [],
    error: false,
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { requestUrl },
      },
      setLoading,
    } = this.props;

    setLoading(true);

    try {
      if (requestUrl !== "new") {
        const historyList = await requestApi.getRequestLogs(requestUrl);

        this.setState({ historyList });
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);

      this.setState({
        error: true,
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.activeTab !== prevProps.activeTab &&
      this.props.activeTab === TAB_HISTORY
    ) {
      const {
        match: {
          params: { requestUrl },
        },
      } = this.props;

      try {
        const historyList = await requestApi.getRequestLogs(requestUrl);

        this.setState({ historyList });
      } catch (e) {
        this.setState({
          error: true,
        });
      }
    }
  };

  handleRequestSaved = async (data) => {
    const historyList = await requestApi.getRequestLogs(data.semanticUrl);

    this.setState({ historyList });
  };

  showMessage = (id) => {
    const { historyList } = this.state;

    const newHistoryList = historyList.map((item) => {
      if (item.id === id) {
        const oldIsCollapsed = item.isCollapsed;
        item.isCollapsed = !oldIsCollapsed;
      }
      return item;
    });

    this.setState({ historyList: newHistoryList });
  };

  getActivityMnemocode = (activityMnemocode) => {
    switch (activityMnemocode) {
      case "request_created":
        return "Создана заявка";

      case "request_participant_changed":
        return "Изменён список участников";

      case "request_modified":
        return "Внесены правки";

      case "request_state_changed":
        return "Статус заявки изменён";

      case "request_checked":
        return "Статус проверки";

      case "comment_added":
        return "Комментарий";

      // case "request_created":
      //   return "Загружен новый файл";

      // case "request_created":
      //   return "Приглашение куратора";

      default:
        return "Интересный";
    }
  };

  getMessage = (item) => {
    const message = item.messageTxt;

    switch (item.activityMnemocode) {
      case "request_created":
        return (
          <div>
            {!item.isCollapsed
              ? message.body.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={classes.MessageHeader}>
                        {item.fieldName}:
                      </div>

                      <div>
                        <span>{item.value}</span>
                      </div>
                    </div>
                  );
                })
              : null}

            {item.isCollapsed ? (
              <div>
                <div>
                  <div className={classes.MessageHeader}>
                    {message.body[0].fieldName}:
                  </div>

                  <div>
                    <span>{message.body[0].value}</span>
                  </div>
                </div>

                <button
                  className={classes.MessageButton}
                  onClick={() => this.showMessage(item.id)}
                >
                  (читать далее)
                </button>
              </div>
            ) : (
              <button
                className={classes.MessageButton}
                onClick={() => this.showMessage(item.id)}
              >
                (скрыть)
              </button>
            )}
          </div>
        );

      case "request_participant_changed":
        return (
          <div>
            <div className={classes.MessageHeader}>Список участников:</div>

            {message.participants.actual.map((item, index) => {
              return (
                <div key={index}>
                  <div>
                    <span>
                      {item.user_id !== null && item.user_id !== ""
                        ? item.user_text
                        : "Пока неизвестен"}
                    </span>
                  </div>
                </div>
              );
            })}

            {message.participants.added &&
            message.participants.added.length > 0 ? (
              <>
                <div
                  className={[
                    classes.MessageHeader,
                    classes.AddedParticipantsMessageColor,
                  ].join(" ")}
                >
                  Добавлены участники:
                </div>

                {message.participants.added.map((item, index) => {
                  return (
                    <div key={index}>
                      <div>
                        <span>
                          {item.user_id !== null && item.user_id !== ""
                            ? item.user_text
                            : "Пока неизвестен"}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}

            {message.participants.deleted &&
            message.participants.deleted.length > 0 ? (
              <>
                <div
                  className={[
                    classes.MessageHeader,
                    classes.DeletedParticipantsMessageColor,
                  ].join(" ")}
                >
                  Удалены участники:
                </div>

                {message.participants.deleted.map((item, index) => {
                  return (
                    <div key={index}>
                      <div>
                        {item.user_id !== null && item.user_id !== ""
                          ? item.user_text
                          : "Пока неизвестен"}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        );

      case "request_modified":
        return message.values.map((item, index) => {
          return (
            <div key={index}>
              <div className={classes.MessageHeader}>{item.field_name}:</div>

              <div>
                <span className={classes.MessageOriginalValue}>
                  {item.original_value}
                </span>

                <span>{item.new_value}</span>
              </div>
            </div>
          );
        });

      case "request_state_changed":
        return message.request_state.name;

      case "request_checked":
        return (
          <>
            {!message.completelyChecked ? (
              <>
                <div>
                  {message.need_to_correct &&
                  message.need_to_correct.length > 0 ? (
                    <div>
                      <div className={classes.MessageHeader}>
                        Требуется исправить:
                      </div>

                      {message.need_to_correct.map((item, index) => {
                        return (
                          <div key={index}>
                            <div>
                              <span>* {item.field_name}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>

                <div>
                  {message.wait_for_check &&
                  message.wait_for_check.length > 0 ? (
                    <div>
                      <div className={classes.MessageHeader}>
                        Осталось проверить:
                      </div>

                      {message.wait_for_check.map((item, index) => {
                        return (
                          <div key={index}>
                            <div>
                              <span>* {item.field_name}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              "Заявка проверена полностью"
            )}
          </>
        );

      case "comment_added":
        return (
          <div
            className={[classes.Text, "emoji-width-fix"].join(" ")}
            dangerouslySetInnerHTML={{ __html: message.comment }}
          />
        );

      // case "request_created":
      //   return "Загружен новый файл";

      // case "request_created":
      //   return "Приглашение куратора";

      default:
        return "Интересный";
    }
  };

  render() {
    const { historyList } = this.state;

    return (
      <>
        <ListGroup className="bg-white p-3">
          {historyList.map((item) => {
            const commentDuringTime = getDuringTime({
              createdTime: item.createdAt,
            });

            const comment = (
              <div className="bb pb-2 pt-2 bg-white" key={item.id}>
                <div className={["row", classes.CommentRow].join(" ")}>
                  <div className="col-12 bg-white">
                    <div className={classes.ActivityHeader}>
                      <span>
                        <em
                          className={["fa-1x mr-2", getCommentIcon(item)].join(
                            " "
                          )}
                        ></em>

                        <strong>{item.createdByName}</strong>

                        <span className={classes.CommentDuringTime}>
                          {commentDuringTime || "Только что"}
                        </span>
                      </span>

                      <span className={classes.ActivityType}>
                        {this.getActivityMnemocode(item.activityMnemocode)}
                      </span>
                    </div>

                    <div className={classes.Text}>{this.getMessage(item)}</div>
                  </div>
                </div>
              </div>
            );

            return comment;
          })}
        </ListGroup>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => {
    dispatch(setLoading(loading));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(ActiveHistory));
