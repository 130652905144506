import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import {
  Card, //
  CardBody,
  CardFooter,
  Input,
} from "reactstrap";
import swal from "sweetalert";

import * as festsApi from "@api/fest";

import classes from "./index.module.css";

class rules extends Component {
  state = {
    agree: false,
    requestTypeName: "",
    error: false,
    from: "",
    to: "",
    festFrom: null,
    festTo: null,
    festRules: "",
    typeRules: "",
    hint: "",
    currentFestivalDates: [],
  };

  componentDidMount = async () => {
    try {
      const {
        match: {
          params: { festUrl, typeUrl },
        },
      } = this.props;

      const [types, fest, fests] = await Promise.all([
        festsApi.getRequestTypeGroups(festUrl),
        festsApi.getFest(festUrl),
        festsApi.getFestsForDate(),
      ]);

      const allTypes = [];

      types.forEach((item) => {
        allTypes.push(...item.types);
      });

      const requestTypeName =
        allTypes.find((item) => item.url === typeUrl).name || "";
      const to = allTypes.find((item) => item.url === typeUrl).to || "";
      const hint = allTypes.find((item) => item.url === typeUrl).hint || "";
      const typeRules =
        allTypes.find((item) => item.url === typeUrl).typeRules || "";

      const currentFestivalDates =
        fests.find((item) => item.url === festUrl).dates || [];

      const [festFrom, festTo] = currentFestivalDates.map((item) =>
        moment(item)
      );

      this.setState({
        requestTypeName, //
        currentFestivalDates,
        to,
        festFrom,
        festTo,
        hint,
        typeRules,
        festRules: fest.festRules,
      });
    } catch (e) {
      this.setState({
        error: true,
      });

      swal("Ошибка", "Не удалось загрузить тип фестиваля", "error");
    }
  };

  getMonth = (month) => {
    switch (month) {
      case 0:
        return `Января`;
      case 1:
        return `Февраля`;
      case 2:
        return `Марта`;
      case 3:
        return `Апреля`;
      case 4:
        return `Мая`;
      case 5:
        return `Июня`;
      case 6:
        return `Июля`;
      case 7:
        return `Августа`;
      case 8:
        return `Сентября`;
      case 9:
        return `Октября`;
      case 10:
        return `Ноября`;
      case 11:
        return `Декабря`;
      default:
        return "";
    }
  };

  agreeRulesHandler = () => {
    this.setState({ agree: !this.state.agree });
  };

  render() {
    const {
      backToRequestType, //

      acceptRulesHandler,
      rulesShouldBeAccepted,
      showRulesHandler,
    } = this.props;

    const {
      agree, //
      error,
      requestTypeName,
      currentFestivalDates,
      to,
      festFrom,
      festTo,
      festRules,
      typeRules,
      hint,
    } = this.state;

    const monthDayFrom = moment(festFrom).month();
    const monthDayTo = moment(festTo).month();

    const monthNameFrom = this.getMonth(monthDayFrom);
    const monthNameTo = this.getMonth(monthDayTo);
    const dayNameFrom = moment(festFrom).format("DD");
    const dayNameTo = moment(festTo).format("DD");

    const monthDeadlineName = this.getMonth(moment(to).month());
    const dayDeadLineName = to ? moment(to).format("DD") : "";
    const hourDeadLineName = to ? moment(to).format("HH:mm") : "";

    if (error) {
      return (
        <div className="alert alert-danger m-2">
          <strong>Ошибка!</strong> Попробуйте обновить страницу, или повтороте
          попытку позже.
        </div>
      );
    }
    return (
      <div>
        <div className={classes.Alertwrapper}>
          <div className={classes.Alert}>
            <em className="fa-2x fas fa-exclamation-triangle"></em>
          </div>

          <div>
            <CardBody className="text-left">
              <div>Обязательно ознакомьтесь с правилами подачи заявки.</div>
            </CardBody>
          </div>
        </div>

        <Card className="card-default ">
          <CardBody>
            <div className={classes.FestInfoHeader}>
              <div className={classes.FestHeaderTextBold}>
                {requestTypeName}
              </div>

              {currentFestivalDates.length === 0 ? (
                <div>Даты проведения фестиваля неизвестны</div>
              ) : (
                <div>
                  <span>Фестиваль проводится </span>
                  {dayNameFrom && dayNameTo && monthNameTo ? (
                    <span className={classes.FestHeaderTextBold}>
                      {festTo
                        ? `${dayNameFrom} - ${dayNameTo} ${monthNameTo}`
                        : `${dayNameFrom} ${monthNameFrom}`}
                    </span>
                  ) : null}
                </div>
              )}
            </div>

            <div>
              <small>
                <div dangerouslySetInnerHTML={{ __html: hint }} />
              </small>
            </div>

            <div className={classes.DeadLine}>
              <span>Окончание приёма заявок — </span>{" "}
              {dayDeadLineName && monthDeadlineName && hourDeadLineName ? (
                <span className={classes.DeadLineDate}>
                  {dayDeadLineName} {monthDeadlineName} {hourDeadLineName}
                </span>
              ) : (
                <span className={classes.DeadLineDate}></span>
              )}
            </div>

            <div dangerouslySetInnerHTML={{ __html: festRules }} />

            <div className="dropdown-divider"></div>

            <div dangerouslySetInnerHTML={{ __html: typeRules }} />
          </CardBody>

          {!rulesShouldBeAccepted ? (
            <CardBody className={[classes.CheckRules, "bt"].join(" ")}>
              <label
                className={[
                  " c-checkbox m-0 float-right",
                  classes.CheckboxWrapper,
                ].join(" ")}
              >
                Я соглашаюсь на сбор, обработку и хранение персональных данных
                <Input
                  type="checkbox"
                  onChange={this.agreeRulesHandler}
                  bsSize="sm"
                />
                <span className="fa fa-check ml-2 mr-0"></span>
              </label>
            </CardBody>
          ) : null}

          <CardFooter className={classes.Footer}>
            {!rulesShouldBeAccepted ? (
              <div className={classes.SubmitWrapper}>
                <div onClick={backToRequestType} className={classes.TextButton}>
                  <strong>Назад к выбору типа заявки</strong>
                </div>

                <div className={classes.SubmitButtonWrapper}>
                  <button
                    type="submit"
                    disabled={!agree}
                    className={["btn", "btn-primary", classes.Button].join(" ")}
                    onClick={acceptRulesHandler}
                  >
                    Правила прочитаны, идём создавать заявку!
                  </button>
                </div>
              </div>
            ) : (
              <div className={classes.SubmitButtonWrapper}>
                <button
                  type="submit"
                  className={[
                    "btn",
                    "btn-primary float-right",
                    classes.Button,
                  ].join(" ")}
                  onClick={showRulesHandler}
                >
                  Вернуться
                </button>
              </div>
            )}
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default withRouter(rules);
