import {
  combineReducers, //
  createStore,
  applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import mainPage from "./mainPage/reducer";
import profile from "./profile/reducer";

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

// ToDo remove redux devtools in production

const rootReducer = combineReducers({
  mainPage,
  profile,
});

// eslint-disable-next-line import/prefer-default-export
export function configureStore() {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
}
