import Axios from "axios";

class Config {
  config = null;

  get(key) {
    return this.config[key];
  }

  async load() {
    try {
      const config = await Axios.get("/config.json");

      if (config && config.data) {
        this.config = config.data;
      } else {
        throw new Error("Cat't obtain config");
      }
    } catch (e) {
      throw e;
    }
  }
}

export default new Config();
