import {
  TOGGLE_SETTING, //
  CHANGE_SETTING,
  CHANGE_THEME,
  SET_LOADING,
  REMOVE_FEST_TILE,
  SET_FEST_TILE,
} from "./types";

export const toggleSettingAction = (name) => ({
  type: TOGGLE_SETTING,
  payload: {
    name,
  },
});

export const changeSettingAction = (name, value) => ({
  type: CHANGE_SETTING,
  payload: {
    name,
    value,
  },
});

export const changeThemeAction = (path) => ({
  type: CHANGE_THEME,
  payload: {
    path,
  },
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: {
    loading,
  },
});

export const setFestTile = (url, mainLogoPath, name) => ({
  type: SET_FEST_TILE,
  payload: {
    url, //
    mainLogoPath,
    name,
  },
});

export const removeFestTile = () => ({
  type: REMOVE_FEST_TILE,
});
