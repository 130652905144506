import React from "react";
import moment from "moment";
import { ListGroup } from "reactstrap";

import { getDuringTimePlural } from "../../../../utils/Request";

import avatar from "@components/Assets/ava3.jpg";

import classes from "./index.module.css";

const commentsPart = ({
  currentTime, //
  comments,
}) => (
  <>
    <ListGroup className="mt-2 bg-white p-3">
      {comments.map((item) => {
        const commentCreatedTime = moment(item.createdAt);

        const commentTimeDiff = currentTime.diff(commentCreatedTime);

        const diffDuration = moment.duration(commentTimeDiff);

        let commentDuringTime;

        if (diffDuration._data.years > 0) {
          const plural = getDuringTimePlural(diffDuration._data.years, "years");

          commentDuringTime = `${diffDuration._data.years} ${plural} назад`;
        } else if (diffDuration._data.months > 0) {
          const plural = getDuringTimePlural(
            diffDuration._data.months,
            "months"
          );

          commentDuringTime = `${diffDuration._data.months} ${plural} назад`;
        } else if (diffDuration._data.days > 0) {
          const plural = getDuringTimePlural(diffDuration._data.days, "days");

          commentDuringTime = `${diffDuration._data.days} ${plural} назад`;
        } else if (diffDuration._data.hours > 0) {
          const plural = getDuringTimePlural(diffDuration._data.hours, "hours");

          commentDuringTime = `${diffDuration._data.hours} ${plural} назад`;
        } else if (diffDuration._data.minutes > 0) {
          const plural = getDuringTimePlural(
            diffDuration._data.minutes,
            "minutes"
          );

          commentDuringTime = `${diffDuration._data.minutes} ${plural} назад`;
        }

        const getClassName = (item) => {
          let className = "bg-white";

          if (item.isCuratorOnly) {
            className = classes.CuratorBackground;
          } else if (item.isAnnounce) {
            className = classes.AnnounceBackground;
          }
          return className;
        };

        const getCommentIcon = (item) => {
          let className = "far fa-comments";

          if (item.isCuratorOnly) {
            className = "fas fa-user-tie";
          } else if (item.isAnnounce) {
            className = "fas fa-broadcast-tower";
          }
          return className;
        };

        const comment = (
          <div
            className={["bb p-1 pb-2 pt-2", getClassName(item)].join(" ")}
            key={item.commentId}
          >
            <div className={["row", classes.CommentRow].join(" ")}>
              <div className="col-1 d-flex justify-content-center align-items-center">
                <img
                  className="rounded-circle thumb48"
                  src={item.avatar || avatar}
                  alt="Avatar"
                />
              </div>

              <div className="col-11 bg-white pt-1">
                <span>
                  <em
                    className={["fa-1x mr-2", getCommentIcon(item)].join(" ")}
                  ></em>

                  <strong>{item.createdByName}</strong>

                  <span className={classes.CommentDuringTime}>
                    {commentDuringTime || "Только что"}
                  </span>

                  <br />

                  <div
                    className={[classes.CommentBody, "emoji-width-fix"].join(
                      " "
                    )}
                    dangerouslySetInnerHTML={{ __html: item.body }}
                  />
                </span>
              </div>
            </div>
          </div>
        );

        return comment;
      })}
    </ListGroup>
  </>
);

export default commentsPart;
