import authService from "@services/auth";

// const Menu = [
//     {
//         heading: 'Main Navigation',
//         translate: 'sidebar.heading.HEADER'
//     },
//     {
//         name: 'Single View',
//         path: 'singleview',
//         icon : 'icon-grid',
//         translate: 'sidebar.nav.SINGLEVIEW'
//     },
//     {
//         name: 'Menu',
//         icon: 'icon-speedometer',
//         translate: 'sidebar.nav.MENU',
//         label: { value: 1, color: 'info' },
//         sbmenu: [{
//             name: 'Submenu',
//             translate: 'sidebar.nav.SUBMENU',
//             path: 'submenu'
//         }]
//     }
// ];

export default (festUrl) => {
  let menu = [
    {
      heading: "Fest Navigation",
      translate: "sidebar.heading.FESTS_HEADER",
    },
    {
      name: "Выбор фестиваля",
      path: "/",
      icon: "fa-1x far fa-calendar-alt",
      translate: "sidebar.nav.FESTS_ACTIVE",
    },
    {
      name: "Архив фестивалей",
      path: "/archive",
      icon: "fa-1x far fa-calendar-check",
      translate: "sidebar.nav.FESTS_ARCHIVE",
    },
  ];

  const requestsSection = [
    {
      heading: "Request navigation",
      translate: "sidebar.heading.REQUESTS_HEADER",
    },
    {
      name: "Создать заявку",
      path: `/fest/${festUrl}/requests/select_type`,
      icon: "fa-1x far fa-lightbulb",
      translate: "sidebar.nav.REQUESTS_NEW",
    },
    {
      name: "Мои заявки",
      path: `/fest/${festUrl}/requests/my`,
      icon: "fa-1x far fa-list-alt",
      translate: "sidebar.nav.REQUESTS_MY",
    },
  ];

  const profileSection = [
    {
      heading: "Профиль",
      translate: "sidebar.heading.PROFILE_HEADER",
    },
    {
      name: "Данные для участия",
      path: `/profile/info`,
      icon: "fa-1x far fa-address-card",
      translate: "sidebar.nav.PROFILE_INFO",
    },
    {
      name: "Я и моя команда",
      path: `/profile/my-team`,
      icon: "fa-1x fa fa-users",
      translate: "sidebar.nav.PROFILE_MY_TEAM",
    },
  ];

  // const donateSection = [
  //   {
  //     heading: "Fest Navigation",
  //     translate: "sidebar.heading.HELP_FEST",
  //   },
  //   {
  //     name: "Донат",
  //     path:
  //       "https://vk.com/@tanibata-o-donatah-na-festivale-nyan-fest-2021-onlain",
  //     icon: "fa-1x fas fa-magic",
  //     translate: "sidebar.nav.DONATE",
  //   },
  // ];

  const helpSection = [
    {
      heading: "Помощь",
      translate: "sidebar.heading.HELP_HEADER",
    },
    {
      name: "Инструкция",
      path:
        "https://telegra.ph/Instrukciya-dlya-cabinetotakuru-08-06",
      icon: "fa-1x fas fa-question",
      translate: "sidebar.nav.HELP",
    },
    {
      name: "help@cabinet.otaku.ru",
      path:
        "mailto:help@cabinet.otaku.ru",
      icon: "fa-1x fas fa-envelope",
      translate: "sidebar.nav.EMAILUS",
    },
  ];

  if (authService.isLoggedIn()) {
    const userInfo = authService.getUserInfo();

    if (userInfo.isCurator) {
      requestsSection.splice(1, 0, {
        name: "Все заявки",
        path: `/fest/${festUrl}/requests/all`,
        icon: "fa-1x fab fa-slideshare",
        translate: "sidebar.nav.REQUESTS_ALL",
      });
    }
  }

  if (!!festUrl) {
    menu = [
      ...menu, //
      ...requestsSection,
      ...profileSection,
      // ...donateSection,
      ...helpSection,
    ];
  } else {
    menu = [
      ...menu, //
      ...profileSection,
      // ...donateSection,
      ...helpSection,
    ];
  }

  return menu;
};
