import React from "react";
import { Link } from "react-router-dom";
import {
  Card, //
  CardHeader,
  CardBody,
} from "reactstrap";

import classes from "./index.module.css";

const group = ({
  colGrid,
  festUrl, //
  type: {
    name, //
    subtitle,
    types,
  },
}) => (
  <div className={`col-lg-${colGrid} mb-3`}>
    <Card className={["card-default mb-0", classes.CardWrapper].join(" ")}>
      <CardHeader className={classes.CardHeader}>
        <div className={classes.Header}>{name}</div>

        <div className={classes.Subtitle}>{subtitle}</div>
      </CardHeader>

      <CardBody>
        {types.map((type) => (
          <Link
            to={`/fest/${festUrl}/${type.url}/new`}
            className={[classes.Link, type.active ? "" : classes.Expired].join(
              " "
            )}
            key={type.url}
          >
            {type.name}{" "}
            {type.active ? null : (
              <div className={classes.Description}>(приём окончен)</div>
            )}
          </Link>
        ))}
      </CardBody>
    </Card>
  </div>
);

export default group;
