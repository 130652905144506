import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  Card, //
  CardHeader,
  CardFooter,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import swal from "sweetalert";

import * as requestApi from "@api/request";
import reviewMnemocodes from "@constants/reviewMnemocodes";
import { setLoading } from "@store/mainPage/actions";
import authService from "@services/auth";

import THeadCell from "./theadcell";

import classes from "./index.module.css";
import underage from "./assets/chansey.png";

const sortRequests = (requests, field, order) => {
  const reviewMnemocodeOrder = [
    "checked", //
    "wait_for_check",
    "need_to_correct",
  ];

  let r;

  switch (field) {
    case "number":
      r = requests.sort((a, b) => b.number - a.number);
      break;
    case "type":
      r = requests.sort((a, b) => b.type.name.localeCompare(a.type.name));
      break;
    case "name":
      r = requests.sort((a, b) => b.name.localeCompare(a.name));
      break;
    case "state":
      r = requests.sort((a, b) => {
        const indexA = reviewMnemocodeOrder.indexOf(a.reviewMnemocode);
        const indexB = reviewMnemocodeOrder.indexOf(b.reviewMnemocode);

        if (indexA !== indexB) {
          return indexB - indexA;
        }

        return b.state.order - a.state.order;
      });
      break;
    case "createdAt":
      r = requests.sort(
        (a, b) => b.created.raw.valueOf() - a.created.raw.valueOf()
      );
      break;
    case "updatedAt":
      r = requests.sort(
        (a, b) => b.updated.raw.valueOf() - a.updated.raw.valueOf()
      );
      break;
    default:
      r = requests;
      break;
  }

  if (order === "desc") {
    return r.reverse();
  }

  return r;
};

const filterRequests = (requests, searchStr, type, state) => {
  const r = [];

  function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

  const searchStrEscape = escapeRegExp(searchStr);

  const rexp = new RegExp(searchStrEscape, "i");

  requests.forEach((request) => {
    const searchValues = [
      request.number.toString(),
      request.type.name,
      request.state.name,
      request.reviewMnemocode,
      request.created.user.name,
      request.updated.user.name,
      request.name,
    ];

    if (searchStrEscape[0] === "#") {
      const searchStrWithoutHash = searchStrEscape.slice(1);

      const value = searchValues[0] === searchStrWithoutHash;

      if (value) {
        r.push(request);
        return;
      }
    } else {
      let match = true;

      if (!searchValues.some((v) => v.match(rexp))) {
        match = false;
      }

      if (type !== null && request.type.url !== type.url) {
        match = false;
      }

      if (state !== null && request.state.name !== state.name) {
        match = false;
      }

      if (match) {
        r.push(request);
      }
    }
  });

  return r;
};

const getRowClassName = ({ reviewMnemocode }, index) => {
  const clsList = [classes.Row];

  if (index % 2 === 1) {
    clsList.push(classes.Odd);
  }

  switch (reviewMnemocode) {
    case "wait_for_check":
      clsList.push(classes.WaitForCheck);
      break;
    case "checked":
      clsList.push(classes.Checked);
      break;
    case "need_to_correct":
      clsList.push(classes.NeedToCorrect);
      break;
    default:
  }

  return clsList.join(" ");
};

class List extends Component {
  state = {
    requests: [],
    displayRequests: [],
    sorting: {
      field: "number",
      order: "desc",
    },
    typeFilterOpened: false,
    stateFilterOpened: false,
    allTypes: [],
    allStates: [],
    filtering: {
      searchStr: "",
      type: null,
      state: null,
    },
    accessGranted: true,
    error: false,
  };

  componentDidMount = async () => {
    await this.loadData();
  };

  componentDidUpdate = async (prevProps) => {
    const {
      match: {
        params: { type: currentType },
      },
    } = this.props;

    const {
      match: {
        params: { type: prevType },
      },
    } = prevProps;

    if (currentType !== prevType) {
      await this.loadData();
    }
  };

  loadData = async () => {
    try {
      const {
        match: {
          params: { festUrl, type },
        },
        setLoading,
      } = this.props;

      this.setState({
        error: false,
      });

      let isCurator = false;

      if (authService.isLoggedIn()) {
        const userInfo = authService.getUserInfo();

        isCurator = userInfo.isCurator;
      }

      if (type === "all" && !isCurator) {
        this.setState({
          accessGranted: false,
        });

        return;
      }

      setLoading(true);

      const rawRequests = await requestApi.getRequests(festUrl, type);

      const allTypes = rawRequests.reduce((accumulator, current) => {
        if (!accumulator.some((acc) => acc.url === current.type.url)) {
          accumulator = [...accumulator, current.type];
        }

        return accumulator;
      }, []);

      const allStates = rawRequests.reduce((accumulator, current) => {
        if (!accumulator.some((acc) => acc.name === current.state.name)) {
          accumulator = [...accumulator, current.state];
        }

        return accumulator;
      }, []);

      const {
        sorting: {
          field, //
          order,
        },
      } = this.state;

      const requests = sortRequests(rawRequests, field, order);

      this.setState({
        requests,
        displayRequests: [...requests],
        allTypes,
        allStates,
        accessGranted: true,
        filtering: {
          searchStr: "",
          type: null,
          state: null,
        },
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);

      this.setState({
        error: true,
      });

      swal("Ошибка", "Не удалось загрузить заявки", "error");
    }
  };

  setSorting = (sorting) => {
    const { requests } = this.state;

    const { field, order } = sorting;

    const sortedRequests = sortRequests(requests, field, order);

    this.setState(
      {
        sorting, //
        requests: [...sortedRequests],
      },
      () => this.applyFiltering()
    );
  };

  toggleTypeFilterOpened = () => {
    const { typeFilterOpened } = this.state;

    this.setState({
      typeFilterOpened: !typeFilterOpened,
    });
  };

  toggleStateFilterOpened = () => {
    const { stateFilterOpened } = this.state;

    this.setState({
      stateFilterOpened: !stateFilterOpened,
    });
  };

  setSearchStr = (searchStr) => {
    this.setState(
      {
        filtering: {
          ...this.state.filtering,
          searchStr,
        },
      },
      () => this.applyFiltering()
    );
  };

  setTypeFilter = (type) => {
    this.setState(
      {
        filtering: {
          ...this.state.filtering,
          type,
        },
      },
      () => this.applyFiltering()
    );
  };

  setStateFilter = (state) => {
    this.setState(
      {
        filtering: {
          ...this.state.filtering,
          state,
        },
      },
      () => this.applyFiltering()
    );
  };

  applyFiltering = () => {
    const {
      requests,
      filtering: {
        searchStr, //
        type,
        state,
      },
    } = this.state;

    const filteredRequests = filterRequests(requests, searchStr, type, state);

    this.setState({
      displayRequests: filteredRequests,
    });
  };

  render = () => {
    const {
      displayRequests, //
      sorting,
      typeFilterOpened,
      stateFilterOpened,
      allTypes,
      allStates,
      filtering,
      error,
      accessGranted,
    } = this.state;

    const {
      match: {
        params: { festUrl, type },
      },
    } = this.props;

    if (!accessGranted) {
      return (
        <div className="alert alert-danger m-2">
          <strong>Доступ запрещён!</strong>
        </div>
      );
    }

    if (error) {
      return (
        <div className="alert alert-danger m-2">
          <strong>Ошибка!</strong> Попробуйте обновить страницу, или повтороте
          попытку позже.
        </div>
      );
    }

    let title = "";
    switch (type) {
      case "all":
        title = "Все заявки";
        break;
      case "my":
        title = "Мои заявки";
        break;
      default:
    }

    return (
      <div className={["content-wrapper", classes.ContentWrapper].join(" ")}>
        <div className="content-heading">{title}</div>

        <Card className="card-default">
          <CardHeader className={classes.CardHeaderGray}>
            <div className={classes.TableFilters}>
              <div>
                <div className="input-group">
                  <Input
                    type="text" //
                    placeholder="Найти заявку..."
                    onChange={(e) => this.setSearchStr(e.target.value)}
                  />

                  <span className="input-group-append input-group-addon">
                    <span
                      className={["input-group-text", classes.BgaWhite].join(
                        " "
                      )}
                    >
                      <em className="fa fa-search fa-1x"></em>
                    </span>
                  </span>
                </div>
              </div>

              <div className="dropdown-menu-left">
                <ButtonDropdown
                  isOpen={typeFilterOpened}
                  toggle={this.toggleTypeFilterOpened}
                  style={{ width: "100%" }}
                  className={classes.FilterButton}
                >
                  <div className={classes.Caret} />

                  <DropdownToggle>
                    <span className={classes.FilterText}>
                      {filtering.type
                        ? filtering.type.name
                        : "Фильтр по типу заявки..."}
                    </span>
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem onClick={() => this.setTypeFilter(null)}>
                      Все
                    </DropdownItem>

                    {allTypes.map((type) => (
                      <div key={type.url}>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => this.setTypeFilter(type)}>
                          {type.name}
                        </DropdownItem>
                      </div>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>

              <div className="dropdown-menu-left">
                <ButtonDropdown
                  isOpen={stateFilterOpened}
                  toggle={this.toggleStateFilterOpened}
                  style={{ width: "100%" }}
                  className={classes.FilterButton}
                >
                  <div className={classes.Caret} />

                  <DropdownToggle>
                    <span className={classes.FilterText}>
                      {filtering.state
                        ? filtering.state.name
                        : "Фильтр по статусу заявки..."}
                    </span>
                  </DropdownToggle>

                  <DropdownMenu>
                    <div>
                      <DropdownItem onClick={() => this.setStateFilter(null)}>
                        Все
                      </DropdownItem>
                    </div>

                    {allStates.map((state) => (
                      <div key={state.name}>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => this.setStateFilter(state)}
                        >
                          {state.name}
                        </DropdownItem>
                      </div>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            </div>
          </CardHeader>
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <table
              className={[
                "table",
                "table-striped",
                "my-4",
                "w-100",
                "dataTable",
                "no-footer",
                "dtr-inline",
                classes.Table,
              ].join(" ")}
            >
              <thead>
                <tr>
                  <THeadCell
                    field="number"
                    sorting={sorting}
                    onClick={this.setSorting}
                  />

                  <THeadCell
                    field="type"
                    sorting={sorting}
                    onClick={this.setSorting}
                  />

                  <THeadCell
                    field="state"
                    sorting={sorting}
                    onClick={this.setSorting}
                  />

                  <THeadCell
                    field="name"
                    sorting={sorting}
                    onClick={this.setSorting}
                  />

                  <THeadCell
                    field="createdAt"
                    sorting={sorting}
                    onClick={this.setSorting}
                  />

                  <THeadCell
                    field="updatedAt"
                    sorting={sorting}
                    onClick={this.setSorting}
                  />
                </tr>
              </thead>

              <tbody>
                {displayRequests.map((request, index) => (
                  <tr
                    key={request.id}
                    className={getRowClassName(request, index)}
                  >
                    <td className={classes.Number}>{request.number}</td>

                    <td className={classes.Type}>{request.type.name}</td>

                    <td className={classes.State}>
                      <div className={classes.StateText}>
                        {request.state.name}
                      </div>

                      <div className={classes.Review}>
                        [{reviewMnemocodes[request.reviewMnemocode]}]
                      </div>
                    </td>

                    <td className={classes.Name}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div>
                            {request.currentUserAllowed ? (
                              <Link
                                to={`/fest/${festUrl}/${request.type.url}/${request.url}`}
                              >
                                {request.name}
                              </Link>
                            ) : (
                              <span className={classes.Inactive}>
                                {request.name}
                              </span>
                            )}
                          </div>

                          <div className={classes.TownNames}>
                            {request.squadName
                              ? `«${request.squadName}»: `
                              : ""}
                            {request.townNames
                              ? request.townNames.join(", ")
                              : ""}
                          </div>

                          <div className={classes.ExtraFields}>
                            {request.extraFields &&
                            request.extraFields.length > 0
                              ? request.extraFields.map((f, i) => (
                                  <div key={i}>{f}</div>
                                ))
                              : null}
                          </div>
                        </div>

                        {request.hasUnderage ? (
                          <div>
                            <img
                              src={underage}
                              alt="has unterage"
                              className={classes.UnderageIndicator}
                            />
                          </div>
                        ) : null}
                      </div>
                    </td>

                    <td>
                      <div className={classes.Date}>
                        {request.created.formatted}
                      </div>

                      <div>[{request.created.user.name}]</div>
                    </td>

                    <td>
                      <div className={classes.Date}>
                        {request.updated.formatted}
                      </div>

                      <div>[{request.updated.user.name}]</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <CardFooter className="text-right">
            <span className={[classes.Count]}>
              Заявок в списке: {displayRequests.length}
            </span>
          </CardFooter>
        </Card>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(List));
