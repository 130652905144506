import {
  SET_PROFILE_IMAGE, //
  SET_PROFILE_INFO,
} from "./types";

const initialState = {
  profileImage: null,
  profileInfo: null,
};

const setProfileImage = (state, action) => {
  const { file } = action.payload;

  return {
    ...state,
    profileImage: file,
  };
};

const setProfileInfo = (state, action) => {
  const { profileInfo } = action.payload;

  return {
    ...state,
    profileInfo,
  };
};

export default function profile(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE_IMAGE:
      return setProfileImage(state, action);
    case SET_PROFILE_INFO:
      return setProfileInfo(state, action);

    default:
      return state;
  }
}
