import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Card, CardBody, Input } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";

import { setLoading } from "@store/mainPage/actions";
import { changePassword } from "@api/profile";

import Footer from "../footer/index";

import classes from "./index.module.css";

class ChangePassword extends Component {
  state = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    submitted: false,
    passwordConfirmed: false,
  };

  componentDidMount = async () => {
    // To Do добавить загрузку
  };

  setOldPassword = (oldPassword) => {
    this.setState({ oldPassword });
  };

  setNewPassword = (newPassword) => {
    const { confirmNewPassword } = this.state;

    const passwordConfirmed = confirmNewPassword === newPassword;

    this.setState({ newPassword, passwordConfirmed });
  };

  confirmNewPassword = (value) => {
    const { newPassword } = this.state;

    const passwordConfirmed = newPassword === value;

    this.setState({ confirmNewPassword: value, passwordConfirmed });
  };

  notify = () =>
    toast("Пароль успешно изменён!", {
      type: "success",
      position: "top-right",
    });

  onSubmitClick = async () => {
    const {
      oldPassword,
      newPassword,

      passwordConfirmed,
    } = this.state;

    const { setLoading } = this.props;

    setLoading(true);

    this.setState({ submitted: true });

    if (passwordConfirmed) {
      try {
        await changePassword({ newPassword, oldPassword });

        this.notify();

        this.setState({ submitted: false });
      } catch (e) {
        swal(
          "Ошибка",
          "Не удалось отправить изменения. Проверьте обязательные поля и попробуйте еще раз.",
          "error"
        );
      }
    }

    setLoading(false);
  };

  onCancelClick = () => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      submitted: false,
      passwordConfirmed: false,
    });
  };

  render = () => {
    const {
      newPassword,
      oldPassword,
      passwordConfirmed,
      confirmNewPassword,
      submitted,
    } = this.state;

    return (
      <>
        <ToastContainer />

        <Card className={classes.Card}>
          <CardBody>
            <div className="row py-4 justify-content-center">
              <div className="col-12 col-sm-10">
                <form>
                  <div className="form-group mb-2 justify-content-end row">
                    <div className="col-4 pr-0 align-self-center">
                      <div className="text-bold  float-right">
                        <span className={classes.TextRed}>* </span>
                        <span>Старый пароль</span>
                      </div>
                    </div>

                    <div className="col-8">
                      <Input
                        type="password" //
                        className={classes.Input}
                        placeholder="Ваш старый пароль..."
                        value={oldPassword}
                        valid={submitted && !!oldPassword}
                        invalid={submitted && !oldPassword}
                        onChange={(e) => this.setOldPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group mb-2 justify-content-end row">
                    <div className="col-4 pr-0 align-self-center">
                      <div className="text-bold  float-right">
                        <span className={classes.TextRed}>* </span>
                        <span>Новый пароль</span>
                      </div>
                    </div>

                    <div className="col-8">
                      <Input
                        type="password" //
                        valid={submitted && !!newPassword}
                        invalid={submitted && !newPassword}
                        value={newPassword}
                        placeholder="Ваш новый пароль..."
                        onChange={(e) => this.setNewPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group mb-2  justify-content-end row">
                    <div className="col-4 pr-0 align-self-center">
                      <div className="text-bold float-right">
                        <span className={classes.TextRed}>* </span>
                        <span>Ещё раз новый пароль</span>
                      </div>
                    </div>

                    <div className=" col-8">
                      <Input
                        type="password" //
                        valid={
                          submitted &&
                          !!confirmNewPassword &&
                          !!passwordConfirmed
                        }
                        invalid={
                          (submitted && !passwordConfirmed) ||
                          (submitted && !confirmNewPassword)
                        }
                        value={confirmNewPassword}
                        placeholder="И еще разок ваш пароль..."
                        onChange={(e) =>
                          this.confirmNewPassword(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </CardBody>

          <Footer
            onSubmitClick={this.onSubmitClick}
            onCancelClick={this.onCancelClick}
          />
        </Card>
      </>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(ChangePassword));
