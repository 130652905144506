import {
  TOGGLE_SETTING, //
  CHANGE_SETTING,
  CHANGE_THEME,
  SET_LOADING,
  SET_FEST_TILE,
  REMOVE_FEST_TILE,
} from "./types";

let settings;

try {
  settings = JSON.parse(localStorage.getItem("settings"));
} catch (e) {
  settings = {};
}

const initialState = {
  /* Layout fixed. Scroll content only */
  isFixed: true,
  /* Sidebar collapsed */
  isCollapsed: false,
  /* Boxed layout */
  isBoxed: true,
  /* Floating sidebar */
  isFloat: false,
  /* Sidebar show menu on hover only */
  asideHover: false,
  /* Show sidebar scrollbar (dont' hide it) */
  asideScrollbar: false,
  /* Sidebar collapsed with big icons and text */
  isCollapsedText: false,
  /* Toggle for the offsidebar */
  offsidebarOpen: false,
  /* Toggle for the sidebar offcanvas (mobile) */
  asideToggled: false,
  /* Toggle for the sidebar user block */
  showUserBlock: false,
  /* Enables layout horizontal */
  horizontal: false,
  /* Full size layout */
  useFullLayout: false,
  /* Hide footer */
  hiddenFooter: false,
  path: "",
  loading: false,

  festTile: {
    url: "",
    mainLogoPath: "",
    name: "",
  },

  ...settings,
};

const saveSettings = (state) => {
  const { isCollapsed } = state;

  const settings = { isCollapsed };

  localStorage.setItem("settings", JSON.stringify(settings));
};

const toggleSetting = (state, action) => {
  const { name } = action.payload;

  return {
    ...state,
    [name]: !state[name],
  };
};

const changeSetting = (state, action) => {
  const { name, value } = action.payload;

  return {
    ...state,
    [name]: value,
  };
};

const changeTheme = (state, action) => {
  const { path } = action.payload;

  return {
    ...state,
    path: path,
  };
};

const setLoading = (state, action) => {
  const { loading } = action.payload;

  return {
    ...state,
    loading,
  };
};

const setFestTile = (state, action) => {
  const {
    url, //
    mainLogoPath,
    name,
  } = action.payload;

  return {
    ...state,
    festTile: {
      url,
      mainLogoPath,
      name,
    },
  };
};

const removeFestTile = (state, action) => {
  return {
    ...state,
    festTile: {
      url: "",
      mainLogoPath: "",
      name: "",
    },
  };
};

export default function mainPage(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SETTING: {
      const s = toggleSetting(state, action);
      saveSettings(s);

      return s;
    }

    case CHANGE_SETTING: {
      const s = toggleSetting(state, action);
      saveSettings(s);

      return changeSetting(state, action);
    }

    case CHANGE_THEME:
      return changeTheme(state, action);

    case SET_LOADING:
      return setLoading(state, action);

    case SET_FEST_TILE:
      return setFestTile(state, action);

    case REMOVE_FEST_TILE:
      return removeFestTile(state, action);

    default:
      return state;
  }
}
