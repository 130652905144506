// NOTES

// Left menu:

//            {/* START User avatar toggle */}
//            <li className="nav-item d-none d-md-block">
//              <a className="nav-link" onClick={this.toggleUserblock}>
//                <em className="icon-user"></em>
//              </a>
//            </li>
//            {/* END User avatar toggle */}
//            {/* START lock screen */}
//            <li className="nav-item d-none d-md-block">
//              <Link to="lock" title="Lock screen" className="nav-link">
//                <em className="icon-lock"></em>
//              </Link>
//            </li>
//            {/* END lock screen */}

// Right menu:

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Card,
} from "reactstrap";
import swal from "sweetalert";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

import { sendHelpMessage } from "@api/fest";

import Footer from "../Custom/footer/index";
import ModalDialog from "../Custom/modalDialog/index";
import ToggleFullscreen from "../Common/ToggleFullscreen";
import HeaderRun from "./Header.run";
import { toggleSettingAction } from "../../store/mainPage/actions";
import { getProfileInfoEffect } from "../../store/profile/effects";

import classes from "./Header.module.css";

import logo from "./logo.svg";
import logoSm from "./logo-sm.svg";
import avatar from "../Assets/ava3.jpg";

class Header extends Component {
  state = {
    showModalHelp: false,
    helpValue: "",
  };

  componentDidMount() {
    const { getProfileInfo } = this.props;

    getProfileInfo();

    HeaderRun();
  }

  notify = () =>
    toast("Ваше сообщение отправлено", {
      type: "success",
      position: "top-right",
    });

  toggleUserblock = (e) => {
    const { toggleSetting } = this.props;

    e.preventDefault();
    toggleSetting("showUserBlock");
  };

  toggleOffsidebar = (e) => {
    const { toggleSetting } = this.props;

    e.preventDefault();
    toggleSetting("offsidebarOpen");
  };

  toggleCollapsed = (e) => {
    const { toggleSetting } = this.props;

    e.preventDefault();
    toggleSetting("isCollapsed");

    this.resize();
  };

  toggleAside = (e) => {
    const { toggleSetting } = this.props;

    e.preventDefault();
    toggleSetting("asideToggled");
  };

  changeHelpValueHandler = ({ value }) => {
    this.setState({ helpValue: value });
  };

  showHelpHandler = () => {
    this.setState({ showModalHelp: true });
  };

  sendHelpHandler = async (isShouldSend) => {
    const { helpValue } = this.state;

    if (isShouldSend) {
      try {
        await sendHelpMessage(helpValue);

        this.setState({ helpValue: "", showModalHelp: false });

        this.notify();
      } catch (e) {
        swal(
          "Ошибка",
          "Не удалось отправить сообщение. Попробуйте позже еще раз.",
          "error"
        );
      }
    } else {
      this.setState({ helpValue: "", showModalHelp: false });
    }
  };

  resize() {
    // all IE friendly dispatchEvent
    var evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { profileImage, profileInfo } = this.props;
    const { showModalHelp, helpValue } = this.state;

    const nickname = profileInfo
      ? profileInfo.nickname
      : "Уважаемый пользователь";

    const image = profileImage ? profileImage.preview : avatar;

    return (
      <header className="topnavbar-wrapper">
        <ToastContainer />

        {showModalHelp ? (
          <ModalDialog>
            <div className={classes.ModalContent}>
              <div className="p-4">
                <div className={classes.HelpText}>
                  <p className="m-0">{nickname}, доброго времени суток!</p>

                  <p className="m-0">
                    Опишите подробно, что у вас случилось, и мы постараемся
                    оперативно помочь.
                  </p>

                  <p className={classes.HelpTextUnderline}>
                    Ответ придёт на электронную почту, указанную в вашем профиле
                    (либо укажите другую почту для связи).
                  </p>
                </div>

                <textarea
                  rows="6" //
                  className="form-control"
                  value={helpValue}
                  onChange={(e) => {
                    this.changeHelpValueHandler({ value: e.target.value });
                  }}
                ></textarea>
              </div>

              <Footer
                onSubmitClick={() => this.sendHelpHandler(true)}
                onCancelClick={() => this.sendHelpHandler(false)}
                disabled={!helpValue}
                onSubmitText="Отправить ^_^"
              />
            </div>
          </ModalDialog>
        ) : null}

        {/* START Top Navbar */}
        <nav
          className={["navbar topnavbar color-primary", classes.TopNavbar].join(
            " "
          )}
        >
          {/* START navbar header */}
          <div className="navbar-header">
            <a className="navbar-brand" href="#/">
              <div className="brand-logo">
                {/* <img className="img-fluid" src="img/logo.png" alt="App Logo" /> */}

                <img src={logo} alt="App logo" />
              </div>
              <div className="brand-logo-collapsed">
                {/* <img
                  className="img-fluid"
                  src="img/logo-single.png"
                  alt="App Logo"
                /> */}

                <img src={logoSm} alt="App logo" />
              </div>
            </a>
          </div>
          {/* END navbar header */}

          {/* START Left navbar */}
          <ul className="navbar-nav mr-auto flex-row">
            <li className="nav-item">
              {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <a
                href=""
                className={[
                  "nav-link d-none ml-2 d-md-block d-lg-block d-xl-block",
                  classes.ButtonBurger,
                ].join(" ")}
                onClick={this.toggleCollapsed}
              >
                <em className={["fas fa-2x fa-bars"].join(" ")}></em>
              </a>
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <a
                href=""
                className="nav-link sidebar-toggle d-md-none"
                onClick={this.toggleAside}
              >
                <em className="fas fa-bars"></em>
              </a>
            </li>
          </ul>
          {/* END Left navbar */}
          {/* START Right Navbar */}

          <ul className="navbar-nav flex-row">
            {/* Search icon */}
            {/* <li className="nav-item">
              <a className="nav-link" href="" data-search-open="">
                <em className="icon-magnifier"></em>
              </a>
            </li> */}
            {/* Fullscreen (only desktops) */}
            {/* <li className="nav-item d-none d-md-block">
              <ToggleFullscreen className="nav-link" />
            </li> */}
            {/* START Alert menu */}
            {/* <UncontrolledDropdown nav inNavbar className="dropdown-list">
              <DropdownToggle nav className="dropdown-toggle-nocaret">
                <em className="icon-bell"></em>
                <span className="badge badge-danger">11</span>
              </DropdownToggle>

              <DropdownMenu
                right
                className="dropdown-menu-right animated flipInX"
              >
                <DropdownItem>
                  <ListGroup>
                    <ListGroupItem
                      action
                      tag="a"
                      href=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="media">
                        <div className="align-self-start mr-2">
                          <em className="fab fa-twitter fa-2x text-info"></em>
                        </div>
                        <div className="media-body">
                          <p className="m-0">New followers</p>
                          <p className="m-0 text-muted text-sm">
                            1 new follower
                          </p>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem
                      action
                      tag="a"
                      href=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="media">
                        <div className="align-self-start mr-2">
                          <em className="fa fa-envelope fa-2x text-warning"></em>
                        </div>
                        <div className="media-body">
                          <p className="m-0">New e-mails</p>
                          <p className="m-0 text-muted text-sm">
                            You have 10 new emails
                          </p>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem
                      action
                      tag="a"
                      href=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="media">
                        <div className="align-self-start mr-2">
                          <em className="fa fa-tasks fa-2x text-success"></em>
                        </div>
                        <div className="media-body">
                          <p className="m-0">Pending Tasks</p>
                          <p className="m-0 text-muted text-sm">
                            11 pending task
                          </p>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem
                      action
                      tag="a"
                      href=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="d-flex align-items-center">
                        <span className="text-sm">More notifications</span>
                        <span className="badge badge-danger ml-auto">14</span>
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            {/* END Alert menu */}
            {/* START Offsidebar button */}

            <div
              className={[
                "media nav-link d-flex justify-content-center align-items-center ",
                classes.NavbarItems,
              ].join(" ")}
            >
              <a
                className={[
                  classes.TextHelpWrapper,
                  "d-flex justify-content-center align-items-center",
                ].join(" ")}
                // href="mailto:hyd0gnik@mail.ru"
                onClick={this.showHelpHandler}
              >
                <em className={["fa-2x mr-2 far fa-life-ring"].join(" ")} />

                <div className={classes.TextHelp}>Помощь</div>
              </a>

              <div className={classes.Divider} />

              <div
                className={["media nav-link mr-4 ", classes.ProfileIcon].join(
                  " "
                )}
                onClick={this.toggleOffsidebar}
              >
                <img
                  className="thumb32 rounded-circle"
                  src={image}
                  alt="user"
                />
              </div>
            </div>

            {/* END Offsidebar menu */}
          </ul>

          {/* END Right Navbar */}

          {/* START Search form */}
          <form className="navbar-form" role="search" action="search.html">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Type and hit enter ..."
              />
              <div
                className="fa fa-times navbar-form-close"
                data-search-dismiss=""
              ></div>
            </div>
            <button className="d-none" type="submit">
              Submit
            </button>
          </form>
          {/* END Search form */}
        </nav>
        {/* END Top Navbar */}
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  profileImage: state.profile.profileImage,
  profileInfo: state.profile.profileInfo,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSetting: (name) => {
    dispatch(toggleSettingAction(name));
  },
  getProfileInfo: () => {
    dispatch(getProfileInfoEffect());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
