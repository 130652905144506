import React from "react";
import MaskedInput from "react-maskedinput";
import { Input } from "reactstrap";

import classes from "./index.module.css";

const contactsPart = ({
  onChange, //
  phone,
  submitted,
  vk,
  instagram,
  twitter,
  setValidateSocial,
  vkSubmitted,
  twitterSubmitted,
  instagamSubmitted,
}) => {
  const vkSubStr = "https://vk.com/";
  const instSubStr = "https://www.instagram.com/";
  const twitterSubStr = "https://twitter.com/";

  const validateVk =
    vk.length > vkSubStr.length && vk.slice(0, vkSubStr.length) === vkSubStr;
  const validateTwitter =
    twitter.length > twitterSubStr.length &&
    twitter.slice(0, twitterSubStr.length) === twitterSubStr;
  const validateInstagram =
    instagram.length > instSubStr.length &&
    instagram.slice(0, instSubStr.length) === instSubStr;

  return (
    <>
      <fieldset className="pb-2 mb-2">
        <div className="form-group  justify-content-end row">
          <div className="col-4 align-self-center">
            <div className="text-bold  float-right">
              <span className={classes.TextRed}>* </span>
              <span>Телефон</span>
            </div>
          </div>

          <div className="col-8">
            <MaskedInput
              className={[
                "form-control",
                submitted && !!phone ? classes.Valid : "",
                submitted && !phone ? classes.Invalid : "",
              ].join(" ")}
              mask="1(111)111-11-11"
              name="expiry"
              value={phone}
              placeholder="8(800)555-35-35"
              onChange={(e) => onChange("phone", e.target.value)}
            />
          </div>
        </div>
      </fieldset>

      <fieldset className="pb-2 mb-2">
        <div className="form-group mb-2  justify-content-end row">
          <div className="col-4 align-self-center">
            <div className="text-bold  float-right">
              <span>VK</span>
            </div>
          </div>

          <div className="col-8">
            <Input
              type="text" //
              value={vk}
              className={[
                "form-control",
                vkSubmitted && validateVk ? classes.Valid : "",
                vkSubmitted && !validateVk ? classes.Invalid : "",
              ].join(" ")}
              placeholder="https://vk.com/userId"
              onChange={(e) => onChange("vk", e.target.value)}
              onBlur={() => setValidateSocial("vk")}
            />
          </div>
        </div>

        <div className="form-group mb-2  justify-content-end row">
          <div className="col-4 align-self-center">
            <div className="text-bold  float-right">
              <span>Instagram</span>
            </div>
          </div>

          <div className="col-8">
            <Input
              type="text" //
              placeholder="https://www.instagram.com/username"
              className={[
                "form-control",
                instagamSubmitted && validateInstagram ? classes.Valid : "",
                instagamSubmitted && !validateInstagram ? classes.Invalid : "",
              ].join(" ")}
              value={instagram}
              onChange={(e) => onChange("instagram", e.target.value)}
              onBlur={() => setValidateSocial("instagram")}
            />
          </div>
        </div>

        <div className="form-group mb-2  justify-content-end row">
          <div className="col-4 align-self-center">
            <div className="text-bold  float-right">
              <span>Twitter</span>
            </div>
          </div>

          <div className="col-8">
            <Input
              type="text" //
              value={twitter}
              className={[
                "form-control",
                twitterSubmitted && validateTwitter ? classes.Valid : "",
                twitterSubmitted && !validateTwitter ? classes.Invalid : "",
              ].join(" ")}
              placeholder="https://twitter.com/username"
              onChange={(e) => onChange("twitter", e.target.value)}
              onBlur={() => setValidateSocial("twitter")}
            />
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default contactsPart;
