import React from "react";

const fields = {
  number: "#",
  type: "Тип",
  state: "Статус",
  name: "Заявка",
  createdAt: "Создано",
  updatedAt: "Изменено",
};

const getSortingObject = (targetField, sortingField, sortingOrder) => {
  if (targetField !== sortingField) {
    return {
      field: targetField,
      order: "desc",
    };
  }

  if (sortingOrder === "desc") {
    return {
      field: sortingField,
      order: "asc",
    };
  }

  return {
    field: sortingField,
    order: "desc",
  };
};

const theadCell = ({
  field: targetField,
  sorting: {
    field: sortingField, //
    order: sortingOrder,
  },
  onClick,
}) => {
  const classes = ["sorting"];

  if (targetField === sortingField) {
    if (sortingOrder === "desc") {
      classes.push("sorting_desc");
    } else {
      classes.push("sorting_asc");
    }
  }

  return (
    <th
      className={classes.join(" ")}
      onClick={() => {
        const obj = getSortingObject(targetField, sortingField, sortingOrder);

        onClick(obj);
      }}
    >
      {fields[targetField]}
    </th>
  );
};

export default theadCell;
