import React from "react";

import LeaderItem from "./leaderItem/index";

import classes from "./index.module.css";

const myLeaders = ({
  leadersIsExpanded, //
  leaders,
  onClick,
  onDeleteClick,
  openContactInfo,
}) => {
  return (
    <>
      <div className={["box-placeholder", classes.BlockWrapper].join(" ")}>
        <fieldset className={classes.FieldSet}>
          <div
            className={classes.TextButton}
            onClick={() => onClick("leadersIsExpanded")}
          >
            Мои лидеры&nbsp;
            {leadersIsExpanded ? "-" : "+"}
          </div>
        </fieldset>

        {leadersIsExpanded
          ? leaders.map((item) => (
              <LeaderItem
                item={item}
                openContactInfo={(usrTeammateId) =>
                  openContactInfo(usrTeammateId)
                }
                onDeleteClick={() => onDeleteClick(item.usrTeammateId)}
              />
            ))
          : null}

        <fieldset className={classes.FieldSet}>
          <div className={classes.TextFooter}>
            вас могут включать в свои заявки: {leaders.length}
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default myLeaders;
