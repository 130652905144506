import React from "react";
import { Link } from "react-router-dom";

import config from "@generic/config";
import authService from "@services/auth";

import Dates from "../dates/dates";

import placeholderUrl from "./defaultPlaceholderImage.png";

import classes from "./index.module.css";

const addDefaultSrc = (e) => (e.target.src = placeholderUrl);

const item = ({
  fest: {
    name, //
    url,
    dates,
    mainLogoPath,
  },
}) => {
  const userInfo = authService.getUserInfo();

  const type = userInfo.isCurator ? "all" : "my";

  return (
    <tr>
      <td className={classes.ImageCell}>
        <img
          className={classes.Image} //
          src={`${config.get("filesUrl")}/${mainLogoPath}`}
          alt="img"
          onError={addDefaultSrc}
        />
      </td>

      <td
        className={[
          classes.Name, //
          classes.TextCell,
          "text-primary",
        ].join(" ")}
      >
        <Link to={`/fest/${url}/requests/${type}`} className={classes.Name}>
          {name}
        </Link>
      </td>

      <td className={classes.TextCell}>
        <Dates dates={dates} festActive={false} />
      </td>
    </tr>
  );
};

export default item;
