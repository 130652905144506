import React from "react";
import moment from "moment";

import { getUserFullText } from "../../../../../../utils/user";

import classes from "./index.module.css";

const crewItem = ({
  item, //
  festsById,
  onRejectClick,
  onAcceptClick,
  hideControls = false,
}) => {
  const InviterName = getUserFullText(
    {
      firstName: item.usr_leader.first_name,
      lastName: item.usr_leader.last_name,
      name: item.usr_leader.name,
      isHiddenPersonal: item.usr_leader.is_hidden_personal,
    },
    true
  );

  const InviteScene = `№${item.request.regNumber} - ${item.request.name} - ${
    'undefined' !== typeof festsById[item.request.festId] ? festsById[item.request.festId].name : '[ДАННЫЕ УДАЛЕНЫ]'
  }`;
  const inviteRole = item.request_participant.performer_info;
  const inviteSendTime = item.userInvite
    ? moment(item.userInvite.created_at).format("DD.MM.YYYY г. HH:mm")
    : "";

  return (
    <fieldset className={classes.FieldSet}>
      <div className="d-flex align-items-center">
        <div className="col-10 pl-0">
          <h5>
            <div className="text-bold">
              <span className={classes.TextLink}>{InviterName}</span>

              <span> приглашает в заявку: </span>
            </div>
          </h5>

          <h5>
            <div className="text-bold">• {InviteScene}</div>
          </h5>

          <div className="text-bold">Роль/Персонаж: {inviteRole}</div>

          {hideControls ? null : (
            <small>Приглашение отправлено: {inviteSendTime}</small>
          )}
        </div>

        {hideControls ? null : (
          <div className="col-2 p-0">
            <button
              className={["btn btn-danger float-left", classes.Button].join(
                " "
              )}
              type="button"
              onClick={onRejectClick}
            >
              <em className="fa-2x  far fa-thumbs-down" />
            </button>

            <button
              className={["btn btn-success float-right", classes.Button].join(
                " "
              )}
              type="button"
              onClick={onAcceptClick}
            >
              <em className="fa-2x far fa-thumbs-up" />
            </button>
          </div>
        )}
      </div>
    </fieldset>
  );
};

export default crewItem;
