import React, { Component } from "react";
import { Alert, TabContent, TabPane } from "reactstrap";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

import authService from "@services/auth";
import eventEmitter from "@services/eventEmitter";

import RequestFiles from "../requestFiles/index";
import Container from "../container/index";
import ActiveHistory from "../activeHistory/index";
import {
  TAB_ANKET,
  TAB_FILES,
  TAB_HISTORY,
} from "../../../../components/Constants/request";
import Rules from "../rules/index";

import classes from "./index.module.css";

class RequestWrapper extends Component {
  state = {
    activeTab: TAB_ANKET,
    rulesAccepred: false,
    showRules: false,
    requestData: {
      requestName: "",
      typeName: "",
      number: "",
    },
    subId: "",
    accessDenied: false,
  };

  componentDidMount = () => {
    const subId = eventEmitter.subscribe(
      "request_data_received",
      this.handleRequestDataReceived
    );

    this.setState({
      subId,
    });
  };

  componentWillUnmount = () => {
    const { subId } = this.state;

    if (subId) {
      eventEmitter.unsubscrube("request_data_received", subId);
    }
  };

  handleRequestDataReceived = (data) => {
    const { requestName, typeName, number } = data;

    this.setState({
      requestData: {
        requestName,
        typeName,
        number,
      },
    });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  notify = (text) =>
    toast(text, {
      type: "info",
      position: "top-right",
    });

  acceptRulesHandler = () => {
    this.setState({ rulesAccepred: true }, () =>
      setTimeout(
        () =>
          window.scrollTo({
            top: 0,
            behavior: "auto",
          }),
        0
      )
    );
  };

  showRulesHandler = () => {
    this.setState({ showRules: !this.state.showRules }, () =>
      setTimeout(
        () =>
          window.scrollTo({
            top: 0,
            behavior: "auto",
          }),
        0
      )
    );
  };

  backToRequestType = () => {
    const { history, festTile } = this.props;

    history.push(`/fest/${festTile.url}/requests/new`);
  };

  handleAccessDeniedChange = (accessDenied) => {
    this.setState({
      accessDenied,
    });
  };

  render = () => {
    const {
      activeTab,

      rulesAccepred,
      showRules,
      requestData: {
        requestName, //
        typeName,
        number,
      },
      accessDenied,
    } = this.state;

    if (accessDenied) {
      return (
        <div className="alert alert-danger m-2">
          <strong>Ошибка!</strong> Доступ запрещён!
        </div>
      );
    }

    const userInfo = authService.getUserInfo();

    const userBreadCrumbsType = userInfo.isCurator ? "all" : "my";

    const {
      match: {
        params: { requestUrl, festUrl },
      },
      festTile,
    } = this.props;

    const isShowRules =
      (requestUrl === "new" && (!rulesAccepred || showRules)) || showRules;

    const rulesShouldBeAccepted = requestUrl !== "new" || rulesAccepred;

    return (
      <div className={["content-wrapper", classes.ContentWrapper].join(" ")}>
        <ToastContainer />

        {isShowRules ? (
          <div className="content-heading">
            Правила подачи заявки {festTile ? `«${festTile.name}»` : null}
          </div>
        ) : (
          <div
            className={["content-heading", classes.contentHeading].join(" ")}
          >
            <div>
              <ul className="breadcrumb breadcrumb p-0 px-0 pb-0">
                <li className="breadcrumb-item">
                  <a href={`/fest/${festUrl}/requests/${userBreadCrumbsType}`}>
                    {`${userInfo.isCurator ? "Все" : "Мои"} заявки`}
                  </a>
                </li>

                <li
                  className={[
                    "breadcrumb-item active",
                    classes.BreadcrumbItem,
                  ].join(" ")}
                >
                  <div>{typeName}</div>
                </li>
              </ul>
            </div>

            <div>
              {requestName === ""
                ? "Новая заявка"
                : `#${number} ${requestName}`}
            </div>
          </div>
        )}

        {isShowRules ? (
          <Rules
            backToRequestType={this.backToRequestType}
            acceptRulesHandler={this.acceptRulesHandler}
            rulesShouldBeAccepted={rulesShouldBeAccepted}
            showRulesHandler={this.showRulesHandler}
          />
        ) : null}

        <div className={isShowRules ? classes.DisplayNone : ""}>
          <Alert color={["warning", classes.Alert].join(" ")}>
            <em className="fa-2x mr-5 fas fa-exclamation-triangle" />

            <strong>
              <span>Обязательно ознакомьтесь с </span>
              <span
                className={classes.AlertButtonText}
                onClick={this.showRulesHandler}
              >
                правилами подачи заявки
              </span>
              !
            </strong>
          </Alert>

          <div role="tabpanel" className="p-0">
            <div className={classes.TabWrapper}>
              <div
                className={[
                  activeTab === TAB_ANKET ? classes.ActiveTab : classes.Tab,
                ].join(" ")}
                onClick={() => {
                  this.toggleTab(TAB_ANKET);
                }}
              >
                <div className={classes.TabText}>Анкета</div>
              </div>

              <div
                className={[
                  activeTab === TAB_FILES ? classes.ActiveTab : classes.Tab,
                ].join(" ")}
                onClick={() => {
                  if (requestUrl === "new") {
                    this.notify(
                      "Загрузка файлов станет доступна после сохранения заявки."
                    );
                  } else {
                    this.toggleTab(TAB_FILES);
                  }
                }}
              >
                <div className={classes.TabText}>Файлы</div>
              </div>

              <div
                className={[
                  activeTab === TAB_HISTORY ? classes.ActiveTab : classes.Tab,
                ].join(" ")}
                onClick={() => {
                  if (requestUrl === "new") {
                    this.notify(
                      "Лента активности станет доступна после сохранения заявки."
                    );
                  } else {
                    this.toggleTab(TAB_HISTORY);
                  }
                }}
              >
                <div className={classes.TabText}>Активность</div>
              </div>
            </div>

            <TabContent className={classes.TabContent} activeTab={activeTab}>
              <TabPane tabId={TAB_ANKET}>
                <Container
                  onAccessDeniedChange={this.handleAccessDeniedChange}
                />
              </TabPane>

              <TabPane tabId={TAB_FILES}>
                <RequestFiles />
              </TabPane>

              <TabPane tabId={TAB_HISTORY}>
                <ActiveHistory activeTab={activeTab} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  festTile: state.mainPage.festTile,
});

export default connect(mapStateToProps, null)(withRouter(RequestWrapper));
