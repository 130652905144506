import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Card, CardBody } from "reactstrap";
import $ from "jquery";

// Image Cropper
import "cropper/dist/cropper.css";
import "cropper/dist/cropper.js";

import classes from "./index.module.css";

class FormCropper extends Component {
  componentDidMount() {
    // var self = this;

    this.inputImage = $(this.refs.inputImage); // upload button
    this.cropperElement = $(this.refs.cropperImage); // image for cropper

    this.options = {
      aspectRatio: 16 / 9,
      preview: ".img-preview",
      crop: function (data) {
        // console.log(self.cropperElement.cropper('getCroppedCanvas').toDataURL()); // base64
        console.log("Data X: " + Math.round(data.x));
        console.log("Data Y: " + Math.round(data.y));
        console.log("Data Height: " + Math.round(data.height));
        console.log("Data Width: " + Math.round(data.width));
        console.log("Data Rotate: " + Math.round(data.rotate));
      },
    };

    // init plugin
    this.cropperElement.cropper(this.options);

    // prepare to handle image upload
    this.handleNewImage();
  }

  handleNewImage(nextProps) {
    const { profileImage } = this.props;
    console.log(2);
    var self = this;
    var URL = window.URL || window.webkitURL,
      blobURL;

    if (URL) {
      console.log(profileImage, "aaaaaaaa", nextProps);
      this.inputImage.change(function () {
        if (!self.cropperElement.data("cropper")) {
          return;
        }

        const file = nextProps || profileImage;

        blobURL = URL.createObjectURL(file);
        self.cropperElement
          .one("built.cropper", function () {
            URL.revokeObjectURL(blobURL); // Revoke when load complete
          })
          .cropper("reset")
          .cropper("replace", blobURL);
        self.inputImage.val("");
      });
    } else {
      this.inputImage.parent().remove();
    }
  }

  componentWillUnmount() {
    this.cropperElement.cropper("destroy");
  }

  render() {
    // const { profileImage } = this.props;

    return (
      <Col lg="7">
        <Card className={["card-default", classes.Card].join(" ")}>
          <CardBody>
            <div
              className={["img-container mb-lg", classes.ImgContainer].join(
                " "
              )}
            >
              <img ref="cropperImage" src="img/mb-sample.jpg" alt="Item" />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  profileImage: state.profile.profileImage,
});

const mapDispatchToProps = (dispatch) => ({
  // setProfileImage: (file) => {
  //   dispatch(setProfileImageAction(file));
  // },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormCropper);
