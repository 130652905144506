import React from "react";

import { Input } from "reactstrap";

import classes from "./index.module.css";

const headerPart = ({
  submitted, //
  login,
  setLogin,
}) => (
  <fieldset className="pb-2 mb-2">
    <div className="form-group mb-2  justify-content-end row">
      <div className="col-2 pr-0 align-self-center">
        <div className="text-bold">
          <span className={classes.TextRed}>* </span>
          <span>Логин</span>
        </div>
      </div>

      <div className=" col-10">
        <Input
          type="text" //
          invalid={submitted && !login}
          placeholder="Ваш логин..."
          onChange={(e) => setLogin(e.target.value)}
        />
      </div>
    </div>
  </fieldset>
);

export default headerPart;
