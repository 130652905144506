import React from "react";

import { getUserFullText } from "../../../../utils/user";

import classes from "./styles.module.css";

const intersection = ({
  intersections, //
  noBorder,
  festUrl,
  onClick,
}) => {
  return (
    <div className={classes.Wrapper}>
      <div className={classes.Header}>
        {intersections[0].requestCheckRegNumber} -{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/fest/${festUrl}/${intersections[0].requestTypeSemanticUrl}/${intersections[0].requestCheckSemanticUrl}`}
        >
          <span className={classes.Name}>
            {intersections[0].requestCheckName}
          </span>{" "}
        </a>
        - {intersections[0].requestTypeName} /{" "}
        {intersections[0].requestStateName} [
        {intersections[0].requestCheckReviewMnemocode}]
      </div>

      <ul>
        {intersections.map((item) => (
          <li key={item.intersectionId}>
            {getUserFullText(item.user)}{" "}
            <span className={classes.ParticipantInfo}>
              {item.requestParticipantCheckPerformerInfo}
            </span>
          </li>
        ))}
      </ul>

      {!noBorder ? <div className={classes.Hr} /> : null}
    </div>
  );
};

export default intersection;
