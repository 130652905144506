import React from "react";
import Select from "react-select";

import classes from "./index.module.css";

const chooseCityPart = ({
  city, //
  cities,
  onChange,
  submitted,
  showCityPart,
}) => {
  return (
    <fieldset className="pb-2 mb-2">
      <div className="text-bold pb-1">
        <span className={classes.TextRed}>* </span>
        <span>Укажите свой город из списка</span>
      </div>

      <div>
        <Select
          placeholder="Выберите ваш город..."
          value={city}
          valid={submitted && !!city}
          invalid={submitted && !city}
          onChange={(e) => onChange("city", e)}
          options={cities}
        />
      </div>

      <div className="text-bold pt-2">
        <span>Или </span>

        <span className={classes.TextButton} onClick={showCityPart}>
          добавьте город, если в списке его нет +
        </span>
      </div>
    </fieldset>
  );
};

export default chooseCityPart;
