import React from "react";
import moment from "moment";
import MaskedInput from "react-maskedinput";
import { Input } from "reactstrap";

import classes from "./index.module.css";

const commonPart = ({
  nickname,
  lastName,
  firstName,
  middleName,
  bdate,
  onChange,
  submitted,
}) => {
  const bdatevalid = moment(bdate, "DD-MM-YYYY").format("YYYY-MM-DD");

  return (
    <>
      <fieldset className="pb-2 mb-2">
        <div className="form-group mb-2  justify-content-end row">
          <div className="col-4 align-self-center">
            <div className="text-bold  float-right">
              <span className={classes.TextRed}>* </span>
              <span>Псевдоним</span>
            </div>
          </div>

          <div className=" col-8">
            <Input
              type="text" //
              valid={submitted && !!nickname}
              invalid={submitted && !nickname}
              value={nickname}
              placeholder="Псевдоним..."
              onChange={(e) => onChange("nickname", e.target.value)}
            />
          </div>
        </div>

        <div className="form-group mb-2  justify-content-end row">
          <div className="col-4 align-self-center">
            <div className="text-bold  float-right">
              <span className={classes.TextRed}>* </span>
              <span>Фамилия</span>
            </div>
          </div>

          <div className="col-8">
            <Input
              type="text" //
              valid={submitted && !!lastName}
              invalid={submitted && !lastName}
              value={lastName}
              placeholder="Фамилия..."
              onChange={(e) => onChange("lastName", e.target.value)}
            />
          </div>
        </div>

        <div className="form-group mb-2  justify-content-end row">
          <div className="col-4 align-self-center">
            <div className="text-bold  float-right">
              <span className={classes.TextRed}>* </span>
              <span>Имя</span>
            </div>
          </div>

          <div className=" col-8">
            <Input
              type="text" //
              valid={submitted && !!firstName}
              invalid={submitted && !firstName}
              placeholder="Имя..."
              value={firstName}
              onChange={(e) => onChange("firstName", e.target.value)}
            />
          </div>
        </div>

        <div className="form-group mb-2  justify-content-end row">
          <div className="col-4 align-self-center">
            <div className="text-bold  float-right">
              <span className={classes.TextRed}>* </span>
              <span>Отчество</span>
            </div>
          </div>

          <div className="col-8">
            <Input
              type="text" //
              valid={submitted && !!middleName}
              invalid={submitted && !middleName}
              value={middleName}
              placeholder="Отчество..."
              onChange={(e) => onChange("middleName", e.target.value)}
            />
          </div>
        </div>

        <div className="form-group justify-content-end row">
          <div className="col-4 align-self-center">
            <div className="text-bold  float-right">
              <span className={classes.TextRed}>* </span>
              <span>День рождения</span>
            </div>
          </div>

          <div className="col-8">
            <MaskedInput
              className={[
                "form-control",
                submitted && !!bdate && bdatevalid !== "Invalid date"
                  ? classes.Valid
                  : "",
                (submitted && !bdate) || bdatevalid === "Invalid date"
                  ? classes.Invalid
                  : "",
              ].join(" ")}
              mask="11\.11\.1111"
              name="expiry"
              value={bdate}
              placeholder="дд-мм-гггг"
              onChange={(e) => onChange("bdate", e.target.value)}
            />
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default commonPart;
