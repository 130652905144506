import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import swal from "sweetalert";

import * as festsApi from "@api/fest";
import { setLoading } from "@store/mainPage/actions";

import TypeGroup from "../typegroup/index";

import classes from "./index.module.css";
import { CardBody } from "reactstrap";

class List extends Component {
  state = {
    fest: null,
    groups: [],
    error: false,
  };

  componentDidMount = async () => {
    const { setLoading } = this.props;

    try {
      const {
        match: {
          params: { festUrl },
        },
      } = this.props;

      setLoading(true);

      this.setState({
        fest: null,
        groups: [],
      });

      const [groupsData, fest] = await Promise.all([
        festsApi.getRequestTypeGroups(festUrl),
        festsApi.getFest(festUrl),
      ]);

      const dividedGroups = [[]];

      let index = 0;

      groupsData.forEach((item) => {
        dividedGroups[index].push(item);

        if (dividedGroups[index].length === 3) {
          index += 1;
          dividedGroups[index] = [];
        }
      });

      setLoading(false);

      this.setState({
        groups: dividedGroups,
        fest,
        error: false,
      });
    } catch (e) {
      setLoading(false);

      this.setState({
        error: true,
      });

      swal("Ошибка", "Не удалось загрузить фестиваль", "error");
    }
  };

  render = () => {
    const {
      fest, //
      groups,
      error,
    } = this.state;

    let groupLength = 0;

    groups.forEach((item) => (groupLength += item.length));

    const colGrid = groupLength === 2 || groupLength === 4 ? "6" : "4";

    const {
      match: {
        params: { festUrl },
      },
    } = this.props;

    if (error) {
      return (
        <div className="alert alert-danger m-2">
          <strong>Ошибка!</strong> Попробуйте обновить страницу, или повтороте
          попытку позже.
        </div>
      );
    }

    return (
      <div className={["content-wrapper", classes.ContentWrapper].join(" ")}>
        {fest ? (
          <div className="content-heading">Выбор типа заявки «{fest.name}»</div>
        ) : null}

        <div className={classes.Alertwrapper}>
          <div className={classes.Alert}>
            <em className="fa-2x fas fa-hand-pointer"></em>
          </div>

          <div>
            <CardBody className="text-left">
              <div>
                Для того, чтобы подать заявку, выберите тип заявки из списка
                ниже.
              </div>
            </CardBody>
          </div>
        </div>

        {groups.map((group, i) => (
          <div className="row" key={i}>
            {group.map((type) => (
              <TypeGroup
                colGrid={colGrid}
                key={type.url} //
                festUrl={festUrl}
                type={type}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(List));
