import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Card, //
  CardBody,
  Input,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

import { setLoading } from "@store/mainPage/actions";
import {
  getCountries,
  getCities,
  getProfileInfo,
  changeProfileInfo,
} from "@api/profile";
import { getProfileInfoEffect } from "../../../../store/profile/effects";

import CommonPart from "./commonPart/index";
import ChooseCityPart from "./chooseCityPart/index";
import NewCityPart from "./newCityPart/index";
import ContactsPart from "./contactsPart/index";
import Footer from "../footer/index";

import classes from "./index.module.css";

class Request extends Component {
  state = {
    submitted: false,
    nickname: "",
    lastName: "",
    firstName: "",
    middleName: "",
    bdate: "",
    isHiddenPersonal: false,
    phone: "",
    vk: "",
    instagram: "",
    twitter: "",

    isNewCityPartOpen: false,
    city: null,
    newCity: "",
    country: null,
    countries: [],
    cities: [],

    vkSubmitted: false,
    twitterSubmitted: false,
    instagamSubmitted: false,
  };

  componentDidMount = async () => {
    this.fetchAll();
  };

  setValidateSocial = (type) => {
    const { vk, instagram, twitter } = this.state;

    if (type === "vk") {
      this.setState({ vkSubmitted: vk });
    }
    if (type === "twitter") {
      this.setState({ twitterSubmitted: twitter });
    }
    if (type === "instagram") {
      this.setState({ instagamSubmitted: instagram });
    }
  };

  fetchAll = async () => {
    const { setLoading } = this.props;

    setLoading(true);

    const [
      countries, //
      cities,
      profileInfo,
    ] = await Promise.all([getCountries(), getCities(), getProfileInfo()]);

    const {
      nickname,
      lastName,
      firstName,
      middleName,
      city,
      vk,
      instagram,
      twitter,
      phone,
      bdate,
      isHiddenPersonal,
    } = profileInfo;

    this.setState({
      countries, //
      cities,
      nickname,
      lastName,
      firstName,
      middleName,
      city,
      vk,
      instagram,
      twitter,
      phone,
      bdate,
      isHiddenPersonal,
      vkSubmitted: false,
      twitterSubmitted: false,
      instagamSubmitted: false,
    });

    setLoading(false);
  };

  notify = () =>
    toast("Данные успешно изменены !", {
      type: "success",
      position: "top-right",
    });

  onSubmitClick = async () => {
    const {
      nickname,
      lastName,
      firstName,
      middleName,
      bdate,
      phone,
      vk,
      isNewCityPartOpen,
      city,
      newCity,
      country,
      instagram,
      twitter,
      isHiddenPersonal,
      vkSubmitted,
      twitterSubmitted,
      instagamSubmitted,
    } = this.state;

    const { setLoading, getProfileInfo } = this.props;

    setLoading(true);

    this.setState({ submitted: true });

    const cityConfirm =
      (!isNewCityPartOpen && city) ||
      (country && //
        newCity);

    const vkSubStr = "https://vk.com/";
    const instSubStr = "https://www.instagram.com/";
    const twitterSubStr = "https://twitter.com/";

    const validateVk = vkSubmitted
      ? vk.length > vkSubStr.length && vk.slice(0, vkSubStr.length) === vkSubStr
      : true;
    const validateTwitter = twitterSubmitted
      ? twitter.length > twitterSubStr.length &&
        twitter.slice(0, twitterSubStr.length) === twitterSubStr
      : true;
    const validateInstagram = instagamSubmitted
      ? instagram.length > instSubStr.length &&
        instagram.slice(0, instSubStr.length) === instSubStr
      : true;

    if (
      cityConfirm &&
      nickname &&
      lastName &&
      firstName &&
      middleName &&
      bdate &&
      phone &&
      validateVk &&
      validateTwitter &&
      validateInstagram
    ) {
      try {
        await changeProfileInfo({
          nickname,
          lastName,
          firstName,
          middleName,
          bdate,
          phone,
          vk,
          instagram,
          twitter,
          isNewCityPartOpen,
          city,
          newCity,
          country,
          isHiddenPersonal,
        });

        await getProfileInfo();

        this.notify();

        this.setState({ submitted: false });

        this.fetchAll();
      } catch (e) {
        swal(
          "Ошибка",
          "Не удалось отправить изменения. Проверьте обязательные поля и попробуйте еще раз.",
          "error"
        );
      }
    }

    setLoading(false);
  };

  onChange = (type, value) => {
    if (type === "isHiddenPersonal") {
      this.setState({ isHiddenPersonal: false });
      return;
    }

    this.setState({ [type]: value });
  };

  showCityPart = () => {
    this.setState({ isNewCityPartOpen: !this.state.isNewCityPartOpen });
  };

  onCancelClick = async () => {
    const { history } = this.props;

    history.push("/");
  };
  render = () => {
    const {
      nickname, //
      lastName,
      firstName,
      middleName,
      bdate,
      phone,
      vk,
      instagram,
      twitter,
      city,
      isNewCityPartOpen,
      newCity,
      country,
      countries,
      cities,
      submitted,
      isHiddenPersonal,
      vkSubmitted,
      twitterSubmitted,
      instagamSubmitted,
    } = this.state;

    return (
      <>
        <ToastContainer />

        <Card className={classes.Card}>
          <CardBody>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10">
                <form>
                  <CommonPart
                    nickname={nickname}
                    lastName={lastName}
                    firstName={firstName}
                    middleName={middleName}
                    bdate={bdate}
                    submitted={submitted}
                    onChange={(type, value) => this.onChange(type, value)}
                  />
                  {/*
                  <fieldset className="pb-2 mb-2">
                    <div className="checkbox c-checkbox">
                      <label className="mb-0">
                        <Input
                          type="checkbox"
                          bsSize="sm"
                          checked={isHiddenPersonal}
                          onChange={(e) => this.onChange("isHiddenPersonal", e)}
                        />
                        Отображать лидеру и кураторам только псевдоним
                        <span
                          className={["fa", "fa-check ml-2"].join(" ")}
                        ></span>
                      </label>
                    </div>
                  </fieldset>
                  */}
                  <ChooseCityPart
                    city={city}
                    cities={cities}
                    submitted={submitted}
                    showCityPart={this.showCityPart}
                    onChange={(type, value) => this.onChange(type, value)}
                  />

                  <NewCityPart
                    newCity={newCity}
                    country={country}
                    countries={countries}
                    submitted={submitted}
                    isNewCityPartOpen={isNewCityPartOpen}
                    onChange={(type, value) => this.onChange(type, value)}
                  />

                  <ContactsPart
                    onChange={(type, value) => this.onChange(type, value)}
                    phone={phone}
                    vk={vk}
                    instagram={instagram}
                    twitter={twitter}
                    submitted={submitted}
                    vkSubmitted={vkSubmitted}
                    twitterSubmitted={twitterSubmitted}
                    instagamSubmitted={instagamSubmitted}
                    setValidateSocial={this.setValidateSocial}
                  />
                </form>
              </div>
            </div>
          </CardBody>

          <Footer
            onSubmitClick={this.onSubmitClick}
            onCancelClick={this.onCancelClick}
          />
        </Card>
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  profileInfo: state.profile.profileInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
  getProfileInfo: () => {
    dispatch(getProfileInfoEffect());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Request));
