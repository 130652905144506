import moment from "moment";

import { getInstance } from "@generic/axios";

const sortFestDates = (dates) =>
  dates.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());

export const getFests = async (isArchive) => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/fests`);

    if (!response || !response.data || !response.data.fests) {
      throw new Error("Empty response");
    }

    return response.data.fests
      .filter((f) => f.is_archive === isArchive)
      .map((item) => ({
        id: item.id,
        name: item.name,
        url: item.semantic_url,
        townId: item.town_id,
        description: item.description,
        dates: sortFestDates(item.dates),
        mainLogoPath: item.main_logo_path,
      }))
      .sort((a, b) => {
        if (a.dates.length === 0) {
          return 1;
        }

        if (b.dates.length === 0) {
          return -1;
        }

        return moment(b.dates[0]).valueOf() - moment(a.dates[0]).valueOf();
      });
  } catch (e) {
    throw e;
  }
};

export const getFestsForDate = async () => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/fests`);

    if (!response || !response.data || !response.data.fests) {
      throw new Error("Empty response");
    }

    return response.data.fests
      .map((item) => ({
        id: item.id,
        name: item.name,
        url: item.semantic_url,
        townId: item.town_id,
        description: item.description,
        dates: sortFestDates(item.dates),
        mainLogoPath: item.main_logo_path,
      }))
      .sort((a, b) => {
        if (a.dates.length === 0) {
          return 1;
        }

        if (b.dates.length === 0) {
          return -1;
        }

        return moment(b.dates[0]).valueOf() - moment(a.dates[0]).valueOf();
      });
  } catch (e) {
    throw e;
  }
};

export const getFest = async (festUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/fests/${festUrl}`);

    if (!response || !response.data) {
      throw new Error("empty response");
    }

    if (!response.data.fest) {
      throw new Error("Empty data");
    }

    const {
      name, //
      semantic_url: url,
      main_logo_path: mainLogoPath,
      fest_request_rules: festRules,
    } = response.data.fest;

    return {
      name,
      url,
      mainLogoPath,
      festRules,
    };
  } catch (e) {
    throw e;
  }
};

export const getRequestTypeGroups = async (festUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/fests/${festUrl}/request-types`);

    if (!response || !response.data) {
      throw new Error("empty response");
    }

    if (!response.data.types || !response.data.groups) {
      throw new Error("Empty data");
    }

    const groupedTypes = new Map();

    response.data.types.forEach((item) => {
      const type = {
        id: item.id,
        name: item.name,
        active: item.is_active,
        url: item.semantic_url,
        hint: item.request_type_group_subtitle,
        orderType: item.order_on_request_type_group,
        from: moment(item.fest_request_date_from),
        to: moment(item.fest_request_date_to),
        typeRules: item.fest_request_rules_by_type,
      };

      const types = groupedTypes.get(item.group_id) || [];

      groupedTypes.set(item.group_id, [...types, type]);
    });

    const groups = response.data.groups.map((item) => {
      const id = item.id;

      const types = groupedTypes.get(id);

      types.sort((a, b) => a.orderType - b.orderType);

      return {
        url: item.semantic_url,
        name: item.name,
        subtitle: item.subtitle,
        orderOnFest: item.order_on_fest,
        types,
      };
    });

    return groups.sort((a, b) => a.orderOnFest - b.orderOnFest);
  } catch (e) {
    throw e;
  }
};

export const getRequestStates = async (festUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/fests/${festUrl}/request-states`);

    if (!response || !response.data || !response.data.request_states) {
      throw new Error("empty response");
    }

    return response.data.request_states
      .sort((a, b) => a.order_on_fest - b.order_on_fest)
      .map((item) => ({
        id: item.id,
        guid: item.guid,
        name: item.name,
      }));
  } catch (e) {
    throw e;
  }
};

export const getRequestTypes = async (festUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/fests/${festUrl}/request-types`);

    if (!response || !response.data || !response.data.types) {
      throw new Error("empty response");
    }

    return response.data.types.map((item) => ({
      id: item.id,
      name: item.name,
      active: item.is_active,
      url: item.semantic_url,
      hint: item.hint_on_name,
    }));
  } catch (e) {
    throw e;
  }
};

export const sendHelpMessage = async (body) => {
  try {
    const axios = getInstance();

    await axios.post("api/v1/support/ticket", {
      body,
    });
  } catch (e) {
    throw e;
  }
};
