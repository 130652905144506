import {
  SET_PROFILE_IMAGE, //
  SET_PROFILE_INFO,
} from "./types";

export const setProfileImageAction = (file) => ({
  type: SET_PROFILE_IMAGE,
  payload: {
    file,
  },
});

export const setProfileInfoAction = ({ profileInfo }) => ({
  type: SET_PROFILE_INFO,
  payload: {
    profileInfo,
  },
});
