import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Card } from "reactstrap";

import { setLoading } from "@store/mainPage/actions";

import classes from "./index.module.css";

class Request extends Component {
  state = {
    error: false,
    submitted: false,
    login: "",
  };

  componentDidMount = async () => {
    // To Do добавить загрузку

    console.log("working");
  };

  onSubmitClick = async () => {
    const { history } = this.props;

    this.setState({ submitted: true });

    history.push("/");
  };

  onCancelClick = async () => {
    const { history } = this.props;

    history.push("/");
  };

  setLogin = (login) => {
    this.setState({ login });
  };

  render = () => {
    const {
      error, //
    } = this.state;

    if (error) {
      return (
        <div className="alert alert-danger m-2">
          <strong>Ошибка!</strong> Попробуйте обновить страницу, или повтороте
          попытку позже.
        </div>
      );
    }

    return (
      <>
        <Card className={classes.Card}>PLESE HILP ME</Card>
      </>
    );
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Request));
