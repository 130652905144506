import moment from "moment";

export const getDuringTimePlural = (number, type) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    switch (type) {
      case "years":
        return "лет";

      case "months":
        return "месяцев";

      case "days":
        return "дней";

      case "hours":
        return "часов";

      case "minutes":
        return "минут";

      default:
        break;
    }
  }
  n %= 10;
  if (n === 1) {
    switch (type) {
      case "years":
        return "год";

      case "months":
        return "месяц";

      case "days":
        return "день";

      case "hours":
        return "час";

      case "minutes":
        return "минуту";

      default:
        break;
    }
  }
  if (n >= 2 && n <= 4) {
    switch (type) {
      case "years":
        return "года";

      case "months":
        return "месяца";

      case "days":
        return "дня";

      case "hours":
        return "часа";

      case "minutes":
        return "минуты";

      default:
        break;
    }
  }

  switch (type) {
    case "years":
      return "лет";

    case "months":
      return "месяцев";

    case "days":
      return "дней";

    case "hours":
      return "часов";

    case "minutes":
      return "минут";

    default:
      break;
  }
};

export const getDuringTime = ({ createdTime }) => {
  const currentTime = moment();

  const commentCreatedTime = moment(createdTime);

  const commentTimeDiff = currentTime.diff(commentCreatedTime);

  const diffDuration = moment.duration(commentTimeDiff);

  let commentDuringTime;

  if (diffDuration._data.years > 0) {
    const plural = getDuringTimePlural(diffDuration._data.years, "years");

    commentDuringTime = `${diffDuration._data.years} ${plural} назад`;
  } else if (diffDuration._data.months > 0) {
    const plural = getDuringTimePlural(diffDuration._data.months, "months");

    commentDuringTime = `${diffDuration._data.months} ${plural} назад`;
  } else if (diffDuration._data.days > 0) {
    const plural = getDuringTimePlural(diffDuration._data.days, "days");

    commentDuringTime = `${diffDuration._data.days} ${plural} назад`;
  } else if (diffDuration._data.hours > 0) {
    const plural = getDuringTimePlural(diffDuration._data.hours, "hours");

    commentDuringTime = `${diffDuration._data.hours} ${plural} назад`;
  } else if (diffDuration._data.minutes > 0) {
    const plural = getDuringTimePlural(diffDuration._data.minutes, "minutes");

    commentDuringTime = `${diffDuration._data.minutes} ${plural} назад`;
  }
  return commentDuringTime;
};

export const getFileTypeIcon = (item) => {
  let className;

  switch (item) {
    case 1:
      className = "fa-file-audio text-primary";
      break;
    case 2:
      className = "fa-file-video text-danger";
      break;
    case 3:
      className = "fa-file-image text-success";
      break;
    case 4:
      className = "fa-file-archive text-warning";
      break;
    case 5:
      className = "fa-file-code text-purple";
      break;

    default:
      className = "fa-file-audio text-primary";
  }

  return className;
};

export const getCommentIcon = (item) => {
  let className = "far fa-comments";

  if (item.isCuratorOnly) {
    className = "fas fa-user-tie";
  } else if (item.isAnnounce) {
    className = "fas fa-broadcast-tower";
  }
  return className;
};

/*eslint-disable*/
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/*eslint-enabled*/
