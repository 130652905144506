import React from "react";

import CrewItem from "./crewItem/index";

import classes from "./index.module.css";

const myCrew = ({
  crews: invites, //
  crewsIsExpanded,
  onClick,
  festsById,
  onRejectClick,
  onAcceptClick,
}) => {
  return (
    <>
      <div className={["box-placeholder", classes.BlockWrapper].join(" ")}>
        <fieldset className={classes.FieldSet}>
          <div
            className={classes.TextButton}
            onClick={() => onClick("crewsIsExpanded")}
          >
            Новые приглашения &nbsp;{crewsIsExpanded ? "-" : "+"}
          </div>
        </fieldset>

        {crewsIsExpanded
          ? invites.map((item) => (
              <CrewItem
                item={item}
                festsById={festsById}
                onRejectClick={() => onRejectClick(item.userInvite.guid)}
                onAcceptClick={() => onAcceptClick(item.userInvite.guid)}
              />
            ))
          : null}

        <fieldset className={classes.FieldSet}>
          <div className={classes.TextFooter}>
            Приглашений в списке: {invites.length}
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default myCrew;
