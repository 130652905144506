import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toggleSettingAction } from "../../store/mainPage/actions";

import Base from "@components/Layout/Base";

const layout = ({
  children, //
  isCollapsed,
  offsidebarOpen,
  toggleSetting,
  asideToggled,
}) => {
  const classes = ["layout-boxed"];

  if (isCollapsed) {
    classes.push("aside-collapsed");
  }

  if (offsidebarOpen) {
    classes.push("offsidebar-open");
  }

  if (asideToggled) {
    classes.push("aside-toggled");
  }

  return (
    <div className={classes.join(" ")} style={{ height: "100%" }}>
      <Base onClick={() => toggleSetting(offsidebarOpen)}>{children}</Base>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isCollapsed: state.mainPage.isCollapsed,
  offsidebarOpen: state.mainPage.offsidebarOpen,
  asideToggled: state.mainPage.asideToggled,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSetting: (offsidebarOpen) => {
    if (offsidebarOpen) {
      dispatch(toggleSettingAction("offsidebarOpen"));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(layout));
