import moment from "moment";

import { getInstance } from "@generic/axios";

import { uuidv4 } from "../utils/Request";

export const getRequests = async (festUrl, type) => {
  try {
    const axios = getInstance();

    const response = await axios.get(
      `/api/v1/fests/${festUrl}/requests/${type}`
    );

    if (!response || !response.data || !response.data.requests) {
      throw new Error("Empty response");
    }

    return response.data.requests.map((item) => ({
      id: item.id,
      name: item.name,
      number: item.reg_number,
      type: {
        name: item.request_type.name,
        url: item.request_type.semantic_url,
      },
      state: {
        name: item.request_state.name,
        order: item.request_state.order_on_fest,
      },
      reviewMnemocode: item.review_mnemocode,
      created: {
        user: {
          name: item.created_by.name,
          url: item.created_by.semantic_url,
        },
        raw: moment(item.created_at),
        formatted: moment(item.created_at).format("DD.MM.YYYY"),
      },
      updated: {
        user: {
          name: item.updated_by.name,
          url: item.updated_by.semantic_url,
        },
        raw: moment(item.updated_at),
        formatted: moment(item.updated_at).format("DD.MM.YYYY"),
      },
      url: item.semantic_url,
      currentUserAllowed: item.current_user_allowed,
      townNames: item.town_names,
      hasUnderage: item.has_underage,
      squadName: item.squad_name,
      extraFields: item.extra_fields,
    }));
  } catch (e) {
    throw e;
  }
};

export const getRequestFields = async (festUrl, requestUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(
      `/api/v1/fests/${festUrl}/request-types/${requestUrl}/request-fields`
    );

    if (!response || !response.data || !response.data.request_fields) {
      throw new Error("Empty response");
    }

    return response.data.request_fields
      .map((item) => ({
        hint: item.hint,
        id: item.id,
        isCuratorOnly: item.is_curator_only,
        name: item.name,
        order: item.order,
        required: item.required,
        url: item.semantic_url,
        type: item.type,
        typeParams: item.type_params,
        isUpperBlock: item.is_upper_block,
      }))
      .sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));
  } catch (e) {
    throw e;
  }
};

const formatRequestBody = (item) => ({
  id: item.id,
  fieldId: item.request_field_id,
  value: item.value,
  reviewMnemocode: item.reviewMnemocode,
  systemFieldName: item.system_field_name,
});

const formatComment = (item) => ({
  commentId: item.id,
  createdById: item.created_by.id,
  createdByName: item.created_by.name,
  createdAt: item.created_at,
  body: item.body,
  isAnnounce: item.is_announce,
  isCuratorOnly: item.is_curator_only,
});

const formatRequestParticipant = (participant) => {
  return {
    id: participant.id,
    user: participant.user.id
      ? {
          id: participant.user.id,
          name: participant.user.name,
          firstName: participant.user.first_name,
          lastName: participant.user.last_name,
          email: participant.user.email,
          phone: participant.user.phone,
          birthDate: participant.user.birth_date,
          vk: participant.user.social_list_json["vk.com"],
          twitter: participant.user.social_list_json["twitter.com"],
          instagram: participant.user.social_list_json["instagram.com"],
          town: participant.user
            ? {
                name: participant.user.town.name,
              }
            : {},
        }
      : {},
    performerInfo: participant.performer_info,
    invites: participant.invites,
    inviteLink: participant.invite_link,
  };
};

export const getRequest = async (requestUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/requests/${requestUrl}`);

    if (!response || !response.data || !response.data.request) {
      throw new Error("Empty response");
    }

    return {
      id: response.data.request.id,
      name: response.data.request.name,
      url: response.data.request.semantic_url,
      number: response.data.request.reg_number,
      createdAt: response.data.request.created_at,
      updatedAt: response.data.request.updated_at,
      createdBy: response.data.request.created_by.name,
      updatedBy: response.data.request.updated_by.name,
      reviewMnemocode: response.data.request.review_mnemocode,
      requestBody: response.data.request.request_body.map(formatRequestBody),
      requestParticipant: response.data.request.request_participant
        .sort((a, b) => a.order_on_request - b.order_on_request)
        .map(formatRequestParticipant),
    };
  } catch (e) {
    if (e.response && e.response.status === 403) {
      throw new Error("ACCESS_DENIED");
    } else {
      throw e;
    }
  }
};

export const getRequestSystemFields = async (requestUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(
      `/api/v1/requests/${requestUrl}/request_body/system`
    );

    if (!response || !response.data || !response.data.request_body) {
      throw new Error("Empty response");
    }

    return response.data.request_body.map(formatRequestBody);
  } catch (e) {
    throw e;
  }
};

export const getRequestComments = async (requestUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/requests/${requestUrl}/comments`);

    if (!response || !response.data || !response.data.comments) {
      throw new Error("Empty response");
    }

    return response.data.comments
      .map(formatComment)
      .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
  } catch (e) {
    throw e;
  }
};

export const postRequestComments = async ({
  requestUrl,
  bodyHtml,
  commentReaderType,
}) => {
  try {
    const axios = getInstance();

    const request = {
      bodyHtml,
      isCuratorOnly: false,
    };

    if (commentReaderType === "is_curator_only") {
      request.isCuratorOnly = true;
    }
    await axios.post(`/api/v1/requests/${requestUrl}/comments`, request);
  } catch (e) {
    throw e;
  }
};

const getCheckReviewMnemocode = (value) => {
  if (value === "checked") {
    return "проверено";
  } else if (value === "wait_for_check") {
    return "Не проверено";
  } else if (value === "need_to_correct") {
    return "Исправить!";
  }
};

const formatParticipantIntersection = (intersection) => ({
  requestCheckId: intersection.request_check_id,
  requestCheckName: intersection.request_check_name,
  requestCheckSemanticUrl: intersection.request_check_semantic_url,
  requestCheckRegNumber: intersection.request_check_reg_number,
  requestCheckReviewMnemocode: getCheckReviewMnemocode(
    intersection.request_check_review_mnemocode
  ),
  requestTypeId: intersection.request_type_id,
  requestTypeName: intersection.request_type_name,
  requestStateId: intersection.request_state_id,
  requestStateName: intersection.request_state_name,
  requestTypeSemanticUrl: intersection.request_type_semantic_url,
  intersectionId: uuidv4(),
  user: {
    id: intersection.usr_acc_member_id,
    name: intersection.usr_acc_member_name,
    semanticUrl: intersection.usr_acc_member_semantic_url,
    lastName: intersection.usr_acc_member_last_name,
    firstName: intersection.usr_acc_member_first_name,
    middleName: intersection.usr_acc_member_middle_name,
    isHiddenPersonal: intersection.usr_acc_member_is_hidden_personal,
  },
  requestParticipantCheckPerformerInfo:
    intersection.request_participant_check_performer_info,
});

export const getRequestParticipantsIntersections = async (
  requestUrl,
  userIds
) => {
  try {
    const axios = getInstance();

    const response = await axios.post(
      `/api/v1/requests/${requestUrl}/operation/getParticipantsIntersection`,
      {
        user_ids: userIds,
      }
    );

    if (!response || !response.data || !response.data.intersections) {
      throw new Error("Empty response");
    }

    return response.data.intersections.map(formatParticipantIntersection);
  } catch (e) {
    throw e;
  }
};

const formatRequestBodyForPost = (body) => ({
  id: body.id,
  request_field_id: body.requestFieldId,
  review_mnemocode: body.reviewMnemocode,
  value: body.value,
});

const formatParticipantForPost = (participant, index) => ({
  order_on_request: index + 1,
  performer_info: participant.performerInfo,
  usr_acc_member_id: participant.userId,
  invite_link: participant.inviteLink,
  invite_social: participant.inviteSocial,
});

export const createRequest = async (festUrl, requestBody, participants) => {
  try {
    const axios = getInstance();

    const response = await axios.post(`/api/v1/requests`, {
      fest_url: festUrl,
      request_body: requestBody.map(formatRequestBodyForPost),
      request_participant: participants.map(formatParticipantForPost),
    });

    if (!response || !response.data || !response.data.semantic_url) {
      throw new Error("empty response");
    }

    return response.data.semantic_url;
  } catch (e) {
    throw e;
  }
};

const formatRequestBodyForPut = (body) => ({
  id: body.id,
  request_field_id: body.requestFieldId,
  review_mnemocode: body.reviewMnemocode,
  value: body.value,
  original_value: body.originalValue,
  value_changed: body.valueChanged,
  review_mnemocode_changed: body.reviewMnemocodeChanged,
});

const formatParticipantForPut = (participant, index) => {
  const r = {
    id: participant.id || undefined,
    order_on_request: index + 1,
    performer_info: participant.performerInfo,
    usr_acc_member_id: participant.userId,
  };

  if (!participant.id) {
    r.invite_link = participant.inviteLink;
    r.invite_social = participant.inviteSocial;
  }

  return r;
};

export const updateRequest = async (
  festUrl,
  requestBody,
  participants,
  requestUrl,
  forceRaceDecision
) => {
  try {
    const axios = getInstance();

    let d;
    switch (forceRaceDecision) {
      case true:
        d = "YES";
        break;
      case false:
        d = "NO";
        break;
      default:
        d = "";
    }

    await axios.put(`/api/v1/requests/${requestUrl}`, {
      fest_url: festUrl,
      request_body: requestBody.map(formatRequestBodyForPut),
      request_participant: participants.map(formatParticipantForPut),
      force_race_decision: d,
    });
  } catch (e) {
    if (e.response && e.response.status === 409) {
      return {
        race: true,
        raceReviewFields: e.response.data.race_review_fields,
        raceValueFields: e.response.data.race_values_fields,
      };
    }

    throw e;
  }
};

const formatPossibleParticipant = (user) => {
  return {
    id: user.userId,
    name: user.name,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    phone: user.phone,
    birthDate: user.birth_date,
    vk: user.social_list_json["vk.com"],
    twitter: user.social_list_json["twitter.com"],
    instagram: user.social_list_json["instagram.com"],
    town: {
      name: user.town.name,
    },
  };
};

export const getPossibleParticipants = async (requestUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(
      `/api/v1/requests/${requestUrl}/possibleParticipants`
    );

    if (!response || !response.data || !response.data.possible_participants) {
      throw new Error("Empty response");
    }

    return response.data.possible_participants.map(formatPossibleParticipant);
  } catch (e) {
    throw e;
  }
};

export const getIsUserCuratorForRequest = async (requestUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.post(
      `/api/v1/requests/${requestUrl}/operation/isCurator`
    );

    if (!response || !response.data) {
      throw new Error("Empty response");
    }

    return response.data.is_curator;
  } catch (e) {
    throw e;
  }
};

const getWeightByActivityMnemocode = ({ activityMnemocode, isCurator }) => {
  let weight = 0;

  switch (activityMnemocode) {
    case "new_curator_here":
      weight = 10;
      break;
    case "announce_added":
      weight = 20;
      break;
    case "comment_added":
      weight = 30;
      break;
    case "request_state_changed":
      weight = 40;
      break;
    case "request_checked":
      weight = 50;
      break;
    case "file_info_updated":
      weight = 60;
      break;
    case "new_file_uploaded":
      weight = 70;
      break;
    case "request_participant_changed":
      weight = 80;
      break;
    case "request_modified":
      weight = 90;
      break;
    case "request_created":
      weight = 100;
      break;
    default:
      weight = 0;
  }

  if (isCurator) {
    weight -= 1;
  }

  return weight;
};

const formatLog = (item) => ({
  createdById: item.created_by.id,
  createdByName: item.created_by.name,
  createdAt: item.created_at,
  activityMnemocode: item.activity_mnemocode,
  isCuratorOnly: item.is_curator_message,
  messageTxt: JSON.parse(item.message_txt),
  isCollapsed: true,
  id: uuidv4(),
  weightByActivityMnemocode: getWeightByActivityMnemocode({
    activityMnemocode: item.activity_mnemocode,
    isCurator: item.is_curator_message,
  }),
});

export const getRequestLogs = async (requestUrl) => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/requests/${requestUrl}/logs`);

    if (!response || !response.data || !response.data.logs) {
      throw new Error("Empty response");
    }

    return response.data.logs
      .map(formatLog)
      .sort(
        (a, b) =>
          moment(b.createdAt).unix() -
          moment(a.createdAt).unix() -
          (b.weightByActivityMnemocode - a.weightByActivityMnemocode)
      );
  } catch (e) {
    throw e;
  }
};

export const createInviteBySocial = async (
  requestUrl,
  requestParticipantId,
  social
) => {
  try {
    const axios = getInstance();

    await axios.post(
      `/api/v1/requests/${requestUrl}/operation/create-invite-by-social`,
      {
        request_participant_id: requestParticipantId,
        query_social: social,
      }
    );
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      throw new Error(e.response.data.error);
    }
  }
};

export const createInviteLink = async (requestUrl, requestParticipantId) => {
  try {
    const axios = getInstance();

    const r = await axios.post(
      `/api/v1/requests/${requestUrl}/operation/create-invite-link`,
      {
        request_participant_id: requestParticipantId,
      }
    );

    if (!r || !r.data || !r.data.link) {
      throw new Error("empty response");
    }

    return r.data.link;
  } catch (e) {
    throw e;
  }
};
