import moment from "moment";
import { getInstance } from "@generic/axios";

const formatContriesAndCities = (item) => ({
  value: item.id,
  label: item.name,
});

const formatProfile = (item) => ({
  nickname: item.name,
  firstName: item.first_name,
  lastName: item.last_name,
  middleName: item.middle_name,
  phone: item.phone,
  isContactInfoOpen: false,
  city: formatContriesAndCities(item.town) || "",
  bdate: moment(item.birth_date).format("DD.MM.YYYY"),
  isHiddenPersonal: item.is_hidden_personal,
  vk: item.social_list_json["vk.com"] || "",
  twitter: item.social_list_json["twitter.com"] || "",
  instagram: item.social_list_json["instagram.com"] || "",
});

export const getCountries = async () => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/countries`);

    if (!response || !response.data || !response.data.countries) {
      throw new Error("Empty response");
    }

    return response.data.countries.map(formatContriesAndCities);
  } catch (e) {
    throw e;
  }
};

export const getCities = async () => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/towns`);

    if (!response || !response.data || !response.data.towns) {
      throw new Error("Empty response");
    }

    return response.data.towns.map(formatContriesAndCities);
  } catch (e) {
    throw e;
  }
};
export const getProfileInfo = async () => {
  try {
    const axios = getInstance();

    const response = await axios.get(`/api/v1/profile/participant-data`);

    if (!response || !response.data || !response.data.participant_data) {
      throw new Error("Empty response");
    }

    return formatProfile(response.data.participant_data);
  } catch (e) {
    throw e;
  }
};

export const changeProfileInfo = async ({
  nickname,
  lastName,
  firstName,
  middleName,
  bdate,
  phone,
  vk,
  instagram,
  twitter,
  isNewCityPartOpen,
  city,
  newCity,
  country,
  isHiddenPersonal,
}) => {
  try {
    const axios = getInstance();

    const participant_data = {
      name: nickname,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      phone,
      town: { id: city.value },
      birth_date: moment(bdate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      is_hidden_personal: isHiddenPersonal,
      social_list_json: {
        "vk.com": vk,
        "twitter.com": twitter,
        "instagram.com": instagram,
      },
    };

    if (isNewCityPartOpen) {
      participant_data.town = { name: newCity, country: { id: country.value } };
    }

    await axios.put("/api/v1/profile/participant-data", {
      participant_data,
    });
  } catch (e) {
    throw e;
  }
};

export const changePassword = async ({ newPassword, oldPassword }) => {
  try {
    const axios = getInstance();

    const request = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    await axios.post("/api/v1/auth/change-password", request);
  } catch (e) {
    throw e;
  }
};

export const getTeamInfo = async () => {
  try {
    const axios = getInstance();

    const response = await axios.get("/api/v1/profile/teammates");

    if (!response || !response.data) {
      throw new Error("empty response");
    }

    const fp = (item) => ({
      ...formatProfile(item),
      inTeamSince: item.in_team_since,
      usrTeammateId: item.usr_teammate_id,
    });

    return {
      leaders: (response.data.leaders || []).map(fp),
      teammates: (response.data.teammates || []).map(fp),
    };
  } catch (e) {
    throw e;
  }
};

export const deleteUsrTeammate = async (usrTeammateId) => {
  try {
    const axios = getInstance();

    await axios.post("/api/v1/profile/operation/delete-teammate", {
      usr_teammate_id: usrTeammateId,
    });
  } catch (e) {
    throw e;
  }
};

export const getInvites = async () => {
  try {
    const axios = getInstance();

    const response = await axios.get("/api/v1/profile/invites");

    if (!response || !response.data || !response.data.invites) {
      throw new Error("empty response");
    }

    return response.data.invites;
  } catch (e) {
    throw e;
  }
};

export const rejectInvite = async (inviteGuid) => {
  try {
    const axios = getInstance();

    await axios.post("/api/v1/profile/invites/reject", {
      invite_guid: inviteGuid,
    });
  } catch (e) {
    throw e;
  }
};

export const acceptInvite = async (inviteGuid) => {
  try {
    const axios = getInstance();

    await axios.post("/api/v1/profile/invites/accept", {
      invite_guid: inviteGuid,
    });
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      throw new Error(e.response.data.error);
    }
  }
};

export const getParticipantByInviteLink = async (link) => {
  try {
    const axios = getInstance();

    const r = await axios.post(
      "/api/v1/profile/invites/operation/get-participant-by-invite-link",
      {
        invite_link: link,
      }
    );

    return r.data;
  } catch (e) {
    throw e;
  }
};

export const acceptInviteByLink = async (link) => {
  try {
    const axios = getInstance();

    const r = await axios.post(
      "/api/v1/profile/invites/operation/accept-invite-link",
      {
        invite_link: link,
      }
    );

    return r.data.invite_guid;
  } catch (e) {
    throw e;
  }
};

export const checkSocial = async (social) => {
  try {
    const axios = getInstance();

    await axios.post("/api/v1/profile/invites/operation/check-social", {
      social,
    });
  } catch (e) {
    throw e;
  }
};
