import React from "react";
import { Route, Switch } from "react-router-dom";

import AuthLayout from "@layouts/auth/index";
import ApplicationLayout from "@layouts/application/index";
import LoginForm from "@scenes/auth/components/login/index";
import RegisterForm from "@scenes/auth/components/register/index";
import FestList from "@scenes/festlist/components/list/index";
import FestListArchive from "@scenes/festlist/components/listArchive/index";
import RequestTypeGroupsList from "@scenes/requests/components/typegroupslist/index";
import FestNewRequest from "@scenes/fest/components/request/index";
import RequestList from "@scenes/requests/components/list/index";
import ResetForm from "@scenes/auth/components/reset/index";
import RequestContainer from "@scenes/requests/components/requestWrapper";
import InviteScene from "@scenes/invite/index";

import ProtectedRoute from "@generic/protectedRoute";
import LoadingSpinner from "@components/Custom/loadingspinner/index";
import Profile from "@scenes/profile/index";
import Donate from "@scenes/donate/index";

const router = () => (
  <>
    <LoadingSpinner />

    <Route path={["/invite/:inviteLink"]} exact>
      <Switch>
        <ProtectedRoute //
          allowAll
          path="/invite/:inviteLink"
          exact
          component={InviteScene}
        />
      </Switch>
    </Route>

    <Route
      path={[
        "/login", //
        "/register",
        "/reset",
      ]}
      exact
    >
      <AuthLayout>
        <Switch>
          <ProtectedRoute //
            allowAnon
            path="/login"
            exact
            component={LoginForm}
          />

          <ProtectedRoute
            allowAnon
            path="/register"
            exact
            component={RegisterForm}
          />

          <ProtectedRoute //
            allowAnon
            path="/reset"
            exact
            component={ResetForm}
          />
        </Switch>
      </AuthLayout>
    </Route>

    <Route
      path={[
        "/", //
        "/fest/:festUrl/:typeUrl/newRequest",
        "/fest/:festUrl/requests/all",
        "/fest/:festUrl/requests/select_type",
        "/fest/:festUrl/requests/my",
        "/fest/:festUrl/:typeUrl/:requestUrl",
        "/archive",
        "/profile",
        "/profile/:profilePage",
        "/donate",
      ]}
      exact
    >
      <ApplicationLayout>
        <Switch>
          <ProtectedRoute //
            allowLoggedIn
            path="/"
            exact
            component={FestList}
          />

          <ProtectedRoute //
            allowLoggedIn
            path={["/profile", "/profile/:profilePage"]}
            exact
            component={Profile}
          />

          <ProtectedRoute //
            allowLoggedIn
            path="/donate"
            exact
            component={Donate}
          />

          <ProtectedRoute
            allowLoggedIn
            path="/fest/:festUrl/:typeUrl/newRequest"
            component={FestNewRequest}
          />

          <ProtectedRoute
            allowLoggedIn
            path="/fest/:festUrl/requests/select_type"
            component={RequestTypeGroupsList}
          />

          <ProtectedRoute
            allowLoggedIn
            path="/fest/:festUrl/requests/:type"
            component={RequestList}
          />

          <ProtectedRoute
            allowLoggedIn //
            path="/archive"
            component={FestListArchive}
          />

          <ProtectedRoute //
            allowLoggedIn
            exact
            path="/fest/:festUrl/:typeUrl/:requestUrl"
            component={RequestContainer}
          />
        </Switch>
      </ApplicationLayout>
    </Route>
  </>
);

export default router;
