import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardFooter, CardHeader } from "reactstrap";
import swal from "sweetalert";

import * as townApi from "@api/town";
import * as festApi from "@api/fest";
import { setLoading } from "@store/mainPage/actions";

import FestListItem from "../itemArchive/index";

import classes from "./index.module.css";

class List extends Component {
  state = {
    fests: [],
    townsById: new Map(),
    searchStr: "",
    error: false,
  };

  componentDidMount = async () => {
    this.setState({
      error: false,
    });

    setLoading(true);

    try {
      const [towns, fests] = await Promise.all([
        townApi.getTowns(),
        festApi.getFests(true),
      ]);

      const townsById = new Map();

      towns.forEach((town) => townsById.set(town.id, town));

      this.setState({
        fests,
        townsById,
        error: false,
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);

      this.setState({
        error: true,
      });

      swal("Ошибка", "Не удалось загрузить фестивали", "error");
    }
  };

  search = (searchStr) => {
    this.setState({
      searchStr,
    });
  };

  render = () => {
    const {
      error, //
      fests: allFests,
      townsById,
      searchStr,
    } = this.state;

    let fests = [];

    if (searchStr === "") {
      fests = [...allFests];
    } else {
      const rexp = new RegExp(searchStr, "i");

      allFests.forEach((item) => {
        if (item.name.match(rexp)) {
          fests.push({ ...item });
        }
      });
    }

    if (error) {
      return (
        <div className="alert alert-danger m-2">
          <strong>Ошибка!</strong> Попробуйте обновить страницу, или повтороте
          попытку позже.
        </div>
      );
    }

    return (
      <div className={["content-wrapper", classes.ContentWrapper].join(" ")}>
        <div className="content-heading">Архив фестивалей</div>

        <Card className="card-default">
          <CardHeader>
            <div className="input-group">
              <input
                type="text" //
                className="form-control"
                placeholder="Найти фестиваль..."
                value={searchStr}
                onChange={(e) => this.search(e.target.value)}
              />

              <span className="input-group-append input-group-addon">
                <span className="input-group-text">
                  <em className="fa fa-search fa-1x"></em>
                </span>
              </span>
            </div>
          </CardHeader>

          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <tbody>
                {fests.map((fest) => (
                  <FestListItem fest={fest} key={fest.id} />
                ))}
              </tbody>
            </table>
          </div>

          <CardFooter className="text-right">
            <span className={classes.Count}>
              Фестивалей в списке: {fests.length}
            </span>
          </CardFooter>
        </Card>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
