import jwtDecode from "jwt-decode";

import * as authApi from "@api/auth";

class AuthService {
  accessToken = "";

  refreshToken = "";

  constructor() {
    const accessToken = localStorage.getItem("accessToken") || "";
    const refreshToken = localStorage.getItem("refreshToken") || "";

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  register;

  async login(username, password) {
    try {
      const tokens = await authApi.login(username, password);

      const { accessToken, refreshToken } = tokens;

      this.accessToken = accessToken;
      this.refreshToken = refreshToken;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
    } catch (e) {
      throw e;
    }
  }

  async register(username, password) {
    try {
      const tokens = await authApi.register(username, password);

      console.log(tokens, "tokens");
    } catch (e) {
      throw e;
    }
  }

  logout() {
    this.accessToken = "";
    this.refreshToken = "";

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }

  isLoggedIn() {
    return !!this.accessToken;
  }

  getUserInfo() {
    const accessToken = localStorage.getItem("accessToken") || "";

    return jwtDecode(accessToken);
  }
}

export default new AuthService();
