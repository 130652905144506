import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Card, CardBody, Row } from "reactstrap";

import { setLoading } from "@store/mainPage/actions";

import FormCropper from "./formCropper/index";
import Uploader from "./uploader/index";
import HeaderPart from "./headerPart/index";
import Footer from "../footer/index";

import classes from "./index.module.css";

class Request extends Component {
  state = {
    error: false,
    submitted: false,
    login: "",
  };

  componentDidMount = async () => {
    // To Do добавить загрузку
  };

  onSubmitClick = async () => {
    const { history } = this.props;

    const { login } = this.state;

    this.setState({ submitted: true });

    history.push("/");
  };

  onCancelClick = async () => {
    const { history } = this.props;

    history.push("/");
  };

  setLogin = (login) => {
    this.setState({ login });
  };

  render = () => {
    const {
      error, //
      submitted,
      login,
    } = this.state;

    if (error) {
      return (
        <div className="alert alert-danger m-2">
          <strong>Ошибка!</strong> Попробуйте обновить страницу, или повтороте
          попытку позже.
        </div>
      );
    }

    return (
      <>
        <Card className={classes.Card}>
          <CardBody>
            <HeaderPart
              submitted={submitted}
              login={login}
              setLogin={(login) => this.setLogin(login)}
            />

            <Row>
              <Uploader />

              <FormCropper />
            </Row>
          </CardBody>

          <Footer
            onSubmitClick={this.onSubmitClick}
            onCancelClick={this.onCancelClick}
          />
        </Card>
      </>
    );
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Request));
