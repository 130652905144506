import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
// import { convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";

import deepEqual from "deep-equal";
import {
  Modal, //
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from "reactstrap";
import swal from "sweetalert";
import { Prompt } from "react-router";

import * as requestApi from "@api/request";
import * as festApi from "@api/fest";
import * as squadApi from "@api/squad";
import { setLoading } from "@store/mainPage/actions";
import eventEmitter from "@services/eventEmitter";

import CommentsPart from "../commentsPart/index";
import CommentEditor from "../commentEditor/index";
import Field from "../field/index";
import IntersectionByRequest from "../participantIntersections/byRequest";
import IntersectionByParticipant from "../participantIntersections/byParticipant";
import ParticipantEditor from "./containerModal/index";
import { getUserFullText } from "../../../../utils/user";

import classes from "./index.module.css";

const maskPhone = (phone) => {
  const mobilePhoneRegex = /^(\d{3})(\d{3})(\d{2})(\d{2})$/;

  //  replace all non digital symbols
  let preparedPhone = phone.replace(/[^\d]/g, "");
  //  remove 8 or any country codes
  if (preparedPhone.length > 10)
    preparedPhone = preparedPhone.substr(preparedPhone.length - 10);

  const maskedPhone = preparedPhone.match(mobilePhoneRegex);

  if (!maskedPhone) {
    throw new Error(`Wrong phone format for ${phone}`);
  }

  let formatedPhone;

  formatedPhone = `(${maskedPhone[1]}) ${maskedPhone[2]}-${maskedPhone[3]}-${maskedPhone[4]}`;

  return formatedPhone;
};

const formatPhone = (phone) => {
  const result = [];

  const s = phone.split(", ");

  s.forEach((item) => {
    try {
      result.push(`+7 ${maskPhone(item)}`);
    } catch (e) {
      // do nothing
    }
  });

  return result.map((item) => <div key={item}>{item}</div>);
};

const groupParticipantIntersections = (intersections, type) => {
  switch (type) {
    case "by_request": {
      const result = {};

      intersections.forEach((item) => {
        if (!result[item.requestCheckId]) {
          result[item.requestCheckId] = [];
        }

        result[item.requestCheckId].push(item);
      });

      return result;
    }
    case "by_participant": {
      const result = {};

      intersections.forEach((item) => {
        if (!result[item.user.id]) {
          result[item.user.id] = [];
        }

        result[item.user.id].push(item);
      });

      return result;
    }
    default:
      return {};
  }
};

const getInitialFields = (typeHint) => {
  return JSON.parse(
    JSON.stringify([
      {
        id: "name",
        name: "Название",
        order: 1,
        hint: typeHint,
        type: "string",
        typeParams: { min_length: 0, max_length: 0 },
        required: true,
        isCuratorOnly: false,
        isUpperBlock: false,
      },
    ])
  );
};

const getInitialValues = () => {
  return JSON.parse(
    JSON.stringify({
      name: {
        value: { value: "" },
      },
      squad: {
        value: {
          value: "",
          guid: "",
          label: "",
        },
      },
      request_participant: {
        value: {},
      },
    })
  );
};

class Request extends Component {
  state = {
    fields: [...getInitialFields()],
    values: { ...getInitialValues() },
    originalValues: {},
    requestStates: [],
    requestTypes: [],
    squads: [],
    participant: [],
    request: {},
    error: false,
    comments: [],
    participantIntersectionsGrouping: "by_request",
    participantIntersections: [],
    groupedParticipantIntersections: {},
    intersectionsCollapsed: true,
    iscommentEditorOpen: false,
    editorState: "",
    isParticipantEditorOpen: false,
    editParticipantIndex: undefined,
    commentReaderTypeOpened: false,
    commentReaderType: "for_user",
    possibleParticipants: [],
    raceModalOpen: false,
    raceData: {
      race: false,
      raceReviewFields: [],
      raceValueFields: [],
    },
    isCuratorForRequest: false,
    requestJustCreated: false,
    hasInitialInfo: false,
    accessDeniedError: false,
  };

  loadParticipantIntersections = async () => {
    const {
      match: {
        params: { requestUrl },
      },
      setLoading,
    } = this.props;

    const { participant } = this.state;

    try {
      const userIds = participant
        .map((item) => item.user.id)
        .reduce((accumulator, current) => {
          if (!!current) {
            accumulator.push(current);
          }

          return accumulator;
        }, []);

      if (userIds.length > 0) {
        setLoading(false);

        const participantIntersections = await requestApi.getRequestParticipantsIntersections(
          requestUrl,
          userIds
        );

        this.setState({
          participantIntersections,
          groupedParticipantIntersections: groupParticipantIntersections(
            participantIntersections,
            this.state.participantIntersectionsGrouping
          ),
        });
      }
    } catch (e) {
      setLoading(false);
    }
  };

  loadAndSetInitialData = async (forcedRequestUrl = "") => {
    const {
      match: {
        params: {
          festUrl, //
          typeUrl,
          requestUrl: routeRequestUrl,
        },
      },
      setLoading,
      onAccessDeniedChange,
    } = this.props;

    try {
      onAccessDeniedChange(false);

      const requestUrl = !!forcedRequestUrl
        ? forcedRequestUrl
        : routeRequestUrl;

      setLoading(true);

      const fields = await requestApi.getRequestFields(festUrl, typeUrl);
      const requestStates = await festApi.getRequestStates(festUrl);
      const requestTypes = await festApi.getRequestTypes(festUrl);
      const squads = await squadApi.getSquads();
      const possibleParticipants = await requestApi.getPossibleParticipants(
        "dump"
      );

      const requestType = requestTypes.find((t) => t.url === typeUrl);
      const typeHint = requestType.hint || "";

      let isCuratorForRequest = false;
      let participant = [];

      let values = {};
      let originalValues = {};
      let request = {};
      let participantIntersections = [];

      if (requestUrl !== "new") {
        isCuratorForRequest = await requestApi.getIsUserCuratorForRequest(
          requestUrl
        );

        request = await requestApi.getRequest(requestUrl);
        const systemFields = await requestApi.getRequestSystemFields(
          requestUrl
        );

        const comments = await requestApi.getRequestComments(requestUrl);

        this.setState({ comments });

        participant = request.requestParticipant;
        // participant = request.requestParticipant.map((p) => ({
        //   ...p,
        //   inviteLink: "",
        //   inviteSocial: "",
        // }));

        request.requestBody.forEach((item) => {
          values[item.fieldId] = {
            reviewMnemocode: item.reviewMnemocode,
            value: { ...item.value },
          };

          originalValues[item.fieldId] = {
            reviewMnemocode: item.reviewMnemocode,
            value: item.value,
          };
        });

        const systemFieldsByFieldName = systemFields.reduce((acc, curr) => {
          acc[curr.systemFieldName] = curr;
          return acc;
        }, {});

        values = {
          ...JSON.parse(JSON.stringify(values)),
        };

        eventEmitter.dispatch("request_data_received", {
          requestName: request.name,
          number: request.number,
          typeName: systemFieldsByFieldName.request_type.value.name,
        });

        Object.keys(systemFieldsByFieldName).forEach((key) => {
          let v;
          let ov;

          if (key === "squad") {
            const s = systemFieldsByFieldName[key].value;

            if (!!s.id && !!s.name) {
              v = {
                value: s.id.toString(),
                label: s.name,
                guid: s.guid,
              };

              ov = {
                id: parseInt(s.id),
                name: s.name,
                guid: s.guid,
              };
            } else {
              v = {
                value: "",
                label: "",
                guid: "",
              };

              ov = {
                id: "",
                name: "",
                guid: "",
              };
            }
          } else {
            v = systemFieldsByFieldName[key].value;
            ov = systemFieldsByFieldName[key].value;
          }

          values[key] = {
            value: v,
            reviewMnemocode: systemFieldsByFieldName[key].reviewMnemocode,
          };

          originalValues[key] = {
            value: ov,
            reviewMnemocode: systemFieldsByFieldName[key].reviewMnemocode,
          };
        });

        const userIds = participant
          .map((item) => item.user.id)
          .reduce((accumulator, current) => {
            if (!!current) {
              accumulator.push(current);
            }

            return accumulator;
          }, []);

        if (userIds.length > 0) {
          participantIntersections = await requestApi.getRequestParticipantsIntersections(
            requestUrl,
            userIds
          );
        }
      } else {
        fields.forEach((field) => {
          switch (field.type) {
            case "string":
            case "multiline":
            case "numeric":
              values[field.id] = {
                value: {
                  value: "",
                },
              };
              break;
            case "duration":
              values[field.id] = {
                value: {
                  time: "",
                  note: "",
                },
              };
              break;
            case "datetime":
              values[field.id] = {
                value: {
                  date_part: null,
                  time_part: null,
                },
              };
              break;
            case "combobox":
              values[field.id] = {
                value: {
                  key: "",
                  value: "",
                },
              };
              break;
            case "checkbox_list":
              values[field.id] = {
                value: {
                  value_list: [
                    ...field.typeParams.item_list.map((item) => ({
                      ...item,
                      check_state: "Unchecked",
                    })),
                  ],
                },
              };
              break;
            default:
              break;
          }
        });

        originalValues = JSON.parse(JSON.stringify(values));

        const typeName = requestTypes.find((t) => t.url === typeUrl);

        eventEmitter.dispatch("request_data_received", {
          requestName: "",
          typeName: typeName.name,
        });
      }

      this.setState({
        fields: [
          ...getInitialFields(typeHint),
          {
            id: "squad",
            name: "Команда",
            hint: `Если команды нет (или для вас это понятие не применимо), то просто не выбирайте ничего в этом поле.`,
            required: true,
            type: "autocomplete_squad",
            typeParams: {
              squads,
            },
            isUpperBlock: false,
          },
          ...fields,
        ],
        values: { ...getInitialValues(), ...values },
        originalValues,
        requestStates,
        requestTypes,
        squads,
        request,
        participant,
        participantIntersections,
        groupedParticipantIntersections: groupParticipantIntersections(
          participantIntersections,
          this.state.participantIntersectionsGrouping
        ),
        possibleParticipants,
        isCuratorForRequest,
        requestJustCreated: false,
        hasInitialInfo: true,
      });

      setLoading(false);

      onAccessDeniedChange(false);
    } catch (e) {
      const { hasInitialInfo } = this.state;

      if (e.message === "ACCESS_DENIED") {
        this.setState({
          accessDeniedError: true,
        });

        setLoading(false);

        onAccessDeniedChange(true);
      } else {
        if (!hasInitialInfo) {
          swal("Ошибка", "Заявки с таким номером не существует", "error");
        }

        setLoading(false);
        throw e;
      }
    }
  };

  componentDidMount = async () => {
    try {
      await this.loadAndSetInitialData();
    } catch (e) {
      setLoading(false);

      this.setState({
        error: true,
      });
    }
  };

  handleFieldChange = (fieldId, value) => {
    const { values } = this.state;

    values[fieldId].value = value;

    this.setState({
      values,
    });
  };

  handleRequestStateChange = (value) => {
    const { requestStates } = this.state;

    const requestState = requestStates.find((item) => item.id === value.key);

    this.setState({
      values: {
        ...this.state.values,
        request_state: {
          ...this.state.values["request_state"],
          value: requestState,
        },
      },
    });
  };

  handleReviewMnemocodeChange = (fieldId, reviewMnemocode) => {
    const { values } = this.state;

    values[fieldId].reviewMnemocode = reviewMnemocode;

    this.setState({
      values,
    });
  };

  readerTypeChange = (commentReaderType) => {
    this.setState({
      commentReaderType,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  addComment = async () => {
    const { editorState, commentReaderType } = this.state;

    const {
      match: {
        params: { requestUrl },
      },
      setLoading,
    } = this.props;

    setLoading(true);

    try {
      // const bodyHtml = draftToHtml(
      //   convertToRaw(editorState.getCurrentContent())
      // );

      await requestApi.postRequestComments({
        requestUrl,
        bodyHtml: editorState,
        commentReaderType,
      });

      const comments = await requestApi.getRequestComments(requestUrl);

      this.setState({ comments, editorState: "" });
    } catch (e) {
      this.setState({
        error: true,
      });
    }

    setLoading(false);
  };

  toggleTypeDropdown = () => {
    this.setState({
      commentReaderTypeOpened: !this.state.commentReaderTypeOpened,
    });
  };

  showCommentEditor = () => {
    this.setState({ iscommentEditorOpen: !this.state.iscommentEditorOpen });
  };

  toggleRaceModal = () => {
    this.setState({
      raceModalOpen: !this.state.raceModalOpen,
    });
  };

  handleCreateInviteLinkClick = async (requestParticipantId) => {
    const {
      match: {
        params: { requestUrl },
      },
      setLoading,
    } = this.props;

    try {
      const { participant } = this.state;

      setLoading(true);

      await requestApi.createInviteLink(requestUrl, requestParticipantId);

      const request = await requestApi.getRequest(requestUrl);

      const participantFromRequest = request.requestParticipant.find(
        (item) => item.id === requestParticipantId
      );

      const newParticipant = participant.map((item) => {
        if (item.id !== requestParticipantId) {
          return item;
        }

        item.invites = participantFromRequest.invites;
        item.inviteLink = participantFromRequest.inviteLink;

        return item;
      });

      this.setState({
        participant: newParticipant,
      });

      swal(
        "Это сообщение об успехе!",
        "Ссылка-приглашение успешно создана",
        "success"
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);

      swal("Ошибка!", "Не удалось создать ссылку-приглашение", "error");
    }
  };

  handleCreateLinkBySocialClick = async (requestParticipantId, social) => {
    const {
      match: {
        params: { requestUrl },
      },
      setLoading,
    } = this.props;

    const { participant } = this.state;

    try {
      setLoading(true);

      await requestApi.createInviteBySocial(
        requestUrl,
        requestParticipantId,
        social
      );

      const request = await requestApi.getRequest(requestUrl);

      const participantFromRequest = request.requestParticipant.find(
        (item) => item.id === requestParticipantId
      );

      const newParticipant = participant.map((item) => {
        if (item.id !== requestParticipantId) {
          return item;
        }

        item.invites = participantFromRequest.invites;

        return item;
      });

      this.setState({
        // isParticipantEditorOpen: false,
        participant: newParticipant,
      });

      swal(
        "Это сообщение об успехе!",
        "Приглашение успешно отправлено участнику! Поторопите его зайти в свой профиль, чтобы подтвердить участие.",
        "success"
      );

      setLoading(false);
    } catch (e) {
      let m;

      switch (e.message) {
        case "NOT_FOUND":
        case "TOO_MANY_USERS":
          m =
            "Не удалось найти участника по социальной сети, попробуйте другой аккаунт или воспользуйтесь ссылкой-приглашением.";
          break;
        case "SELF_INVITE":
          m = "Нет смысла приглашать себя";
          break;
        default:
          m = "Не удалось создать ссылку-приглашение";
      }

      swal("Ошибка!", m, "error");

      setLoading(false);
    }
  };

  getErrorName = (error) => {
    let m;

    switch (error) {
      case "ONLY_PERSONAL_PARTICIPATION_ALLOWED":
        m =
          "В данном типе заявки возможно только личное присутствие автора. Вы можете выбрать в качестве участника только себя.";
        break;

      case "PARTICIPANTS_SHORTAGE":
        m =
          "Недостаточное количество участников в заявке. Нужно добавить еще кого-то.";
        break;

      case "PARTICIPANTS_MAX_EXCEEDED":
        m =
          "Превышено количество участников в заявке. Нужно сократить список участников.";
        break;

      case "ROLE_IS_REQUIRED":
        m =
          "Нужно обязательно указать роль/персонажа для всех участников заявки. Пожалуйста, отредактируйте список участников и укажите всем роли или персонажей.";
        break;

      case "NUMBERS_ARE_INVALID":
        m = "Число указано некорректно.";
        break;

      case "EMPTY_KEY_OF_CHECK_STATE":
      case "FIELD_IS_EMPTY":
        m = "Пустое значение поля.";
        break;

      case "FIELD_IS_TOO_SHORT":
        m = "Слишком короткое значение поля. Допишите еще что-нибудь.";
        break;

      case "FIELD_IS_TOO_LONG":
        m = "Слишком длинное значение поля. Пожалуйста, сократите текст.";
        break;

      case "INVALID_FORMAT":
        m = "Неправильный формат.";
        break;

      case "INVALID_MINUTES":
      case "INVALID_HOURS":
        m =
          "Некорректно указан хронометраж. Например, если у вас заявка на 60 секунд, то следует указать 01:00, а ввод 00:60 приведёт к ошибке.";
        break;

      case "NOT_A_FLOAT_NUMBER":
        m = "Дробное число указано некорректно.";
        break;

      case "FIELD_IS_TOO_SMALL":
        m = "Указано слишком малое значение.";
        break;

      case "INVALID_ACCURACY":
        m = "Неправильно указано количество знаков после запятой.";
        break;

      case "INVALID_KEY":
        m =
          "Выбрано значение, которого больше нет в списке допустимых значений. Обновите страницу с заявкой, чтобы увидеть актуальный список значений.";
        break;

      case "INVALID_CHECK_STATE":
        m =
          "Выбрано значение, которого больше нет в списке допустимых значений. Обновите страницу с заявкой, чтобы увидеть актуальный список значений.";
        break;

      case "NAME_IS_EMPTY":
        m = "Нужно указать название заявки.";
        break;

      case "SQUAD_NAME_IS_NOT_PRESENTED":
        m = "Не указано наименование команды для создания новой.";
        break;

      case "SQUAD_DOES_NOT_EXIST":
        m =
          "Указанной команды не существует, возможно, она была удалена, выберите другую.";
        break;

      default:
        m = "Не удалось сохранить анкету";
    }
    return m;
  };

  handleSubmit = async (forceRaceDecision) => {
    try {
      this.setState({
        raceData: {
          race: false,
          raceReviewFields: [],
          raceValueFields: [],
        },
      });

      const {
        values, //
        requestTypes,
        participant,
        originalValues,
      } = this.state;

      const {
        match: {
          params: {
            requestUrl, //
            typeUrl,
            festUrl,
          },
        },
        history,
      } = this.props;

      const requestBody = Object.keys(values).map((key) => {
        const field = {
          requestFieldId: key,
          value: JSON.parse(JSON.stringify(values[key].value)),
        };

        if (requestUrl !== "new") {
          field.reviewMnemocode = values[key].reviewMnemocode;
        }

        if (key === "squad" && field.value.value) {
          field.value.value = parseInt(field.value.value);
        }

        return field;
      });

      if (requestUrl === "new") {
        const type = requestTypes.find((item) => item.url === typeUrl);

        requestBody.push({
          requestFieldId: "request_type",
          value: {
            id: type.id,
            name: type.name,
            url: type.url,
          },
        });
      }

      let bodyToUpdate = [];

      if (requestUrl === "new") {
        bodyToUpdate = requestBody.map((item) => {
          if (item.requestFieldId === "squad") {
            let squadId = parseInt(item.value.value);
            if (isNaN(squadId)) {
              squadId = "";
            }

            item.value = {
              id: squadId,
              guid: item.value.guid,
              name: item.value.label,
            };
          }

          return item;
        });
      } else {
        requestBody
          .map((item) => JSON.parse(JSON.stringify(item)))
          .forEach((body) => {
            const key = body.requestFieldId;

            if (key === "squad") {
              let squadId = parseInt(body.value.value);
              if (isNaN(squadId)) {
                squadId = "";
              }

              body.value = {
                id: squadId,
                guid: body.value.guid,
                name: body.value.label,
              };
            }

            if (
              body.reviewMnemocode !== originalValues[key].reviewMnemocode ||
              !deepEqual(body.value, originalValues[key].value)
            ) {
              const tmpBody = JSON.parse(JSON.stringify(body));
              tmpBody.valueChanged = !deepEqual(
                body.value,
                originalValues[key].value
              );
              tmpBody.reviewMnemocodeChanged =
                body.reviewMnemocode !== originalValues[key].reviewMnemocode;
              tmpBody.originalValue = JSON.parse(
                JSON.stringify(originalValues[key].value)
              );

              bodyToUpdate.push(tmpBody);
            }
          });
      }

      const participantsToSave = participant.map((item) => ({
        id: item.id,
        orderOnRequest: item.orderOnRequest,
        performerInfo: item.performerInfo,
        userId: item.user ? item.user.id : "",
        inviteSocial: item.socialForInvite,
        inviteLink: item.inviteLink,
      }));

      if (requestUrl === "new") {
        const semanticUrl = await requestApi.createRequest(
          festUrl,
          requestBody,
          participantsToSave
        );

        this.setState(
          {
            requestJustCreated: true,
          },
          async () => {
            history.push(`/fest/${festUrl}/${typeUrl}/${semanticUrl}`);

            await this.loadAndSetInitialData(semanticUrl);

            eventEmitter.dispatch("request_saved", { semanticUrl });
          }
        );
      } else {
        const result = await requestApi.updateRequest(
          festUrl,
          bodyToUpdate,
          participantsToSave,
          requestUrl,
          forceRaceDecision
        );

        if (result && result.race) {
          this.setState({
            raceModalOpen: true,
            raceData: {
              race: true,
              raceReviewFields: result.raceReviewFields,
              raceValueFields: result.raceValueFields,
            },
          });
        } else {
          swal(
            "Это сообщение об успехе!",
            "Анкета успешно сохранена",
            "success"
          );

          this.setState({
            raceModalOpen: false,
          });

          await this.loadAndSetInitialData();

          eventEmitter.dispatch("request_saved", { semanticUrl: requestUrl });
        }
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[0]
      ) {
        const errorName = this.getErrorName(e.response.data.errors[0].code);

        swal("Ошибка", errorName, "error");
      } else {
        swal("Ошибка", "Неизвестная ошибка", "error");
      }

      this.setState({
        error: true,
      });
    }
  };

  showPromtGetStatus = () => {
    const {
      values, //
      editorState,
      originalValues,
      hasInitialInfo,
    } = this.state;

    const {
      match: {
        params: {
          requestUrl, //
        },
      },
    } = this.props;

    const newValue = JSON.parse(JSON.stringify(values));
    const newOriginalValues = JSON.parse(JSON.stringify(originalValues));

    const requestBody = Object.keys(newValue).map((key) => {
      const field = {
        requestFieldId: key,
        value: JSON.parse(JSON.stringify(newValue[key].value)),
      };

      if (requestUrl !== "new") {
        field.reviewMnemocode = values[key].reviewMnemocode;
      }

      return field;
    });

    const showPromt =
      !hasInitialInfo ||
      (!editorState &&
        requestBody
          .map((item) => JSON.parse(JSON.stringify(item)))
          .every((body) => {
            const key = body.requestFieldId;

            if (key === "squad") {
              body.value = {
                id: parseInt(body.value.value) || "",
                name: body.value.label,
                guid: newOriginalValues[key]
                  ? newOriginalValues[key].value.guid
                  : "",
              };
            }

            let isFieldsValueEqual = false;

            if (originalValues[key]) {
              isFieldsValueEqual =
                JSON.stringify(body.value) ===
                  JSON.stringify(newOriginalValues[key].value) &&
                body.reviewMnemocode === newOriginalValues[key].reviewMnemocode;
            }

            return isFieldsValueEqual;
          }));

    return !showPromt;
  };

  toggleIntersectionsCollapsed = () => {
    this.setState({
      intersectionsCollapsed: !this.state.intersectionsCollapsed,
    });
  };

  handleIntersectionGroupingChange = (grouping) => {
    const { participantIntersections } = this.state;

    this.setState({
      participantIntersectionsGrouping: grouping,
      groupedParticipantIntersections: groupParticipantIntersections(
        participantIntersections,
        grouping
      ),
    });
  };

  openParticipantEditor = (index) => {
    this.setState({
      isParticipantEditorOpen: true,
      editParticipantIndex: index,
    });
  };

  saveParticipantEditor = (user, role, socialForInvite, inviteLink) => {
    const { participant, editParticipantIndex } = this.state;

    if (editParticipantIndex === undefined) {
      this.setState(
        {
          participant: [
            ...participant,
            {
              user,
              performerInfo: role,
              socialForInvite,
              inviteLink,
            },
          ],
          isParticipantEditorOpen: false,
          editParticipantIndex: undefined,
        },
        async () => {
          await this.loadParticipantIntersections();
        }
      );
    } else {
      participant[editParticipantIndex].performerInfo = role;
      participant[editParticipantIndex].user = user;
      participant[editParticipantIndex].socialForInvite = socialForInvite;
      participant[editParticipantIndex].inviteLink = inviteLink;

      this.setState(
        {
          participant: [...participant],
          isParticipantEditorOpen: false,
          editParticipantIndex: undefined,
        },
        async () => {
          await this.loadParticipantIntersections();
        }
      );
    }
  };

  deleteParticipant = () => {
    const { participant, editParticipantIndex } = this.state;

    participant.splice(editParticipantIndex, 1);

    this.setState(
      {
        participant,
        isParticipantEditorOpen: false,
        editParticipantIndex: undefined,
      },
      async () => {
        await this.loadParticipantIntersections();
      }
    );
  };

  closeParticipantEditor = () => {
    this.setState({ isParticipantEditorOpen: false });
  };

  getFieldName = (fieldId) => {
    const { fields } = this.state;

    return fields.find((item) => item.id === fieldId).name;
  };

  closeRaceModal = () => {
    this.setState({
      raceModalOpen: false,
      raceData: {
        race: false,
        raceReviewFields: [],
        raceValueFields: [],
      },
    });
  };

  componentWillUnmount = () => {
    window.onbeforeunload = null;
  };

  render = () => {
    const {
      match: {
        params: { festUrl, requestUrl },
      },
    } = this.props;

    const {
      fields, //
      values,
      requestStates,
      requestTypes,
      request,
      comments,
      participant,
      groupedParticipantIntersections,
      participantIntersectionsGrouping,
      intersectionsCollapsed,
      editorState,
      iscommentEditorOpen,
      commentReaderTypeOpened,
      commentReaderType,
      isParticipantEditorOpen,
      editParticipantIndex,
      possibleParticipants,
      raceModalOpen,
      raceData,
      isCuratorForRequest,
      requestJustCreated,
      accessDeniedError,
    } = this.state;

    if (accessDeniedError) {
      return <></>;
    }

    const getSocial = (item, key) => {
      switch (key) {
        case "vk":
          if (item.vk) {
            return { href: item.vk };
          } else {
            return {};
          }
        case "twitter":
          if (item.twitter) {
            return { href: item.twitter };
          } else {
            return {};
          }
        case "instagram":
          if (item.instagram) {
            return { href: item.instagram };
          } else {
            return {};
          }
        default:
          return {};
      }
    };

    const showPromt = this.showPromtGetStatus();

    window.onbeforeunload = function (e) {
      e = e || window.event;
      if (showPromt) {
        if (e) {
          // For IE and Firefox prior to version 4
          e.returnValue = "Sure?";
        }

        // For Safari
        return "Sure?";
      }
    };

    const currentTime = moment();

    const isNewRequest = requestUrl === "new";

    const fieldRequestState = {
      hint: "",
      id: "request_state",
      isCuratorOnly: false,
      name: "Статус заявки",
      required: true,
      type: "combobox_request_state",
      typeParams: {
        item_list: requestStates.map((item) => ({
          key: item.id,
          value: item.name,
        })),
      },
    };

    const fieldRequestType = {
      hint: "",
      id: "request_type",
      isCuratorOnly: false,
      name: "Тип заявки",
      required: true,
      type: "combobox_request_type",
      typeParams: {
        item_list: requestTypes.map((item) => ({
          key: item.id,
          value: item.name,
        })),
      },
    };

    const fieldParticipant = {
      hint: "",
      id: "participant",
      isCuratorOnly: false,
      name: "Список участников",
      required: true,
      type: "table_participant",
      typeParams: {},
    };

    const nameAndSquadEl = fields
      .filter((item) => item.id === "name" || item.id === "squad")
      .map((field) => {
        return (
          <Field
            key={field.id} //
            field={field}
            value={values[field.id].value}
            isNewRequest={isNewRequest}
            isCuratorForRequest={isCuratorForRequest}
            reviewMnemocode={values[field.id].reviewMnemocode}
            onReviewMnemocodeChange={(reviewMnemocode) =>
              this.handleReviewMnemocodeChange(field.id, reviewMnemocode)
            }
            onChange={(value) => this.handleFieldChange(field.id, value)}
            hideControls={!isCuratorForRequest}
          />
        );
      });

    let participantIntersectionsEl = [];

    const totalIntersections = Object.keys(groupedParticipantIntersections)
      .length;

    switch (participantIntersectionsGrouping) {
      case "by_request":
        Object.keys(groupedParticipantIntersections).forEach((item, index) => {
          participantIntersectionsEl = [
            ...participantIntersectionsEl,
            <IntersectionByRequest
              intersections={groupedParticipantIntersections[item]}
              noBorder={totalIntersections === index + 1}
              festUrl={festUrl}
            />,
          ];
        });

        break;
      case "by_participant":
        Object.keys(groupedParticipantIntersections).forEach((item, index) => {
          participantIntersectionsEl = [
            ...participantIntersectionsEl,
            <IntersectionByParticipant
              intersections={groupedParticipantIntersections[item]}
              noBorder={totalIntersections === index + 1}
              festUrl={festUrl}
            />,
          ];
        });
        break;
      default:
        break;
    }

    const upperBlockRegularFieldsEl = fields
      .filter((item) => item.id !== "name" && item.id !== "squad")
      .filter((item) => item.isUpperBlock)
      .map((field) => {
        return (
          <Field
            key={field.id} //
            field={field}
            value={values[field.id].value}
            isNewRequest={isNewRequest}
            reviewMnemocode={values[field.id].reviewMnemocode}
            onReviewMnemocodeChange={(reviewMnemocode) =>
              this.handleReviewMnemocodeChange(field.id, reviewMnemocode)
            }
            onChange={(value, selected) =>
              this.handleFieldChange(field.id, value, selected)
            }
            hideControls={!isCuratorForRequest}
            isCuratorForRequest={isCuratorForRequest}
          />
        );
      });

    const regularFieldsEl = fields
      .filter((item) => item.id !== "name" && item.id !== "squad")
      .filter((item) => !item.isUpperBlock)
      .map((field) => {
        return (
          <Field
            key={field.id} //
            field={field}
            value={values[field.id].value}
            isNewRequest={isNewRequest}
            isCuratorForRequest={isCuratorForRequest}
            reviewMnemocode={values[field.id].reviewMnemocode}
            onReviewMnemocodeChange={(reviewMnemocode) =>
              this.handleReviewMnemocodeChange(field.id, reviewMnemocode)
            }
            onChange={(value, selected) =>
              this.handleFieldChange(field.id, value, selected)
            }
            hideControls={!isCuratorForRequest}
          />
        );
      });

    return (
      <div>
        {isParticipantEditorOpen ? (
          <ParticipantEditor
            onConfirmClick={this.saveParticipantEditor}
            onCancelClick={this.closeParticipantEditor}
            onDeleteClick={this.deleteParticipant}
            participantArray={possibleParticipants}
            currentParticipants={participant}
            editParticipantIndex={editParticipantIndex}
            onCreateLinkBySocialClick={this.handleCreateLinkBySocialClick}
            onCreateInviteLinkClick={this.handleCreateInviteLinkClick}
          />
        ) : null}

        <React.Fragment>
          <Prompt
            when={showPromt && !requestJustCreated}
            message="У вас есть несохранённые изменения, вы точно хотите покинуть страницу?"
          />
        </React.Fragment>

        <Modal isOpen={raceModalOpen} toggle={this.toggleRaceModal}>
          <ModalHeader toggle={this.toggleRaceModal}>
            <strong>Несовместимые правки</strong>
          </ModalHeader>

          {raceModalOpen ? (
            <>
              <ModalBody>
                {raceData.raceReviewFields &&
                raceData.raceReviewFields.length === 0 ? null : (
                  <>
                    <p>
                      <strong>Внимание!</strong> В течение проверки поля были
                      отредактированы. Мы не записали результаты проверки по
                      этим полям. Пожалуйста, проверьте эти поля еще раз по их
                      актуальным значениям. Список таких полей:
                    </p>

                    <ul>
                      {raceData.raceReviewFields.map((item) => (
                        <li key={item}>{this.getFieldName(item)}</li>
                      ))}
                    </ul>
                  </>
                )}

                {raceData.raceValueFields &&
                raceData.raceValueFields.length === 0 ? null : (
                  <>
                    <p>
                      <strong>Внимание!</strong> Поля, которые Вы
                      отредактировали и собираетесь сохранить, успели изменить.
                      Эти правки можно увидеть в ленте активности.
                    </p>

                    <p>
                      Вы хотите продолжить сохранение своих версий этих полей?
                      (Остальные поля сохранятся как обычно)
                    </p>

                    <p>
                      * Да - сохранить Ваши правки поверх уже совершённых
                      правок.
                    </p>
                    <p>* Нет - Ваши правки по этим полям не будут сохранены.</p>
                    <p>* Отмена - пока не сохранять заявку и подумать.</p>

                    <p>Список таких полей:</p>

                    <ul>
                      {raceData.raceValueFields
                        ? raceData.raceValueFields.map((item) => {
                            if (item === "request_state") {
                              return <li key={item}>Статус заявки</li>;
                            } else {
                              return (
                                <li key={item}>{this.getFieldName(item)}</li>
                              );
                            }
                          })
                        : null}
                    </ul>
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                {raceData.raceValueFields &&
                raceData.raceValueFields.length > 0 ? (
                  <>
                    <button
                      className={[
                        "btn btn-warning",
                        classes.ButtonPrimary,
                      ].join(" ")}
                      onClick={() => this.handleSubmit(true)}
                    >
                      Да
                    </button>

                    <button
                      className={["btn btn-danger", classes.ButtonPrimary].join(
                        " "
                      )}
                      onClick={() => this.handleSubmit(false)}
                    >
                      Нет
                    </button>

                    <button
                      className={[
                        "btn btn-secondary",
                        classes.ButtonSecondary,
                      ].join(" ")}
                      onClick={this.closeRaceModal}
                    >
                      Отмена
                    </button>
                  </>
                ) : (
                  <>
                    <Button color="primary" onClick={this.closeRaceModal}>
                      ОК
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          ) : null}
        </Modal>

        <div className={classes.Wrapper}>
          {values["request_state"] ? (
            <Field
              field={fieldRequestState} //
              value={values["request_state"].value}
              isNewRequest={isNewRequest}
              isCuratorForRequest={isCuratorForRequest}
              onChange={(value) => this.handleRequestStateChange(value)}
              hideControls
              noBorders
            />
          ) : null}

          {isNewRequest ? null : (
            <div
              className={[
                "d-flex",
                "align-items-center",
                classes.DateWrapper,
              ].join(" ")}
            >
              <div className={classes.FieldName}>
                <strong>Создано</strong>
              </div>

              <div className={classes.Date}>
                {moment(request.createdAt).format("DD.MM.YYYY")}{" "}
                <span className={classes.User}>[{request.createdBy}]</span>
              </div>
            </div>
          )}

          {values["request_type"] ? (
            <Field
              field={fieldRequestType} //
              value={values["request_type"].value}
              isNewRequest={isNewRequest}
              onChange={(value) => this.handleRequestTypeChange(value)}
              hideControls
              noBorders
              disabled
            />
          ) : null}

          {isNewRequest ? null : (
            <div
              className={[
                "d-flex",
                "align-items-center",
                classes.DateWrapper,
              ].join(" ")}
            >
              <div className={classes.FieldName}>
                <strong>Изменено</strong>
              </div>

              <div className={classes.Date}>
                {moment(request.updatedAt).format("DD.MM.YYYY")}{" "}
                <span className={classes.User}>[{request.updatedBy}]</span>
              </div>
            </div>
          )}

          {nameAndSquadEl}

          {upperBlockRegularFieldsEl}

          <Field
            field={fieldParticipant} //
            isNewRequest={isNewRequest}
            reviewMnemocode={values["request_participant"].reviewMnemocode}
            onReviewMnemocodeChange={(reviewMnemocode) =>
              this.handleReviewMnemocodeChange(
                "request_participant",
                reviewMnemocode
              )
            }
            hideControls={!isCuratorForRequest}
          />

          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <table
              className={[
                "table",
                "table-striped",
                "my-4",
                "w-100",
                "dataTable",
                "no-footer",
                "dtr-inline",
                classes.Table,
                classes.ParticipantTable,
              ].join(" ")}
            >
              <thead>
                <tr>
                  <th>№</th>
                  <th>Участник</th>
                  <th>Город</th>
                  <th>Соц.сети</th>
                  <th>Телефон</th>
                  <th>Возраст</th>
                </tr>
              </thead>

              <tbody className={classes.TableBody}>
                {participant.map((item, index) => (
                  <tr key={item.id}>
                    <td className={classes.OrderNumber}>{index + 1}</td>
                    <td>
                      <div
                        className={classes.Name}
                        onClick={() => this.openParticipantEditor(index)}
                      >
                        {getUserFullText(item.user)}
                      </div>

                      <div className={classes.PerformerInfo}>
                        {item.performerInfo}
                      </div>
                    </td>

                    <td>{item.user.town ? item.user.town.name : "-"}</td>

                    <td className={classes.Email}>
                      <div className="col-auto p-0 text-white">
                        {item.user.email ? (
                          <a
                            className={[
                              "btn btn-primary  thumb32 rounded-circle mr-1 ml-1",
                              classes.Icons,
                            ].join(" ")}
                            type="button"
                            href={`mailto:${item.user.email}`}
                          >
                            <em className="fa-1x icon-envelope-open" />
                          </a>
                        ) : null}

                        {item.user.vk ? (
                          <a
                            type="button"
                            target="_blank"
                            rel="noopener noreferrer"
                            {...getSocial(item.user, "vk")}
                            className={[
                              "btn btn-primary thumb32 rounded-circle mr-1 ml-1",
                              classes.Icons,
                            ].join(" ")}
                          >
                            <em className="fab fa-vk" />
                          </a>
                        ) : null}

                        {item.user.twitter ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            {...getSocial(item.user, "twitter")}
                            className={[
                              "btn btn-primary thumb32 rounded-circle mr-1 ml-1",
                              classes.Icons,
                            ].join(" ")}
                            type="button"
                          >
                            <em className="fab fa-twitter" />
                          </a>
                        ) : null}

                        {item.user.instagram ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            {...getSocial(item.user, "instagram")}
                            className={[
                              "btn btn-primary thumb32 rounded-circle ml-1",
                              classes.Icons,
                            ].join(" ")}
                            type="button"
                          >
                            <em className="fab fa-instagram" />
                          </a>
                        ) : null}
                      </div>
                    </td>

                    <td>
                      {item.user.phone ? formatPhone(item.user.phone) : "-"}
                    </td>

                    <td>
                      {item.user.birthDate
                        ? moment().diff(moment(item.user.birthDate), "years")
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={classes.ParticipantButtonWrapper}>
            <button
              className="btn btn-primary" //
              onClick={() => this.openParticipantEditor(undefined)}
            >
              Добавить участника
            </button>
          </div>

          <div className={classes.IntersectionContainer}>
            <div className={classes.IntersectionsCollapse}>
              <span
                className={classes.Label}
                onClick={this.toggleIntersectionsCollapsed}
              >
                <span className={classes.Underline}>
                  Пересечения участников{" "}
                </span>
                &nbsp;{!intersectionsCollapsed ? "-" : "+"}
              </span>
            </div>

            {intersectionsCollapsed ? null : (
              <>
                <div className={classes.IntersectionsHeaderWrapper}>
                  <div>Группировка</div>

                  <div className={classes.IntersectionType}>
                    <select
                      className="custom-select"
                      value={participantIntersectionsGrouping}
                      onChange={(e) =>
                        this.handleIntersectionGroupingChange(e.target.value)
                      }
                    >
                      <option value="by_request">Заявки -&gt; Участники</option>
                      <option value="by_participant">
                        Участники -&gt; Заявки
                      </option>
                    </select>
                  </div>

                  <div>
                    {participantIntersectionsGrouping === "by_request"
                      ? `Заявок с песесечениями: ${totalIntersections}`
                      : `Участников с песесечениями: ${totalIntersections}`}
                  </div>
                </div>

                <div className={classes.IntersectionsWrapper}>
                  {participantIntersectionsEl}
                </div>
              </>
            )}
          </div>

          {regularFieldsEl}
        </div>

        <div className={classes.SubmitContainer}>
          <button
            className="btn btn-primary" //
            onClick={this.handleSubmit}
          >
            Сохранить заявку!
          </button>
        </div>

        {isNewRequest ? null : (
          <>
            <CommentEditor
              editorState={editorState}
              iscommentEditorOpen={iscommentEditorOpen}
              commentReaderTypeOpened={commentReaderTypeOpened}
              commentReaderType={commentReaderType}
              addComment={this.addComment}
              showCommentEditor={this.showCommentEditor}
              onEditorStateChange={this.onEditorStateChange}
              toggleTypeDropdown={this.toggleTypeDropdown}
              readerTypeChange={(commentReaderType) =>
                this.readerTypeChange(commentReaderType)
              }
              isCuratorForRequest={isCuratorForRequest}
            />

            <CommentsPart comments={comments} currentTime={currentTime} />
          </>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => {
    dispatch(setLoading(loading));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Request));
