import * as festApi from "@api/fest";
import { setFestTile, removeFestTile } from "@store/mainPage/actions";

export const checkFestTileEffect = (festUrl) => async (dispatch, getState) => {
  try {
    const {
      mainPage: {
        festTile: { url },
      },
    } = getState();

    if (url === festUrl) {
      return;
    }

    const fest = await festApi.getFest(festUrl);

    dispatch(setFestTile(fest.url, fest.mainLogoPath, fest.name));
  } catch (e) {
    dispatch(removeFestTile());

    throw e;
  }
};
