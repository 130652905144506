import React from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import config from "@generic/config";
import authService from "@services/auth";

import Dates from "../dates/dates";

import placeholderUrl from "./defaultPlaceholderImage.png";

import classes from "./index.module.css";

const addDefaultSrc = (e) => (e.target.src = placeholderUrl);

const festItem = ({
  fest: {
    dates, //
    url,
    name,
    description,
    mainLogoPath,
  },
}) => {
  const userInfo = authService.getUserInfo();

  const type = userInfo.isCurator ? "all" : "my";

  return (
    <Card className="card-default">
      <CardBody>
        <div className="row">
          <div className="col-3">
            <img
              className={classes.Image} //
              src={`${config.get("filesUrl")}/${mainLogoPath}`}
              alt="img"
              onError={addDefaultSrc}
            />
          </div>

          <div className="col-9">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Dates dates={dates} festActive={true} />
              </div>

              <div>
                <Link to={`/fest/${url}/requests/${type}`}>
                  <button className="btn btn-primary">Выбрать</button>
                </Link>
              </div>
            </div>

            <div className={[classes.Name, "text-primary"].join(" ")}>
              <Link to={`/fest/${url}/requests/${type}`}>{name}</Link>
            </div>

            <div
              className={classes.Description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default festItem;
