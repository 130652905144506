import React, { Component } from "react";
import { Input } from "reactstrap";
import { Creatable } from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import MaskedInput from "react-maskedinput";
import moment from "moment";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import classes from "./index.module.css";

const getClassName = (reviewMnemocode) => {
  switch (reviewMnemocode) {
    case "wait_for_check":
      return classes.BgWaitForCheck;
    case "checked":
      return classes.BgChecked;
    case "need_to_correct":
      return classes.BgNeedToCorrect;
    default:
      return "";
  }
};

const getMnemocodeItem = (reviewMnemocode) => {
  switch (reviewMnemocode) {
    case "wait_for_check":
      return (
        <em
          className={["fa", "fa-pause-circle", "fa-1x", classes.Icon].join(" ")}
        />
      );
    case "checked":
      return (
        <em
          className={["fab", "fa-gratipay", "fa-1x", classes.Icon].join(" ")}
        />
      );

    case "need_to_correct":
      return (
        <em
          className={["fas", "fa-times-circle", "fa-1x", classes.Icon].join(
            " "
          )}
        ></em>
      );
    default:
      return <></>;
  }
};

class Field extends Component {
  state = {
    reviewOpened: false,
  };

  toggleReview = () => {
    this.setState({
      reviewOpened: !this.state.reviewOpened,
    });
  };

  render = () => {
    const { reviewOpened } = this.state;

    const {
      field, //
      value,
      onChange,
      isNewRequest,
      onReviewMnemocodeChange,
      reviewMnemocode,
      hideControls,
      noBorders,
      disabled,
      isCuratorForRequest,
    } = this.props;

    const {
      type, //
      name,
      hint,
      typeParams,
      isCuratorOnly,
    } = field;

    let el = <></>;
    let subEl = <></>;

    switch (type) {
      case "string":
        el = (
          <Input
            type="text" //
            value={value.value}
            disabled={
              name === "Название" && !isNewRequest && !isCuratorForRequest
            }
            onChange={(e) => {
              onChange({ value: e.target.value });
            }}
          />
        );
        break;
      case "duration":
        const { time, note } = value;

        el = (
          <div className={classes.DurationWrapper}>
            <div className={classes.Duration}>
              <MaskedInput
                className="form-control"
                mask="11:11"
                value={value.time}
                onChange={(e) => {
                  onChange({ time: e.target.value, note });
                }}
              />
            </div>

            <div className={classes.Note}>
              <Input
                type="text" //
                value={value.note}
                onChange={(e) => {
                  onChange({ note: e.target.value, time });
                }}
              />
            </div>
          </div>
        );
        break;
      case "multiline":
        subEl = (
          <div className={classes.SubElWrapper}>
            <textarea
              rows="6" //
              className="mb-3 form-control"
              value={value.value}
              onChange={(e) => {
                onChange({ value: e.target.value });
              }}
            ></textarea>
          </div>
        );
        break;
      case "datetime":
        const { is_need_date /*is_need_time*/ } = typeParams;

        let v;

        if (moment(value.date_part, "YYYYMMDD").isValid()) {
          v = `${value.date_part.substring(6, 8)}.${value.date_part.substring(
            4,
            6
          )}.${value.date_part.substring(0, 4)}`;
        }

        el = (
          <Datetime
            dateFormat="DD.MM.YYYY" //
            timeFormat={false}
            inputProps={{ className: "form-control" }}
            value={v}
            onChange={(e) => {
              let value = "";

              if (!moment.isMoment(e)) {
                value = "";
              } else {
                if (is_need_date) {
                  value += e.format("YYYYMMDD");
                }
              }

              // TODO add time handling
              // TODO fix value incorrect format issue
              onChange({
                date_part: value,
                time_part: null,
              });
            }}
          />
        );

        break;
      case "autocomplete_squad":
        el = (
          <Creatable
            value={value}
            formatCreateLabel={(userInput) => `Создать "${userInput}"`}
            formatOptionLabel={(label) => {
              if (!label.value) {
                return <>&nbsp;</>;
              }

              return <strong>{label.label}</strong>;
            }}
            className={classes.SelectFileType}
            onChange={(e) => onChange(e)}
            options={[{ value: "", guid: "", label: "" }, ...typeParams.squads]}
            placeholder="Выберите команду..."
          />
        );
        break;
      case "combobox_request_state":
      case "combobox_request_type": {
        const options = [];

        typeParams.item_list.forEach((item) =>
          options.push({
            label: item.value,
            value: item.key,
          })
        );

        el = (
          <select
            className="custom-select" //
            disabled={disabled || !isCuratorForRequest}
            value={value.id}
            onChange={(e) => {
              const value = typeParams.item_list.find(
                (item) => item.key === e.target.value
              );

              onChange(value);
            }}
          >
            {options.map((option) => (
              <option
                value={option.value} //
                key={option.label}
              >
                {option.label}
              </option>
            ))}
          </select>
        );
        break;
      }
      case "combobox":
        const options = [];

        if (!typeParams.item_list.some((t) => t.key === "")) {
          typeParams.item_list = [
            { key: "", value: "" },
            ...typeParams.item_list,
          ];
        }

        typeParams.item_list.forEach((item) =>
          options.push({
            label: item.value,
            value: item.key,
          })
        );

        el = (
          <select
            className="custom-select" //
            value={value.key}
            onChange={(e) => {
              const value = typeParams.item_list.find(
                (item) => item.key === e.target.value
              );

              onChange(value);
            }}
          >
            {options.map((option) => (
              <option
                value={option.value} //
                key={option.label}
              >
                {option.label}
              </option>
            ))}
          </select>
        );
        break;
      case "numeric":
        // TODO add mask depend on params

        el = (
          <Input
            type="text" //
            step="0.01"
            value={value.value}
            onChange={(e) => {
              try {
                // TODO add regexp check

                if (e.target.value === "" || e.target.value === "-") {
                  onChange({ value: e.target.value });

                  return;
                }

                if (e.target.value[e.target.value.length - 1] === ".") {
                  if (e.target.value.match(/\./g).length === 1) {
                    onChange({ value: e.target.value });
                  }

                  return;
                }

                const v = parseFloat(e.target.value);

                if (isNaN(v)) {
                  return;
                }

                onChange({ value: v });
              } catch (err) {
                // do nothing
              }
            }}
          />
        );
        break;
      case "checkbox_list":
        const { value_list: valueList } = value;

        subEl = (
          <div className={classes.SubElWrapper}>
            {typeParams.item_list.map((item) => {
              const valueIndex = value.value_list.findIndex(
                (v) => v.key === item.key
              );

              let checked = false;
              if (valueIndex !== -1) {
                checked =
                  value.value_list[valueIndex].check_state === "Checked";
              }

              // TODO add intermediate state

              return (
                <div
                  className={[
                    "checkbox", //
                    "c-checkbox",
                    classes.Checkbox,
                  ].join(" ")}
                  key={item.key}
                >
                  <label className="mb-1">
                    <Input
                      type="checkbox"
                      bsSize="sm"
                      checked={checked}
                      onChange={(e) => {
                        const v = [];

                        valueList.forEach((valueListItem) => {
                          if (valueListItem.key === item.key) {
                            const newState =
                              valueListItem.check_state === "Checked"
                                ? "Unchecked"
                                : "Checked";

                            v.push({
                              ...valueListItem,
                              check_state: newState,
                            });
                          } else {
                            v.push({ ...valueListItem });
                          }
                        });

                        onChange({
                          value_list: v,
                        });
                      }}
                    />
                    <span
                      className={["fa", "fa-check", classes.Check].join(" ")}
                    ></span>
                    {item.value}
                  </label>
                </div>
              );
            })}
          </div>
        );
        break;
      default:
        el = <>{JSON.stringify(field)}</>;
        subEl = null;
    }

    const className = getClassName(reviewMnemocode);

    return (
      <div
        className={[
          classes.Wrapper,
          type === "table_participant" ? classes.NoGap : "",
          className,
          field.isCuratorOnly ? classes.CuratorBackground : "",
          !noBorders ? classes.Bordered : "",
        ].join(" ")}
      >
        <div className="d-flex align-items-center">
          {type !== "table_participant" ? (
            <>
              <div className={classes.FieldName}>
                <strong>{name}</strong>
              </div>

              <div
                className={[
                  classes.FieldWrapper,
                  type === "autocomplete_squad" ? classes.WithFix : "",
                ].join(" ")}
              >
                <div>{el}</div>

                {!!hint && name !== "Название" && name !== "Команда" ? (
                  <div
                    className={classes.Hint}
                    dangerouslySetInnerHTML={{ __html: hint }}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className={classes.FieldName}>&nbsp;</div>

              <div
                className={[
                  classes.FieldWrapper,
                  type === "autocomplete_squad" ? classes.WithFix : "",
                  classes.OnlyNameField,
                ].join(" ")}
              >
                <div>
                  <strong>{name}</strong>
                </div>
              </div>
            </>
          )}

          <div className={classes.Controls}>
            {isNewRequest || hideControls ? null : (
              // TODO add role check for each control
              <>
                <div>
                  <ButtonDropdown
                    isOpen={reviewOpened}
                    toggle={this.toggleReview}
                  >
                    {isCuratorOnly ? (
                      <>
                        <DropdownToggle caret>
                          <em
                            className={[
                              "fa-1x fas fa-user-tie",
                              classes.Icon,
                            ].join(" ")}
                          />
                        </DropdownToggle>

                        <DropdownMenu className={classes.ReviewMenu}>
                          <DropdownItem onClick={() => {}}>
                            <em
                              className={[
                                "fa-1x fas fa-user-tie",
                                classes.Icon,
                              ].join(" ")}
                            />
                            Для куратора
                          </DropdownItem>
                        </DropdownMenu>
                      </>
                    ) : (
                      <>
                        <DropdownToggle caret>
                          {getMnemocodeItem(reviewMnemocode)}
                        </DropdownToggle>

                        <DropdownMenu className={classes.ReviewMenu}>
                          <DropdownItem
                            className={classes.BgWaitForCheck}
                            onClick={() =>
                              onReviewMnemocodeChange("wait_for_check")
                            }
                          >
                            <em
                              className={[
                                "fa",
                                "fa-pause-circle",
                                "fa-1x",
                                classes.Icon,
                              ].join(" ")}
                            ></em>{" "}
                            Не проверено
                          </DropdownItem>
                          <DropdownItem
                            className={classes.BgChecked}
                            onClick={() => onReviewMnemocodeChange("checked")}
                          >
                            <em
                              className={[
                                "fab",
                                "fa-gratipay",
                                "fa-1x",
                                classes.Icon,
                              ].join(" ")}
                            ></em>{" "}
                            Проверено
                          </DropdownItem>
                          <DropdownItem
                            className={classes.BgNeedToCorrect}
                            onClick={() =>
                              onReviewMnemocodeChange("need_to_correct")
                            }
                          >
                            <em
                              className={[
                                "fas",
                                "fa-times-circle",
                                "fa-1x",
                                classes.Icon,
                              ].join(" ")}
                            ></em>{" "}
                            Исправить!
                          </DropdownItem>
                        </DropdownMenu>
                      </>
                    )}
                  </ButtonDropdown>
                </div>

                <div>
                  <button className="btn btn-secondary">
                    <em
                      className={[
                        "fa",
                        "fa-comment-dots",
                        "fa-1x",
                        classes.Icon,
                      ].join(" ")}
                    ></em>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        {name === "Название" || name === "Команда" ? (
          <div className="d-flex align-items-center">
            <>
              <div className={classes.FieldName}></div>

              <div
                className={[
                  classes.FieldWrapper,
                  type === "autocomplete_squad" ? classes.WithFix : "",
                ].join(" ")}
              >
                {!!hint ? (
                  <div
                    className={classes.Hint}
                    dangerouslySetInnerHTML={{ __html: hint }}
                  />
                ) : null}
              </div>
            </>

            <div className={classes.Controls}></div>
          </div>
        ) : null}

        {subEl}
      </div>
    );
  };
}

export default Field;
