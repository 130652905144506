import React, { Component } from "react";
import {
  Card, //
  CardHeader,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";

import authService from "@services/auth";
import history from "@generic/history";

class inviteScene extends Component {
  componentDidMount = () => {
    const {
      match: {
        params: {
          inviteLink, //
        },
      },
    } = this.props;

    if (authService.isLoggedIn()) {
      history.push(`/profile/my-team?invite-link=${inviteLink}`);
    }
  };

  render = () => {
    const {
      match: {
        params: {
          inviteLink, //
        },
      },
    } = this.props;

    return (
      <div className="row m-0">
        <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-sm-12">
          <div className="text-center">
            <img
              src={`http://localhost:5000/files/fest_files_main_logo/nyan_fest_2021/nyan-fest-2021.png`}
              style={{ maxWidth: "40%" }}
              alt="logo"
            />
          </div>

          <Card>
            <CardHeader>
              <strong>Ссылка-приглашение</strong>
            </CardHeader>

            <CardBody>
              <p>
                Для того, чтобы воспользоваться ссылкой-приглашением, необходимо{" "}
                <Link to={`/login?invite-link=${inviteLink}`}>
                  войти под своей учетной записью.
                </Link>
              </p>

              <p>
                Если у вас еще нет учетной записи, нужно{" "}
                <Link to={`/register?invite-link=${inviteLink}`}>
                  зарегистрироваться.
                </Link>
              </p>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  };
}

export default inviteScene;
