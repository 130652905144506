import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Alert, Card } from "reactstrap";

import { setLoading } from "@store/mainPage/actions";
import {
  MY_PROFILE, //
  INFO,
  MY_TEAM,
  CHANGE_PASSWORD,
  PAGES,
} from "@components/Constants/profile";

import MyProfile from "./components/myProfile/index";
import MyTeam from "./components/myTeam/index";
import Info from "./components/info/index";
import ChangePassword from "./components/changePassword/index";

import classes from "./index.module.css";
import { getProfileInfo } from "../../api/profile";
import { getProfileInfoEffect } from "../../store/profile/effects";

class Request extends Component {
  componentDidMount = async () => {
    await getProfileInfo();
  };

  changePage = async (id) => {
    const { history } = this.props;

    history.push(`/profile/${id}`);
  };

  render = () => {
    const {
      match: {
        params: { profilePage },
      },
    } = this.props;

    const pages = () => {
      const {
        match: {
          params: { profilePage },
        },
        history,
      } = this.props;

      switch (profilePage) {
        case MY_PROFILE:
          return <MyProfile />;
        case INFO:
          return <Info />;
        case MY_TEAM:
          return <MyTeam />;
        case CHANGE_PASSWORD:
          return <ChangePassword />;

        default:
          history.push(`/profile/${INFO}`);
      }
    };

    return (
      <>
        <div className={["content-wrapper", classes.ContentWrapper].join(" ")}>
          <div className="content-heading">Мой профиль</div>

          <div className="row">
            <div className="col-lg-3">
              <Card className="b">
                <ul className="list-group">
                  {PAGES.map((item) => (
                    <li
                      key={item.name}
                      className={[
                        classes.Pages,
                        "list-group-item-action list-group-item list-inline",
                        profilePage === item.id ? "active" : "",
                      ].join(" ")}
                      onClick={() => this.changePage(item.id)}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </Card>
            </div>

            <div className="col-lg-9">
              {profilePage === MY_TEAM || profilePage === INFO ? (
                <Alert color={["warning mb-2", classes.Alert].join(" ")}>
                  <em className="fa-2x mr-2 fas fa-exclamation-triangle" />

                  {profilePage === MY_TEAM ? (
                    <strong>
                      <span>
                        Ознакомьтесь с системой приглашений: это доступ к вашему
                        профилю.
                      </span>
                    </strong>
                  ) : null}

                  {profilePage === INFO ? (
                    <strong>
                      <span>
                        Укажите реальные данные: они нужны для оформления
                        пропуска.
                      </span>
                    </strong>
                  ) : null}
                </Alert>
              ) : null}

              <Card className="b">{pages()}</Card>
            </div>
          </div>
        </div>
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  festTile: state.mainPage.festTile,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
  getProfileInfo: () => {
    dispatch(getProfileInfoEffect());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Request));
