import { getInstance } from "@generic/axios";

export const getTowns = async () => {
  try {
    const axios = getInstance();

    const response = await axios.get("/api/v1/towns");

    if (!response || !response.data) {
      throw new Error("empty response");
    }

    return response.data.towns.map((town) => ({
      id: town.id,
      name: town.name,
    }));
  } catch (e) {
    throw e;
  }
};
