import React from "react";
import { CardBody } from "reactstrap";

import classes from "./index.module.css";

const footer = ({
  onSubmitClick, //
  onCancelClick,
  onDeleteClick,
  showDelete,
}) => (
  <CardBody className="bt">
    <div
      className={[
        "d-flex",
        showDelete ? "justify-content-between" : "justify-content-end",
      ].join(" ")}
    >
      {showDelete ? (
        <div>
          <button
            type="button"
            className={["btn btn-danger"].join(" ")}
            onClick={onDeleteClick}
          >
            Удалить из списка
          </button>
        </div>
      ) : null}

      <div>
        <button
          type="submit"
          className={["btn btn-secondary", classes.ButtonSecondary].join(" ")}
          onClick={onCancelClick}
        >
          Отмена
        </button>

        <button
          type="submit"
          className={["btn btn-primary", classes.ButtonPrimary].join(" ")}
          onClick={onSubmitClick}
        >
          Сохранить
        </button>
      </div>
    </div>
  </CardBody>
);

export default footer;
