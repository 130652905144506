import { getProfileInfo } from "../../api/profile";
import { setProfileInfoAction } from "./actions";

export const getProfileInfoEffect = () => async (dispatch) => {
  try {
    const profileInfo = await getProfileInfo();

    dispatch(
      setProfileInfoAction({
        profileInfo,
      })
    );
  } catch (e) {
    throw e;
  }
};
