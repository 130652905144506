import React from "react";
import { connect } from "react-redux";

import "loaders.css/loaders.css";
import "spinkit/css/spinkit.css";

import classes from "./index.module.css";

const spinner = ({ loading }) => (
  <>
    {loading ? (
      <div className={classes.Wrapper}>
        <div className={["sk-cube-grid", classes.CubeWrapper].join(" ")}>
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      </div>
    ) : null}
  </>
);

const mapStateToProps = (state) => ({
  loading: state.mainPage.loading,
});

export default connect(mapStateToProps)(spinner);
