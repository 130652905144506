import "core-js/es6/string";
import "core-js/es6/array";
import "core-js/es6/map";
import "core-js/es6/set";
import "core-js/es6/object";
import "core-js/es6/promise";
import "core-js/es7/object";
import "core-js/es7/array";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router";

import config from "@generic/config";
import { createInstance } from "@generic/axios";
import authService from "@services/auth";
import history from "@generic/history";
import { checkFestTileEffect } from "@store/mainPage/effects";
import { removeFestTile } from "@store/mainPage/actions";

import App from "./App";
import "./i18n";
import { configureStore } from "./store";

const store = configureStore();

(async () => {
  try {
    await config.load();

    createInstance(config.get("apiUrl"), () => {
      authService.logout();

      history.push("/login");
    });

    const checkFestTile = (pathname) => {
      const s = pathname.split("/");

      if (s[1] === "fest") {
        const festUrl = s[2];

        store.dispatch(checkFestTileEffect(festUrl));
      } else {
        store.dispatch(removeFestTile());
      }
    };

    history.listen(({ pathname }) => checkFestTile(pathname));

    checkFestTile(history.location.pathname);

    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>,
      document.getElementById("app")
    );
  } catch (e) {
    console.error(e);
  }
})();
