import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { CardBody } from "reactstrap";

import authService from "@services/auth";

import { toggleSettingAction } from "../../store/mainPage/actions";
import avatar from "../Assets/ava3.jpg";

import classes from "./Offsidebar.module.css";

class Offsidebar extends Component {
  logout = async () => {
    const { history } = this.props;

    authService.logout();

    history.push("/login");
  };

  openProfile = () => {
    const { history, toggleSetting } = this.props;

    toggleSetting("offsidebarOpen");

    history.push("/profile/info");
  };

  render() {
    const { profileImage, profileInfo } = this.props;

    const name = profileInfo ? profileInfo.nickname : "";

    const image = profileImage ? profileImage.preview : avatar;

    return (
      <aside className={["offsidebar", classes.OffsideContainer].join(" ")}>
        <nav>
          <div>
            <CardBody className="text-center">
              <div className="py-2">
                <img
                  className="img-fluid rounded-circle img-thumbnail thumb96"
                  src={image}
                  alt="Contact"
                />
              </div>

              <p className={classes.NameText}>{name}</p>

              <div className={classes.SecondText}>(Это вы)</div>
            </CardBody>

            <li
              className={[
                classes.ProfileButton,
                "list-group-item-action list-group-item list-inline",
              ].join(" ")}
              onClick={this.openProfile}
            >
              <em className="mr-2 far fa-user"></em>
              <strong>Мой профиль</strong>
            </li>

            <CardBody className="text-center bt">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={this.logout}
              >
                Выйти
              </button>
            </CardBody>
          </div>
        </nav>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  profileImage: state.profile.profileImage,
  profileInfo: state.profile.profileInfo,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSetting: (name) => {
    dispatch(toggleSettingAction(name));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Offsidebar));
