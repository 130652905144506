export const ALL_FILES = "ALL_FILES";
export const TRACK = "TRACK";
export const REFERENCE = "REFERENCE";
export const OTHER = "OTHER";

export const FILES = [
  { name: "Все файлы", id: ALL_FILES },
  { name: "Трек для выхода", id: TRACK },
  { name: "Референс", id: REFERENCE },
  { name: "Прочее", id: OTHER },
];

export const FILE_TYPES = [
  { value: "track", label: "Трек для выхода" },
  { value: "reference", label: "Референс" },
  { value: "other", label: "Остальные файлы" },
];
