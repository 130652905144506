import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card, //
  CardBody,
  CardFooter,
  Input,
} from "reactstrap";
import swal from "sweetalert";

import * as authApi from "@api/auth";
import { setLoading } from "@store/mainPage/actions";

import classes from "./index.module.css";

class Login extends Component {
  state = {
    username: "",
  };

  setUsername = (username) => {
    this.setState({
      username,
    });
  };

  onSubmitClick = async () => {
    const { setLoading, history } = this.props;
    const { username } = this.state;

    try {
      if (username.length > 0) {
        setLoading(true);

        await authApi.restorePassword(username);

        history.push("/login");

        swal(
          "Это сообщение об успехе!",
          "Проверьте почту, привязанную к учетной записи.",
          "success"
        );

        setLoading(false);
      }
    } catch (e) {
      swal("Ошибка", "Не удалось восстановить пароль", "error");

      setLoading(false);
    }
  };

  render = () => {
    return (
      <Card className={["card-default", classes.Card].join(" ")}>
        <CardBody className={classes.CardBody}>
          <h3 className={classes.FormHeader}>Сброс пароля, такое бывает!</h3>

          <p className={classes.Description}>
            Укажите свои учётные данные для сброса пароля.
          </p>

          <div className={classes.Form}>
            <div>
              <Input
                type="text" //
                className={["mb-2", classes.Input].join(" ")}
                placeholder="Укажите логин или электронную почту..."
                onChange={(e) => this.setUsername(e.target.value)}
              />
            </div>
          </div>
        </CardBody>

        <CardFooter className={classes.Footer}>
          <div className={classes.SubmitWrapper}>
            <Link to="/login" className={classes.TextButton}>
              <strong>На страницу входа</strong>
            </Link>

            <div className={classes.SubmitButtonWrapper}>
              <button
                type="submit"
                className={["btn", "btn-danger", classes.Button].join(" ")}
                onClick={this.onSubmitClick}
              >
                Сбросить пароль!
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (name) => {
    dispatch(setLoading(name));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(Login));
