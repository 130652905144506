import React from "react";

import InviteItem from "./inviteItem/index";

import classes from "./index.module.css";

const myInvites = ({
  invitesIsExpanded, //
  teammates,
  onClick,
  onDeleteClick,
  openContactInfo,
}) => {
  return (
    <>
      <div className={["box-placeholder", classes.BlockWrapper].join(" ")}>
        <fieldset className={classes.FieldSet}>
          <div
            className={classes.TextButton}
            onClick={() => onClick("invitesIsExpanded")}
          >
            Моя команда &nbsp;{invitesIsExpanded ? "-" : "+"}
          </div>
        </fieldset>

        {invitesIsExpanded
          ? teammates.map((item) => (
              <InviteItem
                item={item}
                openContactInfo={(usrTeammateId) =>
                  openContactInfo(usrTeammateId)
                }
                onDeleteClick={() => onDeleteClick(item.usrTeammateId)}
              />
            ))
          : null}

        <fieldset className={classes.FieldSet}>
          <div className={classes.TextFooter}>
            Вы можете включать в свои заявки: {teammates.length}
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default myInvites;
