import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Card, //
  CardBody,
  CardFooter,
  CardHeader,
  Input,
} from "reactstrap";

import classes from "./index.module.css";

class rules extends Component {
  state = {
    agree: false,
  };

  agreeRulesHandler = () => {
    this.setState({ agree: !this.state.agree });
  };

  render() {
    const {
      backToRequestType, //
      festTile,
      festRules,
      requestRules,
      acceptRulesHandler,
    } = this.props;

    const { agree } = this.state;
    return (
      <div className={["content-wrapper", classes.ContentWrapper].join(" ")}>
        {festTile ? (
          <div className="content-heading">
            Правила подачи заявки «{festTile.name}»
          </div>
        ) : null}

        <Card className="border-0">
          <div className="row row-flush">
            <div className="col-2 bg-warning text-center d-flex align-items-center justify-content-center rounded-left">
              <em className="fa-2x mr-2 fas fa-exclamation-triangle"></em>
            </div>

            <div className="col-10">
              <CardBody className="text-left">
                <div className={classes.Warning}>
                  Обязательно ознакомьтесь с правилами подачи заявки.
                </div>
              </CardBody>
            </div>
          </div>
        </Card>

        <Card className="card-default ">
          <CardHeader>
            <div>{festRules}</div>

            <div className="dropdown-divider"></div>

            <div>{requestRules}</div>
          </CardHeader>

          <CardBody className={classes.CheckRules}>
            <label
              className={[" c-checkbox", classes.CheckboxWrapper].join(" ")}
            >
              Я соглашаюсь на сбор, обработку и хранение персональных данных
              <Input
                type="checkbox"
                onChange={this.agreeRulesHandler}
                bsSize="sm"
              />
              <span className="fa fa-check ml-2"></span>
            </label>
          </CardBody>

          <CardFooter className={classes.Footer}>
            <div className={classes.SubmitWrapper}>
              <div onClick={backToRequestType} className={classes.TextButton}>
                <strong>Назад к выбору типа заявки</strong>
              </div>

              <div className={classes.SubmitButtonWrapper}>
                <button
                  type="submit"
                  disabled={!agree}
                  className={["btn", "btn-primary", classes.Button].join(" ")}
                  onClick={acceptRulesHandler}
                >
                  Правила прочитаны, идём создавать заявку!
                </button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default withRouter(rules);
