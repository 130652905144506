import React from "react";

import { getUserFullText } from "../../../../utils/user";

import classes from "./styles.module.css";

const intersection = ({
  intersections, //
  noBorder,
  festUrl,
}) => {
  return (
    <div className={classes.Wrapper}>
      <div className={classes.Header}>
        {getUserFullText(intersections[0].user)}
      </div>

      <ul>
        {intersections.map((item) => (
          <li key={item.intersectionId}>
            {item.requestCheckRegNumber} -{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/fest/${festUrl}/${item.requestTypeSemanticUrl}/${item.requestCheckSemanticUrl}`}
            >
              <span className={classes.Name}>{item.requestCheckName}</span>
            </a>{" "}
            - {item.requestTypeName} / {item.requestStateName} [
            {item.requestCheckReviewMnemocode}]{" "}
            <span className={classes.ParticipantInfo}>
              {item.requestParticipantCheckPerformerInfo}
            </span>
          </li>
        ))}
      </ul>

      {!noBorder ? <div className={classes.Hr} /> : null}
    </div>
  );
};

export default intersection;
