import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import {
  Card, //
  CardBody,
  CardFooter,
  Input,
} from "reactstrap";
import swal from "sweetalert";

import authService from "@services/auth";

import classes from "./index.module.css";

class Register extends Component {
  state = {
    username: "",
    email: { value: "", valid: false },
    password: "",
    confirmPassword: "",
    isPasswordConfirmed: false,
    agreed: false,
    submitted: false,
    error: "",
  };

  setUsername = (username) => {
    this.setState({ username });
  };

  setPassword = (password) => {
    this.setState({ password });
  };

  setEmail = (value) => {
    const newValue = value;

    const reMail = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

    const newValid = reMail.test(String(value).toLowerCase());

    this.setState({ email: { value: newValue, valid: newValid } });
  };

  agreeRules = () => {
    this.setState({ agreed: !this.state.agreed });
  };

  onSubmitClick = async () => {
    const { history } = this.props;

    const {
      username, //
      email,
      password,

      isPasswordConfirmed,
      agreed,
    } = this.state;

    this.setState({ submitted: true });

    if (
      username && //
      email.valid &&
      password &&
      isPasswordConfirmed &&
      agreed
    ) {
      try {
        await authService.register({
          username, //
          email,
          password,
        });

        const urlParams = new URLSearchParams(window.location.search);
        const inviteLink = urlParams.get("invite-link");
        setTimeout(function(){
            history.push(!!inviteLink ? `/profile/my-team?invite-link=${inviteLink}`: '/');
        }, 3000);
        swal(
          "Поздравляем с успешной регистрацией!",
          "Теперь с этими логином и паролем можно войти в систему заявок.",
          "success"
        );
      } catch (e) {
        swal(
          "Ошибка",
          "Не удалось создать учетную запись. Проверьте правильность и попробуйте еще раз.",
          "error"
        );
      }
    }
  };

  confirmPassword = (value) => {
    const { password } = this.state;
    const isPasswordConfirmed = password === value;

    this.setState({ isPasswordConfirmed, confirmPassword: value });
  };

  render() {
    const {
      username, //
      email,
      password,
      confirmPassword,
      agreed,
      submitted,
    } = this.state;

    const { history } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const inviteLink = urlParams.get("invite-link");

    return (
      <Card className={["card-default", classes.Card].join(" ")}>
        <CardBody className={classes.CardBody}>
          <h3 className={classes.FormHeader}>Добро пожаловать!</h3>

          <p className={classes.Description}>
            Введите свои данные для регистрации.
          </p>

          <div className={classes.Form}>
            <div>
              <Input
                type="text" //
                valid={submitted && !!username}
                invalid={submitted && !username}
                value={username}
                className={["mb-2", classes.Input].join(" ")}
                placeholder="Укажите логин ..."
                onChange={(e) => this.setUsername(e.target.value)}
                required
              />
            </div>

            <div>
              <Input
                type="text" //
                valid={submitted && email.valid}
                value={email.value}
                invalid={submitted && !email.valid}
                className={["mb-2", classes.Input].join(" ")}
                placeholder="Укажите электронную почту..."
                onChange={(e) => this.setEmail(e.target.value)}
              />
            </div>

            <div>
              <Input
                type="password" //
                valid={submitted && !!password}
                invalid={submitted && !password}
                value={password}
                className={["mb-2", classes.Input].join(" ")}
                placeholder="Ваш пароль..."
                onChange={(e) => this.setPassword(e.target.value)}
              />
            </div>

            <div>
              <Input
                type="password" //
                valid={submitted && confirmPassword}
                invalid={submitted && !confirmPassword}
                className={["mb-2", classes.Input].join(" ")}
                value={confirmPassword}
                placeholder="И еще разок ваш пароль..."
                onChange={(e) => this.confirmPassword(e.target.value)}
              />
            </div>

            <div className="checkbox c-checkbox">
              <label className="mb-1">
                <Input type="checkbox" onChange={this.agreeRules} bsSize="sm" />
                <span
                  className={[
                    submitted && !agreed ? classes.Check : "",
                    "fa",
                    "fa-check",
                  ].join(" ")}
                ></span>
                Я согласен с условиями пользовательского соглашения
              </label>
            </div>
          </div>
        </CardBody>

        <CardFooter className={classes.Footer}>
          <div className={classes.SubmitWrapper}>
            <div
              className={classes.TextButton}
              onClick={() =>
                history.push(
                  !!inviteLink ? `/login?invite-link=${inviteLink}` : "/login"
                )
              }
            >
              <strong>На страницу входа</strong>
            </div>

            <div className={classes.SubmitButtonWrapper}>
              <button
                type="submit"
                className={["btn", "btn-primary", classes.Button].join(" ")}
                onClick={this.onSubmitClick}
              >
                Зарегистрироваться
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(Register);
